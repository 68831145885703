/**
 * acknowledgeConstants
 *
 * @package                onboard
 * @subpackage             acknowledgeConstants
 * @category               Constants
 * @DateOfCreation         15 JULY 2019
 * @ShortDescription       This is responsible for E-acknowledge action names
 */
 export const acknowledgeConstants = {
    ACKNOWLEDGE_REQUEST  : 'ACKNOWLEDGE_REQUEST',
    ACKNOWLEDGE_SUCCESS  : 'ACKNOWLEDGE_SUCCESS',
    ACKNOWLEDGE_FAILURE  : 'ACKNOWLEDGE_FAILURE',
    ACKNOWLEDGE_RESET    : 'ACKNOWLEDGE_RESET'
};