import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const withRouting = (Component) => {
    return props => {
        const navigate = useNavigate();
        const params = useParams();
        const location = useLocation();
        return <Component {...props} navigate={navigate} params={params} location={location}/>;
    };
}
