import { businessConstants, documentConstants } from '../_constants';

/**
 * businessReducer
 *
 * @package                onboard
 * @subpackage             businessReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to Admin business
 */

//Initial State on load state and intial action with their type
const initialState = {
    businessList    : [],
    businessRoleList: [],
    documentList: [],
    userList:[],
    sendingRequest  : false,
    success: null,
    businessEditDetail : {},
    isBusinessSaved    : false,
    isBusinessEdit     : false,
    isBusinessUpdate    : false,
    isDocumentDelete : null,
    loader          : true,
    successMessage  : '',
    submitted: false
};
export function business(state = initialState, action) {
    switch (action.type) {
        case businessConstants.BUSINESS_REQUEST :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : true,
            };
        case businessConstants.BUSINESS_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                loader         : false,
                businessList   : action.businessList,
                pages          : action.businessList.totalPages
            };
        case businessConstants.BUSINESS_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                errorMsg       : action.errorMsg
            };
        case businessConstants.BUSINESS_DOCUMENT_REQUEST :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : true,
            };
        case businessConstants.BUSINESS_DOCUMENT_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                loader         : false,
                documentList   : action.documentList,
                //pages          : action.businessList.totalPages
            };
        case businessConstants.BUSINESS_DOCUMENT_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                errorMsg       : action.errorMsg
            };
        case businessConstants.BUSINESS_EDIT_REQUEST :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : true,
            };
        case businessConstants.BUSINESS_EDIT_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                loader         : false,
                isBusinessEdit     : true,
                businessEditDetail   : action.business,
            };
        case businessConstants.BUSINESS_EDIT_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                isBusinessEdit     : false,
                errorMsg       : action.errorMsg
            };

        case businessConstants.BUSINESS_ADD_REQUEST :
            return  { 
                ...state,
                submitted      : true
            };    
        case businessConstants.BUSINESS_ADD_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true, 
                successMessage : action.successMsg.message,
                isBusinessSaved: true, 
                success: true,
                submitted      : false
            };
        case businessConstants.BUSINESS_ADD_FAILURE :
            return  { 
                ...state,
                sendingRequest : false, 
                isBusinessSaved: false, 
                submitted      : false,
                success : false,
                successMessage       : action.errorMsg
            };

        case businessConstants.BUSINESS_UPDATE_REQUEST :
            return  { 
                ...state,
                submitted      : true
            };    
        case businessConstants.BUSINESS_UPDATE_SUCCESS :
                return  { 
                    ...state,
                    sendingRequest : true, 
                    successMessage : action.result.message,
                    isBusinessUpdate: true, 
                    submitted      : true,
                    success: true,
                };
        case businessConstants.BUSINESS_UPDATE_FAILURE :
            return  { 
                ...state,
                sendingRequest : false, 
                isBusinessUpdate: false, 
                submitted      : false,
                errorMsg       : action.errorMsg,
                success : false
            };
        case businessConstants.BUSINESS_REMOVE_REQUEST :
            return  { 
                ...state,
                submitted      : true
            };
        case businessConstants.BUSINESS_REMOVE_SUCCESS:
            return  { 
                ...state,
                sendingRequest       : true, 
                deleteSuccessMessage : action.successMsg.message,
                businessList         : action.successMsg.businessList,
            };
        case businessConstants.BUSINESS_REMOVE_FAILURE:
            return  { 
                ...state,
                sendingRequest : false, 
                errorMsg       : action.errorMsg
            };
        case businessConstants.BUSINESS_USER_REQUEST :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : true,
            };
        case businessConstants.BUSINESS_USER_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                loader         : false,
                userList   : action.userList,
                //pages          : action.businessList.totalPages
            };
        case businessConstants.BUSINESS_USER_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                errorMsg       : action.errorMsg
            };
        
            case businessConstants.BUSINESS_ROLE_REQUEST :
                return  { 
                    ...state,
                    sendingRequest : false,
                    loader         : true,
                };
            case businessConstants.BUSINESS_ROLE_SUCCESS :
                return  { 
                    ...state,
                    sendingRequest : true,
                    loader         : false,
                    businessRoleList : action.businessRoleList,
                };
            case businessConstants.BUSINESS_ROLE_FAILURE :
                return  { 
                    ...state,
                    sendingRequest : false,
                    loader         : false,
                    errorMsg       : action.errorMsg
                };
    
        case businessConstants.BUSINESS_RESET_STATE:
            return {
                ...state,
                sendingRequest  : false, 
                errorMsg        : false,
                successMessage  : false,
                businessEditDetail : {},
                documentList    : [],
                isDocumentDelete: null,
                userList: [],
                isBusinessSaved    : false,
                isBusinessEdit     : false,
                isBusinessUpdate    : false,
                submitted       : false,
                success: null
             };
                 
        case businessConstants.BUSINESS_RESET_RESPONSE:
            return {
                ...state,
                sendingRequest  : false, 
                errorMsg        : false,
                successMessage  : false,
                submitted       : false,
                success: null
             };
        case documentConstants.DOCUMENT_REMOVE_REQUEST:
            return {
                ...state,
                submitted: true,
                sendingRequest: false,
                isDocumentDelete: false,
                loader: true,
            };   
        case documentConstants.DOCUMENT_REMOVE_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                success: true,
                isDocumentDelete: true,
                successMessage: action.successMsg.message
            };
        case documentConstants.DOCUMENT_REMOVE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg,
                success: false,
                isDocumentDelete: false,
                successMessage: action.errorMsg
            };

        case documentConstants.DOCUMENT_RESET_STATE:
            return {
                documentList    : [],
                isDocumentDelete: null,
            }
        default:
            return state
    }
}