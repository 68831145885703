/**
 * employeeService
 *
 * @package                onboard
 * @subpackage             employeeService
 * @category               Service
 * @DateOfCreation         8 July 2019
 * @ShortDescription       This is responsible for calling all api related to business
 */
import axios from 'axios';
import { configConstants } from '../_constants';
import { utilityHelper, history } from '../_helpers';

export const employeeService = {
    employeeNotesList,
    employeeNoteSave,
    editEmployeeNotes,
    userUpdate,
    notesDelete,
};

/**
 * @DateOfCreation        8 July 2019
 * @ShortDescription      This function is responsible to call Fetch business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function employeeNotesList(page, pageSize, sorted, filtered,userDetail) { 
    let detail = {
        "NoteDetails": {
            "BusinessId":userDetail.BusinessId, 
            "LocationId":userDetail.LocationId,
            "UserId": userDetail.UserId
        },
        "Paging": {
            "PageNumber":page, 
            "PageSize":pageSize, 
            "Sort":sorted,
        }
    }
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'Notes/get',
            data    :   detail,
            responseType: "json"
        }).then(function (response) {
            return response;
        }).catch(response=> {
            if(response.response.data)
                return response.response.data.message;
            else
               return response;
        });
}



function employeeNoteSave(detail)
{
    let bodyFormData = new FormData();
    bodyFormData.set('BusinessId', detail.BusinessId);
    bodyFormData.set('LocationId', detail.LocationId);
    bodyFormData.append('UserId', detail.UserId); 
    bodyFormData.append('FileName', detail.FileName); 
    bodyFormData.append('NotesFile', detail.NotesFile); 
    bodyFormData.append('DocExpirationDate', detail.DocExpirationDate || ''); 
    bodyFormData.append('Text', detail.Text);
    // console.log(bodyFormData.entries())
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'Notes/create',
            data    : bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

function userUpdate(detail)
{
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'user/update',
        data    : detail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        // console.log('Update LOG',response);
        if(response.response&&response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}

function editEmployeeNotes(Id) { 
    return axios({
            method  : 'Get',
            url     : configConstants.API_BASE_PATH+'Notes/get/'+Id,
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

function notesDelete(detail)
{
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'Notes/Delete',
        data    : detail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}