import axios from 'axios';
import { utilityHelper } from '../_helpers/utilityHelper'
import { configConstants } from '../_constants/configConstants';
import { history } from './history';
import { userLoginService } from '../_services';
import { sessionService } from '../_packages/redux-react-session';
export default {
  setupInterceptors: () => {
    //Add a response interceptor
    axios.interceptors.request.use((config) => {
      document.body.classList.add('loading-indicator');
        if (config.headers.Authorization === null || config.headers.Authorization  === undefined) {
          let token =  utilityHelper.getLoginAccessToken();
          config.headers = {
            'Pragma'       : 'no-cache',
            'Content-Security-Policy': "script-src 'self'; style-src 'self';",
            'Strict-Transport-Security': "max-age=3600; includeSubDomains",
            'X-Content-Type-Options': 'nosniff',
            'X-Frame-Options': 'sameorigin',
          }
          if(token !== undefined){
            config.headers.Authorization = 'Bearer '+token;
          }
        }

        return config;
    }, (error) => {
      document.body.classList.remove('loading-indicator');
      return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use((response) => {
      
       document.body.classList.remove('loading-indicator');
      if(response.status == configConstants.UNAUTHENTICATE_CODE){
        let userInfo = utilityHelper.getUserInfo();
        if(userInfo.refreshToken) {
          let refreshToken = {
            refreshToken: userInfo.refreshToken,
            Token: utilityHelper.getLoginAccessToken(),
            UserName: userInfo.username,
          }
          userLoginService.refreshToken(refreshToken)
          .then(response=>{
            let responseData = response.data;
            sessionService.saveSession(responseData.data.token);
            sessionService.saveUser(responseData.data.userInfo);
            localStorage.setItem('currentLoginTime',JSON.stringify((new Date).getTime()));
            window.location.reload(true);
          })
          .catch(error=>{
            utilityHelper.doLogout();
            window.location.reload(true);
          });
        } else {
          utilityHelper.doLogout();
          window.location.reload(true);
        }
      } else if(response.status == configConstants.FORBIDDEN_CODE){
          utilityHelper.doLogout();
          window.location.reload(true);
      } else if(response.status == configConstants.INACTIVE_USER_CODE){
        utilityHelper.doLogout();
        window.location.reload(true);
      }
      return response;
    }, (error) => {
      document.body.classList.remove('loading-indicator');
      if(error.response.status == configConstants.UNAUTHENTICATE_CODE){
        let userInfo = utilityHelper.getUserInfo();
        if(userInfo.refreshToken) {
          let refreshToken = {
            refreshToken: userInfo.refreshToken,
            Token: utilityHelper.getLoginAccessToken(),
            UserName: userInfo.username,
          }
          userLoginService.refreshToken(refreshToken)
          .then(response=>{
            let responseData = response.data;
            sessionService.saveSession(responseData.data.token);
            sessionService.saveUser(responseData.data.userInfo);
            localStorage.setItem('currentLoginTime',JSON.stringify((new Date).getTime()))
            window.location.reload(true);
          })
          .catch(error=>{
            utilityHelper.doLogout();
            window.location.reload(true);
          });
        } else {
          utilityHelper.doLogout();
          window.location.reload(true);
        }
      } else if(error.response.status == configConstants.FORBIDDEN_CODE){
        utilityHelper.doLogout();
        window.location.reload(true);
      } else if(error.response.status == configConstants.INACTIVE_USER_CODE){
        utilityHelper.doLogout();
        window.location.reload(true);
      }
       return error.response;
    });
  }

};


