import { locationConstants } from '../_constants';

/**
 * locationReducer
 *
 * @package                onboard
 * @subpackage             locationReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to Admin business location
 */

//Initial State on load state and intial action with their type
const initialState = {
    sendingRequest  : false, 
    success : null,
    message  : '',
    locationEditDetail : {},
    locationDetail  : {},
    isLocationSaved    : false,
    isLocationUpdate    : false,
    submitted       : false,
    locationList    : [],
};
export function location(state = initialState, action) {
    switch (action.type) {
        case locationConstants.LOCATION_REQUEST :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : true,
            };
        case locationConstants.LOCATION_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                loader         : false,
                locationList   : action.locationList,
                pages          : action.locationList.pages
            };
        case locationConstants.LOCATION_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                errorMsg       : action.errorMsg
            };
        
        case locationConstants.LOCATION_EDIT_REQUEST :
                return  { 
                    ...state,
                    sendingRequest : false,
                    loader         : true,
                };
        case locationConstants.LOCATION_EDIT_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                loader         : false,
                success        : true,
                locationEditDetail   : action.location,
            };
        case locationConstants.LOCATION_EDIT_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                success        : false,
                message       : action.errorMsg
            };
        case locationConstants.LOCATION_ADD_REQUEST :
            return  { 
                ...state,
                submitted      : true
            };
        case locationConstants.LOCATION_ADD_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true, 
                success : true,
                message : action.responseDetail.message,
                locationDetail : action.responseDetail.detail,
                isLocationSaved: true, 
                submitted      : false
            };
        case locationConstants.LOCATION_ADD_FAILURE :
            return  { 
                ...state,
                sendingRequest : false, 
                isLocationSaved: false, 
                submitted      : false,
                success        : false,
                message       : action.errorMsg
            };
        case locationConstants.LOCATION_UPDATE_REQUEST :
            return  { 
                ...state,
                submitted      : true
            };
        case locationConstants.LOCATION_UPDATE_SUCCESS :
                return  { 
                    ...state,
                    sendingRequest : true, 
                    message : action.result.message,
                    locationDetail : action.result.detail,
                    isLocationUpdate: true, 
                    success: true,
                    submitted      : false,
                };
        case locationConstants.LOCATION_UPDATE_FAILURE :
            return  { 
                ...state,
                sendingRequest : false, 
                isLocationUpdate: false, 
                submitted      : false,
                success         : false,
                message       : action.errorMsg
            };
        case locationConstants.LOCATION_REMOVE_SUCCESS:
            return  { 
                ...state,
                sendingRequest       : true, 
                message              : action.successMsg.message,
                success              : true,
                locationList         : action.successMsg.locationList,
            };
        case locationConstants.LOCATION_REMOVE_FAILURE:
            return  { 
                ...state,
                sendingRequest : false, 
                message       : action.errorMsg,
                success        : false,
            };
        case locationConstants.LOCATION_RESET_STATE:
            return {
                initialState
             };
        default:
            return state
    }
}