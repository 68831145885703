import axios from "axios"; 
import { configConstants } from '../_constants';
import { utilityHelper} from '../_helpers';

/**
 * headerService
 *
 * @package                onboard
 * @subpackage             headerService
 * @category               Service
 * @DateOfCreation         22 May 2019
 * @ShortDescription       This is responsible for calling API
 */
export const headerService = {
    logout
};

/**
* @DateOfCreation        22 May 2019
* @ShortDescription      This function is responsible for remove cookies
* @return                Redirect
*/
function logout() {
    //Call the action function with dispatch
    //var loginAccessToken = utilityHelper.getLoginAccessToken();
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH + 'account/logout',
    })
    .then(response => {
         return response;
    })
    .catch(function (response) {
            return response;
    });
}

