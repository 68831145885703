export const I9constants = {
I9_AUTOFILL_REQUEST : 'I9_AUTOFILL_REQUEST',
I9_AUTOFILL_SUCCESS : 'I9_AUTOFILL_SUCCESS',
I9_AUTOFILL_FAILURE : 'I9_AUTOFILL_FAILURE',

I9_DOC_LIST_REQUEST : 'I9_DOC_LIST_REQUEST',
I9_DOC_LIST_SUCCESS : 'I9_DOC_LIST_SUCCESS',
I9_DOC_LIST_FAILURE : 'I9_DOC_LIST_FAILURE',
I9_EMP_RES_REQUEST  : 'I9_EMP_RES_REQUEST',
I9_EMP_RES_SUCCESS  : 'I9_EMP_RES_SUCCESS',
I9_EMP_RES_FAILURE  : 'I9_EMP_RES_FAILURE',
I9_UPDATE_REQUEST   : 'I9_UPDATE_REQUEST',
I9_UPDATE_SUCCESS   : 'I9_UPDATE_SUCCESS',
I9_UPDATE_FAILURE   : 'I9_UPDATE_FAILURE',
I9_RESET_STATE      : 'I9_RESET_STATE',
};