import axios from "axios"; 
import { configConstants } from '../_constants';
import { utilityHelper } from '../_helpers';

/**
 * forgotPasswordService
 *
 * @package                onboard
 * @subpackage             forgotPasswordService
 * @category               Service
 * @DateOfCreation         17 aug 2019
 * @ShortDescription       This is responsible for calling forgot password API
 */
export const forgotPasswordService = {
    resetPassword
};

/**
* @DateOfCreation        10 May 2019
* @ShortDescription      This function is responsible to call login api
* @param                 JSON jsonObj
* @return                Response JSON jsonObj
*/
function resetPassword(detail) {
   return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH + 'Account/ForgotPassword',
        data    : detail,
        headers: { 
            'Content-Type' : 'application/json',
        }
    })
    .then(response => {
        return response;
    })
    .catch(function (error) {
       return error.response
    });
}

