/**
 * userActions
 *
 * @package                onboard
 * @subpackage             userActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all business actions
 */ 

import { userConstants,configConstants } from '../_constants';
import { userService } from '../_services';

export const userActions = {
    userList,
    resetState,
    userSave,
    getRoles,
    getBusiness,
    getLocation,
    getJobTitle,
    editUser,
    userUpdate,
    userDelete,
    changeUserRole,
    resetUserEditState,
    assignToBusinessSave,
    getAssignedBusinessLocation,
    resetAssignToBusinessState,
    resetUserList,
    businessLocationDelete,
    employeeOnboard,
    resetStateError
};



/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for user list
 * @return                JSON Object
 */
function userList(page, pageSize, sorted, filtered,businessDetail={}) {
    return dispatch => {
        userService.userList(page, pageSize, sorted, filtered,businessDetail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(userList) { return { type : userConstants.USER_REQUEST, userList } }
    function  success(userList) { return { type : userConstants.USER_SUCCESS, userList } }
    function failure(error) { return { type : userConstants.USER_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for save user detail
 * @param {object} detail       user detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function userSave(detail) {
    return dispatch => {
        dispatch(request({ detail }));
        userService.userSave(detail)
            .then(
                response => { 
                    let data = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        // const index = businessList.findIndex(
                        //             i => 
                        //                 i.BusinessId == detail.BusinessId
                        //             );
                        // if(businessList[index]) {
                        //     businessList[index] = detail;
                        // }else{
                        //     detail['BusinessId'] = data.result.BusinessId;
                        //     businessList.push(detail);
                        // }
                        let successMsg = { 'message' : data.message, 'detail' : data.result, 'userList' : userList };
                        dispatch(success(successMsg));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(detail) { return { type: userConstants.USER_ADD_REQUEST, detail } }
    function success(successMsg) { return { type : userConstants.USER_ADD_SUCCESS, successMsg } }
    function failure(error) { return { type : userConstants.USER_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for update user detail
 * @param {object} detail       user detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function userUpdate(detail) {
    return dispatch => {
        dispatch(request({ detail }));
        userService.userUpdate(detail)
            .then(
                response => { 
                    let data = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let successMsg = { 'message' : data.message, 'detail' : data.result, 'userList' : userList };
                        dispatch(success(successMsg));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };
    function request(detail) { return { type: userConstants.USER_UPDATE_REQUEST, detail } }
    function success(successMsg) { return { type : userConstants.USER_UPDATE_SUCCESS, successMsg } }
    function failure(error) { return { type : userConstants.USER_UPDATE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for delete user detail
 * @param {object} detail       user detail
 * @return                      JSON Object in succsss message, recent delete record
 */
function userDelete(userId, isPermanentDelete) {
    return dispatch => {
        dispatch(request({ userId }));
        userService.userDelete(userId,isPermanentDelete)
            .then(
                response => { 
                    let data = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let successMsg = { 'message' : data.message };
                        dispatch(success(successMsg));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };
    function request(userId) { return { type: userConstants.USER_REMOVE_REQUEST, userId } }
    function success(successMsg) { return { type : userConstants.USER_REMOVE_SUCCESS, successMsg } }
    function failure(error) { return { type : userConstants.USER_REMOVE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for update user detail
 * @param {object} detail       user detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function changeUserRole(detail) {
    return dispatch => {
        userService.changeUserRole(detail)
            .then(
                response => { 
                   let responseData = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let responseDetail = { 'message' : responseData.message,'detail':responseData.data };
                        dispatch(success(responseDetail));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        let errorMsg  = responseData.message;
                        dispatch(failure(errorMsg));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function success(responseDetail) { return { type : userConstants.USER_ROLE_CHANGE_SUCCESS, responseDetail } }
    function failure(error) { return { type : userConstants.USER_ROLE_CHANGE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}


function getRoles() {
        return dispatch => {
            userService.getRoles()
                .then(
                    response => { 
                        let responseData = response.data;
                        if(response.status == configConstants.SUCCESS_CODE) {
                            dispatch(success(responseData.data));
                        }else if(response.status == configConstants.ERROR_CODE) {
                            dispatch(failure(responseData.message));
                        }else if(response.status == configConstants.EXCEPTION_CODE) {
                            dispatch(failure(responseData.message));
                        }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                            dispatch(unauthorize(responseData.message));
                        }else{
                            dispatch(failure(response));
                        }
                    }
                ).catch(function (response) {
                    dispatch(failure(response));
                });
        };
    
        function request(RoleList) { return { type : userConstants.USER_ROLE_REQUEST, RoleList } }
        function  success(RoleList) { return { type : userConstants.USER_ROLE_SUCCESS, RoleList } }
        function failure(error) { return { type : userConstants.USER_ROLE_FAILURE, errorMsg:error } }
        function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

function getBusiness() {
    return dispatch => {
        userService.businessList()
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(BusinessList) { return { type : userConstants.USER_BUSINESS_REQUEST, BusinessList } }
    function  success(BusinessList) { return { type : userConstants.USER_BUSINESS_SUCCESS, BusinessList } }
    function failure(error) { return { type : userConstants.USER_BUSINESS_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

function getJobTitle() {
    return dispatch => {
        userService.getJobTitle()
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(jobTitleList) { return { type : userConstants.USER_JOB_TITLE_REQUEST, jobTitleList } }
    function  success(jobTitleList) { return { type : userConstants.USER_JOB_TITLE_SUCCESS, jobTitleList } }
    function failure(error) { return { type : userConstants.USER_JOB_TITLE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

function editUser(Id) {
    return dispatch => {
        userService.editUser(Id)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(editUserDetail) { return { type : userConstants.USER_EDIT_REQUEST, editUserDetail } }
    function  success(editUserDetail) { return { type : userConstants.USER_EDIT_SUCCESS, editUserDetail } }
    function failure(error) { return { type : userConstants.USER_EDIT_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}



function getLocation(businessId) {
    return dispatch => {
        dispatch(request({ businessId }));
        userService.locationList(businessId)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(LocationList) { return { type : userConstants.USER_LOCATION_REQUEST, LocationList } }
    function  success(LocationList) { return { type : userConstants.USER_LOCATION_SUCCESS, LocationList } }
    function failure(error) { return { type : userConstants.USER_LOCATION_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for save assign business user detail
 * @param {object} detail       user detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function assignToBusinessSave(detail) {
    return dispatch => {
        dispatch(request({ detail }));
        userService.assignToBusinessSave(detail)
            .then(
                response => { 
                    let data = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let successMsg = { 'message' : data.message, 'detail' : data.result };
                        dispatch(success(successMsg));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };
    function request(detail) { return { type: userConstants.ASSIGNTOBUSINESS_ADD_REQUEST, detail } }
    function success(successMsg) { return { type : userConstants.ASSIGNTOBUSINESS_ADD_SUCCESS, successMsg } }
    function failure(error) { return { type : userConstants.ASSIGNTOBUSINESS_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for user list
 * @return                JSON Object
 */
function getAssignedBusinessLocation(page, pageSize, sorted, filtered,userId) {
    return dispatch => {
        userService.getAssignedBusinessLocation(page, pageSize, sorted, filtered,userId)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function  success(assignedBusinessList) { return { type : userConstants.ASSIGNTOBUSINESS_LIST_SUCCESS, assignedBusinessList } }
    function failure(error) { return { type : userConstants.ASSIGNTOBUSINESS_LIST_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for delete business location detail
 * @param {object} detail       user business location detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function businessLocationDelete(businessLocationId,userBusLocList) {
    return dispatch => {
        dispatch(request({ businessLocationId }));
        userService.businessLocationDelete(businessLocationId)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        userBusLocList.list.filter((item)=>{
                           return item.userBusinessLocationId !== businessLocationId
                        })
                        let responseDetail = { 'message' : responseData.message, 'userBusLocList':userBusLocList};
                        dispatch(success(responseDetail));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(detail) { return { type: userConstants.ASSIGNTOBUSINESS_DELETE_REQUEST, detail } }
    function success(responseDetail) { return { type : userConstants.ASSIGNTOBUSINESS_DELETE_SUCCESS, responseDetail } }
    function failure(error) { return { type : userConstants.ASSIGNTOBUSINESS_DELETE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}


/**
* @DateOfCreation        8 july 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : userConstants.USER_RESET_STATE } }
}

function resetStateError(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : userConstants.USER_RESET_STATE_FOR_ERROR } }
}


/**
* @DateOfCreation        8 july 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetUserEditState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : userConstants.USER_EDIT_RESET_STATE } }
}

/**
* @DateOfCreation        8 july 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetAssignToBusinessState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : userConstants.ASSIGNTOBUSINESS_RESET_STATE } }
}

/**
* @DateOfCreation        8 july 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function employeeOnboard(employeeOnboard){
    return dispatch => { dispatch(succcess()); }
    function succcess() { return { type : userConstants.APPLICANT_ONBOARD, employeeOnboard } }
}

/**
* @DateOfCreation        8 july 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetUserList(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : userConstants.USER_LIST_RESET_STATE } }
}



