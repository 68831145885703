import axios from "axios"; 
import { configConstants } from '../_constants';

/**
 * loginService
 *
 * @package                onboard
 * @subpackage             loginService
 * @category               Service
 * @DateOfCreation         10 May 2019
 * @ShortDescription       This is responsible for calling API
 */
export const userLoginService = {
    login,
    validateAuthCode,
    resendAuthCode,
    refreshToken
};

/**
* @DateOfCreation        10 May 2019
* @ShortDescription      This function is responsible to call login api
* @param                 JSON jsonObj
* @return                Response JSON jsonObj
*/
function login(user) {
    // Create the Base64 encoded string for the Basic Auth header
    const encodedCredentials = btoa(`${user.UserName}:${user.Password}`);

    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH + 'account/login',
        headers: { 
            'Content-Type' : 'application/json',
            'Authorization': `Basic ${encodedCredentials}`
        }
    })
    .then(response => {
        return response;
    })
    .catch(function (error) {
        // console.log(error);
        return error.response
    });
}

/**
* @DateOfCreation        30 May 2023
* @ShortDescription      This function is responsible to call validate AuthCode api
* @param                 JSON jsonObj
* @return                Response JSON jsonObj
*/
function validateAuthCode(user) {
    // Create the Base64 encoded string for the Basic Auth header
    const encodedCredentials = btoa(`${user.UserName}:${user.Password}`);
    let demouser = {
        UserName: user.UserName,
        AuthCode: user.AuthCode,
        OneTimeCode: user.OneTimeCode
    }
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH + 'account/ValidateAuthCode',
        data    : demouser,//bodyFormData,
        headers: { 
            'Content-Type' : 'application/json',
        }
    })
    .then(response => {
        return response;
    })
    .catch(function (error) {
        // console.log(error);
        return error.response
    });
}

/**
* @DateOfCreation        30 May 2023
* @ShortDescription      This function is responsible to call resend AuthCode api
* @param                 JSON jsonObj
* @return                Response JSON jsonObj
*/
function resendAuthCode(user) {
    // Create the Base64 encoded string for the Basic Auth header
    const encodedCredentials = btoa(`${user.UserName}:${user.Password}`);
    let demouser = {
        UserName: user.UserName,
        AuthCode: user.AuthCode,
        OneTimeCode: user.OneTimeCode
    }
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH + 'account/ResendAuthCode',
        data    : demouser,//bodyFormData,
        headers: { 
            'Content-Type' : 'application/json',
        }
    })
    .then(response => {
        return response;
    })
    .catch(function (error) {
        // console.log(error);
        return error.response
    });
}

/**
* @DateOfCreation        10 May 2019
* @ShortDescription      This function is responsible to call login api
* @param                 JSON jsonObj
* @return                Response JSON jsonObj
*/
function refreshToken(demouser) {
    // Create the Base64 encoded string for the Basic Auth header
    const encodedCredentials = btoa(`${demouser.UserName}:${demouser.Password}`);
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH + 'account/RefreshToken',
        data    : demouser,//bodyFormData,
        headers: { 
            'Content-Type' : 'application/json',
        }
    })
    .then(response => {
        return response;
    })
    .catch(function (error) {
       return error.response
    });
}



