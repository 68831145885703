export const documentConstants = {
    BUSINESS_DOC_SAVE_REQUEST   : 'BUSINESS_DOC_SAVE_REQUEST',
    BUSINESS_DOC_SAVE_SUCCESS   : 'BUSINESS_DOC_SAVE_SUCCESS',
    BUSINESS_DOC_SAVE_FAILURE   : 'BUSINESS_DOC_SAVE_FAILURE',
    BUSINESS_DOC_RESET_STATE    : 'BUSINESS_DOC_RESET_STATE',

    MGMT_DOC_DELETE_REQUEST   : 'MGMT_DOC_DELETE_REQUEST',
    MGMT_DOC_DELETE_SUCCESS   : 'MGMT_DOC_DELETE_SUCCESS',
    MGMT_DOC_DELETE_FAILURE   : 'MGMT_DOC_DELETE_FAILURE',
    MGMT_DOC_DELETE_RESET     : 'MGMT_DOC_DELETE_RESET',
    
    
    MGMT_DOC_REQUEST            : 'MGMT_DOC_REQUEST',
    MGMT_DOC_SUCCESS            : 'MGMT_DOC_SUCCESS',
    MGMT_DOC_FAILURE            : 'MGMT_DOC_FAILURE',
    MGMT_DOC_RESET_STATE        : 'MGMT_DOC_RESET_STATE',
    
    MGMT_EMP_DOC_REQUEST        : 'MGMT_EMP_DOC_REQUEST',
    MGMT_EMP_DOC_SUCCESS        : 'MGMT_EMP_DOC_SUCCESS',
    MGMT_EMP_DOC_FAILURE        : 'MGMT_EMP_DOC_FAILURE',
    APPLICATIONS_LIST_REQUEST   : 'APPLICATIONS_LIST_REQUEST',
    APPLICATIONS_LIST_SUCCESS   : 'APPLICATIONS_LIST_SUCCESS',
    APPLICATIONS_LIST_FAILURE   : 'APPLICATIONS_LIST_FAILURE',
    
    BUSINESS_ALL_DOCUMENT_REQUEST: 'BUSINESS_ALL_DOCUMENT_REQUEST',
    BUSINESS_ALL_DOCUMENT_SUCCESS: 'BUSINESS_ALL_DOCUMENT_SUCCESS',
    BUSINESS_ALL_DOCUMENT_FAILURE: 'BUSINESS_ALL_DOCUMENT_FAILURE',

    REJECT_DOCUMENT_REQUEST : 'REJECT_DOCUMENT_REQUEST',
    REJECT_DOCUMENT_SUCCESS : 'REJECT_DOCUMENT_SUCCESS',
    REJECT_DOCUMENT_FAILURE : 'REJECT_DOCUMENT_FAILURE',
    REJECT_DOCUMENT_RESET   : 'REJECT_DOCUMENT_RESET',

    UPDATE_APP_STATUS_REQUEST : 'UPDATE_APP_STATUS_REQUEST',
    UPDATE_APP_STATUS_SUCCESS : 'UPDATE_APP_STATUS_SUCCESS',
    UPDATE_APP_STATUS_FAILURE : 'UPDATE_APP_STATUS_FAILURE',
    APP_STATUS_RESET          : 'APP_STATUS_RESET',
    MGMT_EMP_DOC_RESET_STATE  : 'MGMT_EMP_DOC_RESET_STATE',

    DOCUMENT_REMOVE_REQUEST : 'DOCUMENT_REMOVE_REQUEST',
    DOCUMENT_REMOVE_SUCCESS : 'DOCUMENT_REMOVE_SUCCESS',
    DOCUMENT_REMOVE_FAILURE : 'DOCUMENT_REMOVE_FAILURE',
    DOCUMENT_RESET_STATE: 'DOCUMENT_RESET_STATE',

    BUSINESS_DOCUMENT_LEVEL5_REQUEST: 'BUSINESS_DOCUMENT_LEVEL5_REQUEST',
    BUSINESS_DOCUMENT_LEVEL5_SUCCESS: 'BUSINESS_DOCUMENT_LEVEL5_SUCCESS',
    BUSINESS_DOCUMENT_LEVEL5_FAILURE: 'BUSINESS_DOCUMENT_LEVEL5_FAILURE',
};


