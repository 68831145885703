import { registerConstants } from '../_constants';

/**
 * registerReducer
 *
 * @package                onboard
 * @subpackage             registerReducer
 * @category               Reducers
 * @DateOfCreation         28 aug 2019
 * @ShortDescription       This is responsible for all state related to online register
 */

//Initial State on load state and intial action with their type
const initialState = {
    loader: true,
    isUserSaved: false,
    jobTitleList: [],
    businessLocationList: {},
    submitted: false,
    success: null,
    message: '',
};
export function register(state = initialState, action) {
    switch (action.type) {
        case registerConstants.REGISTER_ADD_REQUEST:
            return {
                ...state,
                submitted: true,
            };
        case registerConstants.REGISTER_ADD_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                isUserSaved: true,
                submitted: false,
                success: true,
                message: action.responseDetail.message

            };
        case registerConstants.REGISTER_ADD_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isUserSaved: false,
                submitted: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case registerConstants.REGISTER_JOB_TITLE_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case registerConstants.REGISTER_JOB_TITLE_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                jobTitleList: action.jobTitleList,
                //pages          : action.userList.totalPages
            };
        case registerConstants.REGISTER_JOB_TITLE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg
            };
        case registerConstants.REGISTER_BUSINESS_LOCATION_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case registerConstants.REGISTER_BUSINESS_LOCATION_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                businessLocationList: action.businessLocationList,
                //pages          : action.userList.totalPages
            };
        case registerConstants.REGISTER_JOB_TITLE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg
            };    
        case registerConstants.REGISTER_RESET_STATE:
            return {
                ...state,
                sendingRequest: false,
                errorMsg: false,
                isUserSaved: false,
                submitted: false,
                success: null,
                message: ''
            };
            
        default:
            return state
    }
}