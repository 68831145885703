/**
 * configConstants
 *
 * @package                onboard
 * @subpackage             configConstants
 * @category               Constants
 * @DateOfCreation         11 May 2019
 * @ShortDescription       This is responsible for all config related constants
 */

export const configConstants = {
    API_BASE_PATH       :  process.env.REACT_APP_API_ROOT_PATH+'/api/',
    API_ROOT_PATH       :  process.env.REACT_APP_API_ROOT_PATH,
    WP_BASE_PATH        :  process.env.WP_BASE_PATH,
    SUCCESS_CODE        :  200,
    ERROR_CODE          :  400,
    EXCEPTION_CODE      :  404,
    UNAUTHENTICATE_CODE :  401,
    FORBIDDEN_CODE      :  403,
    INACTIVE_USER_CODE  :  421,
    RESOURCE_TYPE       : 1,
    LOGIN_TOKEN         : '_lgbds',
    USER_INFO           : '_ugdhs',
    START_YEAR          : '1970',
    END_YEAR            : '2019',
    START_MONTH         : '1',
    END_MONTH           : '12',
    USER_ROLE_ADMIN     : 'Admin',
    USER_ROLE_TPA       : 'TPA',
    USER_ROLE_OWNER     : 'Owner',
    USER_ROLE_MGMT      : 'MGMT',
    USER_ROLE_MANAGER   : 'STRMGR',
    USER_ROLE_EMPLOYEE  : 'EMPLOYEE',
    UNAUTHENTICATE      : 'UNAUTHENTICATE',
    DEFAULT_IMAGE_PATH  :  process.env.PUBLIC_URL+'assets/images/inner-logo.png',
    PROFILE_LOAING      :  process.env.REACT_APP_STATIC_IMAGE_BASE_PATH+'profile-loading.gif',
    CONFIRM_ALERT_YES   : 'Yes',
    CONFIRM_ALERT_NO    : 'No',
    W4_FORM_ID          : 1,
    I9_FORM_ID          : 2,
    WOTC_FORM_ID        : 3,
    DD_FORM_ID          : 4,
    A4_FORM_ID          : 6,
    ALERT_TIMEOUT       : 600000
};
