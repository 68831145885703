/**
 * a4formActions
 *
 * @package                onboard
 * @subpackage             a4formActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all business actions
 */ 

import { a4constants,configConstants } from '../_constants';
import { a4FormService } from '../_services';

export const a4formAction = {
    autofilldetails,
    savea4form,
    updatea4form,
    resetState
};



/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for user list
 * @return                JSON Object
 */
function autofilldetails(model) {
    return dispatch => {
        a4FormService.formDetails(model)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(autofilldetail) { return { type : a4constants.AUTOFILL_REQUEST, autofilldetail } }
    function  success(autofilldetail) { return { type : a4constants.AUTOFILL_SUCCESS, autofilldetail } }
    function failure(error) { return { type : a4constants.AUTOFILL_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}



/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for user list
 * @return                JSON Object
 */
function savea4form(model) {
    return dispatch => {
        dispatch(request({ model }));
        a4FormService.A4FormAdd(model)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let respDetail = { 'message' : responseData.message, 'detail' : responseData.data };
                        dispatch(success(respDetail));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };
    function request(model){ return { type: a4constants.A4_ADD_REQUEST, model}}
    function  success(respDetail) { return { type : a4constants.A4_ADD_SUCCESS, respDetail } }
    function failure(error) { return { type : a4constants.A4_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for update Wotc form
 * @return                JSON Object
 */
function updatea4form(model) {
    return dispatch => {
        dispatch(request({ model }));
        a4FormService.A4FormUpdate(model)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let successMsg = { 'message' : responseData.message, 'detail' : responseData.data };
                        dispatch(success(successMsg));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(model) { return { type : a4constants.A4_UPDATE_REQUEST, model } }
    function  success(successMsg) { return { type : a4constants.A4_UPDATE_SUCCESS, successMsg } }
    function failure(error) { return { type : a4constants.A4_UPDATE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}


function resetState()
{
    return dispatch => { dispatch(request()); }
    function request() { return { type : a4constants.A4_RESET_STATE } }
}