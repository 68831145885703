import React from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";
import { configConstants } from "../_constants";
import { utilityHelper } from "../_helpers";

const ManagerRoute = ({ authenticated }) => {
    const userInfo = utilityHelper.getUserInfo();

    if (!userInfo) {
        return <Navigate to="/" replace={true} />;
    }

    const isManager =
        userInfo.role === configConstants.USER_ROLE_MANAGER ||
        userInfo.role === configConstants.USER_ROLE_ADMIN ||
        userInfo.role === configConstants.USER_ROLE_TPA;

    return authenticated && isManager ? <Outlet /> : <Navigate to="/" replace={true} />;
};

ManagerRoute.propTypes = {
    authenticated: PropTypes.bool.isRequired,
};

export { ManagerRoute };
