/**
 * taskActions
 *
 * @package                onboard
 * @subpackage             taskActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all business actions
 */ 

import { taskConstants,configConstants } from '../_constants';
import { documentService } from '../_services';

export const taskActions = {
    getTaskList,
    getEmpTaskList,
    signDocument,
    signLevelDocument,
    generateDocument,
    resetState,
    resetSignState,
    resetGenerateDocState
};


/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for management task list
 * @return                JSON Object
 */
function getTaskList(page, pageSize, sorted, filtered, businessDetail) {
    return dispatch => {
        dispatch(request());
        documentService.getDocumentList(page, pageSize, sorted, filtered, businessDetail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request() { return { type : taskConstants.MGMT_TASK_REQUEST  } }
    function  success(taskList) { return { type : taskConstants.MGMT_TASK_SUCCESS, taskList } }
    function failure(error) { return { type : taskConstants.MGMT_TASK_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for management employee task list
 * @return                JSON Object
 */
function getEmpTaskList(page, pageSize, sorted, filtered, businessDetail) {
    return dispatch => {
        dispatch(request());
        documentService.getDocumentList(page, pageSize, sorted, filtered, businessDetail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request() { return { type : taskConstants.MGMT_EMP_TASK_REQUEST } }
    function  success(empTaskList) { return { type : taskConstants.MGMT_EMP_TASK_SUCCESS, empTaskList } }
    function failure(error) { return { type : taskConstants.MGMT_EMP_TASK_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for management employee task list
 * @return                JSON Object
 */
function signDocument(taskInfo) {
    return dispatch => {
        dispatch(request({ taskInfo }))
        documentService.signDocument(taskInfo)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let respDetail = { 'message' : responseData.message, 'detail' : responseData.data };
                        dispatch(success(respDetail));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(taskInfo) { return { type : taskConstants.MGMT_DOC_SIGN_REQUEST, taskInfo } }
    function  success(signDocumentResponse) { return { type : taskConstants.MGMT_DOC_SIGN_SUCCESS, signDocumentResponse } }
    function failure(error) { return { type : taskConstants.MGMT_DOC_SIGN_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for management employee task list
 * @return                JSON Object
 */
function signLevelDocument(taskInfo) {
    return dispatch => {
        dispatch(request({ taskInfo }))
        documentService.signLevelDocument(taskInfo)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let respDetail = { 'message' : responseData.message, 'detail' : responseData.data };
                        dispatch(success(respDetail));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(taskInfo) { return { type : taskConstants.LEVEL_DOC_SIGN_REQUEST, taskInfo } }
    function  success(signDocumentResponse) { return { type : taskConstants.LEVEL_DOC_SIGN_SUCCESS, signDocumentResponse } }
    function failure(error) { return { type : taskConstants.LEVEL_DOC_SIGN_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for management employee task list
 * @return                JSON Object
 */
function generateDocument(generateDoc) {
    return dispatch => {
        dispatch(request({ generateDoc }))
        documentService.generateDocument(generateDoc)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let respDetail = { 'message' : responseData.message, 'detail' : responseData.data };
                        dispatch(success(respDetail));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(generateDoc) { return { type : taskConstants.GENERATE_DOC_REQUEST, generateDoc } }
    function  success(documentResponse) { return { type : taskConstants.GENERATE_DOC_SUCCESS, documentResponse } }
    function failure(error) { return { type : taskConstants.GENERATE_DOC_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}



/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : taskConstants.MGMT_TASK_RESET_STATE } }
}

/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetSignState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : taskConstants.MGMT_DOC_SIGN_RESET_STATE } }
}

/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetGenerateDocState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : taskConstants.GENERATE_DOC_RESET_STATE } }
}

