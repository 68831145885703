import { logoConstants } from '../_constants';

/**
 * logoReducer
 *
 * @package                onboard
 * @subpackage             logoReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to Admin logo
 */

//Initial State on load state and intial action with their type
const initialState = {
    sendingRequest  : false,
    isLogoSaved    : false,
    loader          : true,
    successMessage  : '',
    logoDetail      : {},
    submitted       : false,
};
export function logo(state = initialState, action) {
    switch (action.type) {
        case logoConstants.LOGO_SAVE_REQUEST :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : true,
                submitted      : true
            };
        case logoConstants.LOGO_SAVE_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                loader         : false,
                isLogoSaved   : true,
                logoDetail     : action.logo.data,
                successMessage : action.logo.message,
                submitted      : false
            };
        case logoConstants.LOGO_SAVE_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                errorMsg       : action.errorMsg,
                submitted      : false
            };
        case logoConstants.LOGO_RESET_STATE:
            return {
                ...state,
                sendingRequest  : false, 
                errorMsg        : false,
                successMessage  : false,
                isLogoSaved    : false,
                submitted       : false,
            };
        default:
            return state
    }
}