/**
 * ddFormService
 *
 * @package                onboard
 * @subpackage             ddFormService
 * @category               Service
 * @DateOfCreation         8 July 2019
 * @ShortDescription       This is responsible for calling all api related to wotc form
 */
import axios from 'axios';
import { configConstants } from '../_constants';

export const ddFormService = {
    formDetails,
    saveDDForm,
    updateDDForm
};


/**
 * @DateOfCreation        8 July 2019
 * @ShortDescription      This function is responsible to call Fetch business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function formDetails(model) { 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'DirectDeposit/GetAutoFill',
            data    : model,
            responseType: "json"
        }).then(function (response) {
            return response;
        }).catch(response=> {
            if(response.response.data)
                return response.response.data.message;
            else
               return response;
        });
}

/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or update wotc Document
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function updateDDForm(detail, ddType) { 
    let bodyFormData = new FormData();
    bodyFormData.set('businessId',detail.bankDetail.BusinessId);
    bodyFormData.append('locationId',detail.bankDetail.LocationId);
    bodyFormData.append('userId',detail.bankDetail.UserId);
    bodyFormData.append('userDocumentId',detail.bankDetail.UserDocumentId);
    bodyFormData.append('isReject',detail.bankDetail.IsReject);
    bodyFormData.append('ddType', ddType);
    if((ddType == 'DirectDeposit') || (ddType == 'VoidedCheck')){
        bodyFormData.append('bankName', detail.bankDetail.BankName === null ? "": detail.bankDetail.BankName);
        bodyFormData.append('routing', detail.bankDetail.Routing === null ? "": detail.bankDetail.Routing);
        bodyFormData.append('accountNumber', detail.bankDetail.AccountNumber === null ? '': detail.bankDetail.AccountNumber);
        bodyFormData.append('accountType', (detail.bankDetail.AccountType === null || detail.bankDetail.AccountType === "null") ? null: detail.bankDetail.AccountType);
        bodyFormData.append('DocFile', detail.bankDetail.DocFile);
        bodyFormData.append('SupportiveDocPath', detail.bankDetail.SupportiveDocPath !== null ? detail.bankDetail.SupportiveDocPath : "");
    }

    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'DirectDeposit/update',
        data    : bodyFormData,
        config  : { headers: {'Content-Type': 'multipart/form-data' }}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}

function saveDDForm(model, ddType) {
    let bodyFormData = new FormData();
    bodyFormData.set('businessId',model.bankDetail.BusinessId);
    bodyFormData.append('locationId',model.bankDetail.LocationId);
    bodyFormData.append('userId',model.bankDetail.UserId);
    bodyFormData.append('userDocumentId',model.bankDetail.UserDocumentId);
    bodyFormData.append('isReject',model.bankDetail.IsReject);
    bodyFormData.append('ddType', ddType);
    if((ddType == 'DirectDeposit') || (ddType == 'VoidedCheck')){
        bodyFormData.append('bankName', model.bankDetail.BankName !== null ? model.bankDetail.BankName : '');
        bodyFormData.append('routing', model.bankDetail.Routing !== null ? model.bankDetail.Routing : '' );
        bodyFormData.append('accountNumber', model.bankDetail.AccountNumber !== null ? model.bankDetail.AccountNumber : '');
        bodyFormData.append('accountType', (model.bankDetail.AccountType === null || model.bankDetail.AccountType === "null") ? null: model.bankDetail.AccountType);
        bodyFormData.append('DocFile', model.bankDetail.DocFile);
        bodyFormData.append('SupportiveDocPath', model.bankDetail.SupportiveDocPath !== null ? model.bankDetail.SupportiveDocPath : "");
    }

    let ddPath = 'DirectDeposit/create';
    if(model.detail.Id > 0){
        ddPath = 'DirectDeposit/EmployeeUpdate';
    }
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+ddPath,
        data    : bodyFormData,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}