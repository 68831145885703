import React from "react";
import { connect } from 'react-redux';
import { Header } from './Header'
import { configConstants } from '../../../_constants';
import * as $ from "jquery"
import { withRouting } from "../../../_helpers/withRouting";
/**
 * HeaderContainer
 *
 * @package                onboard
 * @subpackage             HeaderContainer
 * @category               Container Component
 * @DateOfCreation         22 May 2019
 * @ShortDescription       This component is reponsible for logic in header
 */
class HeaderContainer extends React.Component {

    constructor(props) {
        super(props);
        // Bind the events to the current class
        
        this.viewProfile  = this.viewProfile.bind(this);
        this.manageBusiness = this.manageBusiness.bind(this);
    }
    
    
    componentDidMount() {
        $('.d-menu').click(function(){
            $(this).toggleClass('slideview');
            

        });
        $('.whiteHeader ul li').click(function(){
            $('.d-menu').removeClass('slideview');
        }); 
    }


   

    /**
    * @DateOfCreation        22 May 2019
    * @ShortDescription      This function is responsible to viewProfile event
    * @return                Nothing
    */
    viewProfile() {
       this.props.navigate('/adminprofile')
    }

    /**
    * @DateOfCreation        22 May 2019
    * @ShortDescription      This function is responsible to manageBusiness event
    * @return                Nothing
    */    
    manageBusiness() {
        this.props.navigate('/business')
    }

    /**
    * @DateOfCreation        22 May 2019
    * @ShortDescription      This function is responsible to show header
    * @return                View
    */
    render() {
        return (
            <div >
                <Header
                    handleLogout    = {this.handleLogout}
                    role            = {this.props.role}
                    viewProfile     = {this.viewProfile}
                    manageBusiness  = {this.manageBusiness}
                    loggedInUser    = {this.props.user}
                    loggedInUserName= {this.props.user.firstName+' '+this.props.user.lastName}
                    loggedInUserImg = {Object.keys(this.props.logoDetail).length > 0 ? this.props.logoDetail : configConstants.DEFAULT_IMAGE_PATH}
                />
            </div>
        );
    }
}

/**
* @DateOfCreation        22 May 2019
* @ShortDescription      This function is responsible to connect store to props
* @return                View
*/
function mapStateToProps(state) {
    const { isLogoutDone, successMsg, errorMsg } = state.headerReducer;
    const { logoDetail } = state.logo
    return {
        isLogoutDone,
        successMsg,
        errorMsg,
        user: state.session.user,
        authenticated: state.session.authenticated,
        logoDetail
    };
}

// Connection with State
const connectedHeaderContainer = connect(mapStateToProps)(withRouting(HeaderContainer));
export { connectedHeaderContainer as HeaderContainer };
