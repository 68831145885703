import React from "react";
import { Nav, NavItem } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { configConstants } from '../../../_constants';
import { utilityHelper } from '../../../_helpers';


export const DropdownMenu = (props) => {
    let userInfo = utilityHelper.getUserInfo();
    return (
        <div>
            { props.displayMenu ? (
              <ul>
                <li className="center"> 
                {/* <img className="img-circle"
                  src={props.loggedInUserImg}                  
                /><br/> */}
                  {props.loggedInUserName}</li>
                <li className="center">{userInfo.role}</li>
                <li>
                  {(userInfo.role === configConstants.USER_ROLE_ADMIN) ?
                    <Nav variant="pills">
                      <NavLink to="/profile">Manage profile</NavLink>
                    </Nav>
                    : ''
                  }
                  {userInfo.role === configConstants.USER_ROLE_TPA && 
                         <Nav variant="pills">
                              <NavLink to="/TPA/profile">Manage profile</NavLink>
                          </Nav>
                  }
                  {userInfo.role === configConstants.USER_ROLE_OWNER  || userInfo.role === configConstants.USER_ROLE_MGMT ? 
                         <Nav variant="pills">
                              <NavLink to="/updateProfile">Manage profile</NavLink>
                          </Nav>
                    :   ''
                  }
                  {userInfo.role === configConstants.USER_ROLE_MANAGER ? 
                         <Nav variant="pills">
                              <NavLink to="/manager/updateProfile">Manage profile</NavLink>
                          </Nav>
                    :   ''
                  } 
                  {userInfo.role === configConstants.USER_ROLE_EMPLOYEE ? 
                         <Nav variant="pills">
                              <NavLink to="/employee/updateProfile">Manage profile</NavLink>
                         </Nav>
                    :   ''
                  }  
                </li>
              <li className="br-top center"><NavLink to="#" onClick={props.handleLogout}>Logout</NavLink></li>
              </ul>
            ) :
              (
                null
              )
            }

        </div>

    );
}