/**
 * loginConstants
 *
 * @package                onboard
 * @subpackage             loginConstants
 * @category               Constants
 * @DateOfCreation         10 May 2019
 * @ShortDescription       This is responsible for Login action names
 */
export const loginConstants = {
    GET_OTP_REQUEST         : 'GET_OTP_REQUEST',
    GET_OTP_SUCCESS         : 'GET_OTP_SUCCESS',
    GET_OTP_FAILURE         : 'GET_OTP_FAILURE',
    LOGIN_REQUEST           : 'LOGIN_REQUEST',
    LOGIN_SUCCESS           : 'LOGIN_SUCCESS',
    LOGIN_FAILURE           : 'LOGIN_FAILURE',
    LOGOUT_SUCCESS          : 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE          : 'LOGOUT_FAILURE',
    LOGIN_UPDATE_STATE      : 'LOGIN_UPDATE_STATE',
    LOGIN_RESET             : 'LOGIN_RESET'
};
