import { documentConstants, taskConstants } from '../_constants';

/**
 * documentReducer
 *
 * @package                onboard
 * @subpackage             documentReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to MGMT document
 */

//Initial State on load state and intial action with their type
const initialState = {
    sendingRequest: false,
    success: null,
    isDocumentSaved: false,
    loader: true,
    submitted: false,
    success: null,
    successMessage: '',
    message: '',
    updatedAppStatus: {},
    documentList: [],
    allBusinessdocument: [],
    applicationsList: [],
    empDocumentList: [],
    documentData: {},
    isDocGenerated: null,
    isRejectDocument: false,
    isDeleteDocument: false,
    documentListWithLevel5: [],
};
export function document(state = initialState, action) {
    switch (action.type) {
        case documentConstants.BUSINESS_DOC_SAVE_REQUEST:
            return {
                ...state,
                submitted: true,
                sendingRequest: false,
                loader: true,
            };
        case documentConstants.BUSINESS_DOC_SAVE_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
                loader: false,
                isDocumentSaved: true,
                message: action.document.message
            };
        case documentConstants.BUSINESS_DOC_SAVE_FAILURE:
            return {
                ...state,
                submitted: false,
                success: false,
                loader: false,
                message: action.errorMsg
            };
        case documentConstants.BUSINESS_DOC_RESET_STATE:
            return {
                ...state,
                success: false,
                errorMsg: false,
                isDocumentSaved: false,
                submitted: false,
                message: '',
            };
        case documentConstants.MGMT_DOC_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case documentConstants.MGMT_DOC_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                documentList: action.documentList,
            };
        case documentConstants.MGMT_DOC_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg
            };
        case documentConstants.APPLICATIONS_LIST_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case documentConstants.APPLICATIONS_LIST_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                applicationsList: action.applicationsList.list,
                pages: action.applicationsList.totalPages,
            };
        case documentConstants.APPLICATIONS_LIST_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg
            };
        case documentConstants.UPDATE_APP_STATUS_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
                submitted: true,
            };
        case documentConstants.UPDATE_APP_STATUS_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                success: true,
                submitted: false,
                successMessage: action.updatedAppStatus.message,
                updatedAppStatus: action.updatedAppStatus.result,
            };
        case documentConstants.UPDATE_APP_STATUS_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                submitted: false,
                success: false,
                errorMsg: action.errorMsg
            };

        case documentConstants.APP_STATUS_RESET:
            return {
                ...state,
                success: null,
                submitted: false,
                successMessage: '',
                updatedAppStatus: {},
                errorMsg: ''
            };

        case documentConstants.MGMT_DOC_RESET_STATE:
            return {
                ...state,
                sendingRequest: false,
                errorMsg: false,
                successMessage: false,
                submitted: false,
                documentList: []
            };

        case documentConstants.BUSINESS_ALL_DOCUMENT_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
                allBusinessdocument: []
            };
        case documentConstants.BUSINESS_ALL_DOCUMENT_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                allBusinessdocument: action.document,
                //pages          : action.businessList.totalPages
            };
        case documentConstants.BUSINESS_ALL_DOCUMENT_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg
            };

        case taskConstants.GENERATE_DOC_REQUEST:
            return {
                ...state,
                submitted: true,
            };
        case taskConstants.GENERATE_DOC_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                submitted: false,
                success: true,
                isDocGenerated: true,
                message: action.documentResponse.message,
                documentData: action.documentResponse.detail,
            };
        case taskConstants.GENERATE_DOC_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                success: false,
                message: action.errorMsg,
                submitted: false,

            };
        case taskConstants.GENERATE_DOC_RESET_STATE:
            return {
                ...state,
                sendingRequest: false,
                submitted: false,
                success: null,
                loader: false,
                message: '',
                documentData: {},
                isDocGenerated: null,
            };

        case documentConstants.MGMT_EMP_DOC_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case documentConstants.MGMT_EMP_DOC_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                empDocumentList: action.documentList,
            };
        case documentConstants.MGMT_EMP_DOC_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg
            };
        case documentConstants.MGMT_EMP_DOC_RESET_STATE:
            return {
                ...state,
                sendingRequest: false,
                errorMsg: false,
                successMessage: false,
                submitted: false,
                empDocumentList: []
            };
        
        case documentConstants.REJECT_DOCUMENT_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
                submitted: true,
            };

        case documentConstants.REJECT_DOCUMENT_SUCCESS:
           return {
                    ...state,
                sendingRequest: true,
                isRejectDocument: true,
                submitted: false,
                success: true,
                successMessage: action.document.message
            };

        case documentConstants.REJECT_DOCUMENT_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isRejectDocument: false,
                submitted: false,
                success: false,
                successMessage: action.errorMsg
            };
        case documentConstants.REJECT_DOCUMENT_RESET:
            return {
                ...state,
            isRejectDocument: false,
            submitted: false,
            success: null,
            successMessage: ''
        };

        case documentConstants.MGMT_DOC_DELETE_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
                submitted: true,
            };

        case documentConstants.MGMT_DOC_DELETE_SUCCESS:
           return {
                    ...state,
                sendingRequest: true,
                isDeleteDocument: true,
                submitted: false,
                success: true,
                successMessage: action.document.message
            };

        case documentConstants.MGMT_DOC_DELETE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isDeleteDocument: false,
                submitted: false,
                success: false,
                successMessage: action.errorMsg
            };
        case documentConstants.MGMT_DOC_DELETE_RESET:
            return {
                ...state,
            isDeleteDocument: false,
            submitted: false,
            success: null,
            successMessage: ''
        };

        case documentConstants.BUSINESS_DOCUMENT_LEVEL5_REQUEST :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : true,
            };
        case documentConstants.BUSINESS_DOCUMENT_LEVEL5_SUCCESS :

            return  { 
                ...state,
                sendingRequest : true,
                loader         : false,
                documentListWithLevel5   : action.documentList,
                //pages          : action.businessList.totalPages
            };
        case documentConstants.BUSINESS_DOCUMENT_LEVEL5_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                errorMsg       : action.errorMsg
            };

        default:
            return state
    }
}