/**
 * tpaCompanyService
 *
 * @package                onboard
 * @subpackage             tpaCompanyService
 * @category               Service
 * @DateOfCreation         28 Sept 2019
 * @ShortDescription       This is responsible for calling all api related to business
 */
import axios from 'axios';
import { configConstants } from '../_constants';


export const tpaCompanyService = {
    tpaCompanyList,
    tpaCompanySave,
    editTPACompany,
    tpaCompanyUpdate,
};

/**
 * @DateOfCreation        28 Sept 2019
 * @ShortDescription      This function is responsible to call Fetch tpa company api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function tpaCompanyList(page, pageSize, sorted, filtered) { 
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'TPACompany/get',
        data    : {"PageNumber":page, "PageSize":pageSize, "sort":sorted, "filtered":filtered},
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        return response;
    });
}

/**
 * @DateOfCreation        28 Sept 2019
 * @ShortDescription      This function is responsible to call Fetch business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */

function tpaCompanySave(detail)
{
    let bodyFormData = new FormData();
    bodyFormData.set('companyName',detail.companyName);
    bodyFormData.append('emailId',detail.emailId);
    bodyFormData.append('phoneNumber',detail.phoneNumber);
    bodyFormData.append('address',detail.address);
    bodyFormData.append('city',detail.city);
    bodyFormData.append('state',detail.state);
    bodyFormData.append('zip',detail.zip);
    bodyFormData.append('active',detail.active);
    bodyFormData.append('logo',detail.logo);


    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'TPACompany/create',
            data    : bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

function tpaCompanyUpdate(detail)
{
    let bodyFormData = new FormData();
    bodyFormData.set('id',detail.id);
    bodyFormData.append('companyName',detail.companyName);
    bodyFormData.append('emailId',detail.emailId);
    bodyFormData.append('phoneNumber',detail.phoneNumber);
    bodyFormData.append('address',detail.address);
    bodyFormData.append('city',detail.city);
    bodyFormData.append('state',detail.state);
    bodyFormData.append('zip',detail.zip);
    bodyFormData.append('active',detail.active);
    bodyFormData.append('logo',detail.logo);


    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'TPACompany/update',
            data    : bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

function editTPACompany(Id) { 
    return axios({
            method  : 'Get',
            url     : configConstants.API_BASE_PATH+'TPACompany/Get/'+Id,
            //data    : {},
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}
