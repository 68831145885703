export const wotcFormConstants = {
    AUTOFILL_REQUEST        : 'AUTOFILL_REQUEST',
    AUTOFILL_SUCCESS        : 'AUTOFILL_SUCCESS',
    AUTOFILL_FAILURE        : 'AUTOFILL_FAILURE',

    WOTC_ADD_REQUEST        : 'WOTC_ADD_REQUEST',
    WOTC_ADD_SUCCESS        : 'WOTC_ADD_SUCCESS',
    WOTC_ADD_FAILURE        : 'WOTC_ADD_FAILURE',

    WOTC_UPDATE_REQUEST        : 'WOTC_UPDATE_REQUEST',
    WOTC_UPDATE_SUCCESS        : 'WOTC_UPDATE_SUCCESS',
    WOTC_UPDATE_FAILURE        : 'WOTC_UPDATE_FAILURE',

    
    WOTC_RESET_STATE    : 'WOTC_RESET_STATE',
};