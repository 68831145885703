import { a4constants } from '../_constants';

/**
 * businessReducer
 *
 * @package                onboard
 * @subpackage             businessReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to Admin business
 */

//Initial State on load state and intial action with their type
const initialState = {
    autofilldetail: {},
    stateList : [],
    loader: true,
    isa4Saved: false,
    isA4Update:false,
    submitted: false,
    success: null,
    message: ''
};
export function AfourForm(state = initialState, action) {
    switch (action.type) {
        case a4constants.AUTOFILL_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case a4constants.AUTOFILL_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                autofilldetail: action.autofilldetail,
               // success: true,
            };
        case a4constants.AUTOFILL_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case a4constants.A4_ADD_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
                submitted: true,
            };
            case a4constants.A4_ADD_SUCCESS:
                return {
                    ...state,
                    sendingRequest: true,
                    isa4Saved: true,
                    submitted: false,
                    success: true,
                    message: action.respDetail.message
                };
            case a4constants.A4_ADD_FAILURE:
                return {
                    ...state,
                    sendingRequest: false,
                    isa4Saved: false,
                    submitted: false,
                    errorMsg: action.errorMsg,
                    success: false,
                    message: action.errorMsg
                };
            case a4constants.A4_UPDATE_REQUEST:
                return {
                    ...state,
                    sendingRequest: false,
                    loader: true,
                    submitted: true,
                };
            case a4constants.A4_UPDATE_SUCCESS:
                return {
                    ...state,
                sendingRequest: true,
                isA4Update: true,
                submitted: false,
                success: true,
                message: action.successMsg.message
                };
            case a4constants.A4_UPDATE_FAILURE:
                return {
                    ...state,
                    sendingRequest: false,
                    isA4Update: false,
                    submitted: false,
                    errorMsg: action.errorMsg,
                    success: false,
                    message: action.errorMsg
                };
            case a4constants.A4_RESET_STATE:
                return {
                    initialState
                };
    
        default:
            return state
    }
}