import { taskConstants } from '../_constants';

/**
 * taskReducer
 *
 * @package                onboard
 * @subpackage             taskReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to MGMT task
 */

//Initial State on load state and intial action with their type
const initialState = {
    sendingRequest  : false,
    loader          : true,
    message  : '',
    signDocumentData: {},
    signLevelDocumentData: {},
    documentData:{},
    taskList : [],
    empTaskList:[],
    isDocumentSign: null,
    isDocGenerated: null,
    submitted: false,

};
export function task(state = initialState, action) {
    switch (action.type) {
        case taskConstants.MGMT_TASK_REQUEST :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : true,
            };
        case taskConstants.MGMT_TASK_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                loader         : false,
                taskList   : action.taskList,
            };
        case taskConstants.MGMT_TASK_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                errorMsg       : action.errorMsg
            };
        case taskConstants.MGMT_TASK_RESET_STATE:
            return {
                ...state,
                sendingRequest  : false, 
                errorMsg        : false,
                message  : false,
                submitted       : false,
                taskList    : []
                };

        case taskConstants.MGMT_EMP_TASK_REQUEST :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : true,
            };
        case taskConstants.MGMT_EMP_TASK_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                loader         : false,
                empTaskList   : action.empTaskList,
            };
        case taskConstants.MGMT_EMP_TASK_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                errorMsg       : action.errorMsg
            };
        case taskConstants.MGMT_EMP_TASK_RESET_STATE:
            return {
                ...state,
                sendingRequest  : false, 
                errorMsg        : false,
                message  : false,
                submitted       : false,
                empTaskList    : []
            };
        case taskConstants.MGMT_DOC_SIGN_REQUEST :
            return  { 
                ...state,
                submitted: true,
            };
        case taskConstants.MGMT_DOC_SIGN_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                submitted: false,
                success        : true,
                loader         : false,
                isDocumentSign : true,
                message : action.signDocumentResponse.message,
                signDocumentData   : action.signDocumentResponse.detail,
            };
        case taskConstants.MGMT_DOC_SIGN_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                success        : false,
                loader         : false,
                isDocumentSign : false,
                message : action.errorMsg,
                submitted: false,

            };
        case taskConstants.MGMT_DOC_SIGN_RESET_STATE :
            return  { 
                ...state,
                sendingRequest : false,
                submitted: false,

                success        : null,
                loader         : false,
                message : '',
                isDocumentSign : false,
                signDocumentData : {},
            };
            case taskConstants.LEVEL_DOC_SIGN_REQUEST :
                return  { 
                    ...state,
                    submitted: true,
                };
            case taskConstants.LEVEL_DOC_SIGN_SUCCESS :
                return  { 
                    ...state,
                    sendingRequest : true,
                    submitted: false,
                    success        : true,
                    loader         : false,
                    isLevelDocumentSign : true,
                    message : action.signDocumentResponse.message,
                    signLevelDocumentData   : action.signDocumentResponse.detail,
                };
            case taskConstants.LEVEL_DOC_SIGN_FAILURE :
                return  { 
                    ...state,
                    sendingRequest : false,
                    success        : false,
                    loader         : false,
                    isLevelDocumentSign : false,
                    message : action.errorMsg,
                    submitted: false,
    
                };
            case taskConstants.LEVEL_DOC_SIGN_RESET_STATE :
                return  { 
                    ...state,
                    sendingRequest : false,
                    submitted: false,
    
                    success        : null,
                    loader         : false,
                    message : '',
                    isLevelDocumentSign : false,
                    signLevelDocumentData : {},
                };
        case taskConstants.GENERATE_DOC_REQUEST :
            return  { 
                ...state,
                submitted: true,
            };
        case taskConstants.GENERATE_DOC_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                submitted      : false,
                success        : true,
                isDocGenerated : true, 
                message        : action.documentResponse.message,
                documentData   : action.documentResponse.detail,
                };
        case taskConstants.GENERATE_DOC_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                success        : false,
                message        : action.errorMsg,
                submitted      : false,

            };
        case taskConstants.GENERATE_DOC_RESET_STATE :
            return  { 
                ...state,
                sendingRequest : false,
                submitted      : false,
                success        : null,
                loader         : false,
                message        : '',
                documentData   : {},
                isDocGenerated : null,
            };
        default:
            return state
    }
}