/**
 * documentActions
 *
 * @package                onboard
 * @subpackage             documentActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all business actions
 */ 

import { documentConstants,configConstants } from '../_constants';
import { documentService } from '../_services';

export const documentActions = {
    documentSave,
    getDocumentList,
    getBusinessDocumentWithLevel5,
    getAllBusinessDocument,
    getEmpDocumentList,
    resetDocState,
    updateApplicationStatus,
    getApplicationsList,
    businessDocumentSave,
    rejectDocument,
    resetRejectDocumentState,
    resetDeleteDocumentState,
    resetState,
    resetAppStatusState,
    documentDelete
};


/**
 * @DateOfCreation              25 june 2019
 * @ShortDescription            This function is responsible for save business document detail
 * @param {object} detail       business document detail
 * @param {array} documentSave  save business document
 * @return                      JSON Object in succsss message, recent saved record
 */
function documentSave(detail) {
    return dispatch => {
        dispatch(request({detail}));
        documentService.documentSave(detail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let document = {'message':responseData.message,'detail':responseData.data}
                        dispatch(success(document));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(document) { return { type : documentConstants.BUSINESS_DOC_SAVE_REQUEST, document } }
    function success(document) { return { type : documentConstants.BUSINESS_DOC_SAVE_SUCCESS, document } }
    function failure(error) { return { type : documentConstants.BUSINESS_DOC_SAVE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        28 sept 2021
 * @ShortDescription      This function is responsible for document delete
 * @return                JSON Object
 */

 function documentDelete(detail) {
    return dispatch => {
        dispatch(request({detail}));
        documentService.documentDelete(detail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let document = {'message':responseData.message,'detail':responseData.data}
                        dispatch(success(document));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(document) { return { type : documentConstants.MGMT_DOC_DELETE_REQUEST, document } }
    function success(document) { return { type : documentConstants.MGMT_DOC_DELETE_SUCCESS, document } }
    function failure(error) { return { type : documentConstants.MGMT_DOC_DELETE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for management document list
 * @return                JSON Object
 */
function getDocumentList(page, pageSize, sorted, filtered, businessDetail) {
    return dispatch => {
        documentService.getDocumentList(page, pageSize, sorted, filtered, businessDetail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(documentList) { return { type : documentConstants.MGMT_DOC_REQUEST, documentList } }
    function  success(documentList) { return { type : documentConstants.MGMT_DOC_SUCCESS, documentList } }
    function failure(error) { return { type : documentConstants.MGMT_DOC_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible for document list
 * @return                JSON Object
 */
 function getBusinessDocumentWithLevel5(businessId='') {
    return dispatch => {
        documentService.getBusinessDocumentWithLevel5(businessId)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(documentList) { return { type : documentConstants.BUSINESS_DOCUMENT_LEVEL5_REQUEST, documentList } }
    function success(documentList) { return { type : documentConstants.BUSINESS_DOCUMENT_LEVEL5_SUCCESS, documentList } }
    function failure(error) { return { type : documentConstants.BUSINESS_DOCUMENT_LEVEL5_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for management document list
 * @return                JSON Object
 */
function getApplicationsList(page, pageSize, sorted, filtered, applicationDetail) {
    return dispatch => {
        documentService.getApplicationsList(page, pageSize, sorted, filtered, applicationDetail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(applicationsList) { return { type : documentConstants.APPLICATIONS_LIST_REQUEST, applicationsList } }
    function  success(applicationsList) { return { type : documentConstants.APPLICATIONS_LIST_SUCCESS, applicationsList } }
    function failure(error) { return { type : documentConstants.APPLICATIONS_LIST_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for save application detail
 * @return                JSON Object
 */
function updateApplicationStatus(applicationDetail) {
    return dispatch => {
        dispatch(request({ applicationDetail }))
        documentService.updateApplicationStatus(applicationDetail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let responseResult = {'result': responseData.data, 'message': responseData.message}
                        dispatch(success(responseResult));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(applicationDetail) { return { type : documentConstants.UPDATE_APP_STATUS_REQUEST, applicationDetail } }
    function  success(updatedAppStatus) { return { type : documentConstants.UPDATE_APP_STATUS_SUCCESS, updatedAppStatus } }
    function failure(error) { return { type : documentConstants.UPDATE_APP_STATUS_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for management document list
 * @return                JSON Object
 */
function getEmpDocumentList(page, pageSize, sorted, filtered, businessDetail) {
    return dispatch => {
        documentService.getDocumentList(page, pageSize, sorted, filtered, businessDetail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(documentList) { return { type : documentConstants.MGMT_EMP_DOC_REQUEST, documentList } }
    function  success(documentList) { return { type : documentConstants.MGMT_EMP_DOC_SUCCESS, documentList } }
    function failure(error) { return { type : documentConstants.MGMT_EMP_DOC_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              25 june 2019
 * @ShortDescription            This function is responsible for save business document detail
 * @param {object} detail       business document detail
 * @param {array} documentSave  save business document
 * @return                      JSON Object in succsss message, recent saved record
 */
function businessDocumentSave(detail) {
    return dispatch => {
        dispatch(request({detail}));
        documentService.businessDocumentSave(detail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let document = {'message':responseData.message,'detail':responseData.data}
                        dispatch(success(document));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(document) { return { type : documentConstants.BUSINESS_DOC_SAVE_REQUEST, document } }
    function success(document) { return { type : documentConstants.BUSINESS_DOC_SAVE_SUCCESS, document } }
    function failure(error) { return { type : documentConstants.BUSINESS_DOC_SAVE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible for document list
 * @return                JSON Object
 */
function getAllBusinessDocument(businessId) {
    return dispatch => {
        dispatch(request());
        documentService.getAllBusinessDocument(businessId)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request() { return { type : documentConstants.BUSINESS_ALL_DOCUMENT_REQUEST } }
    function success(document) { return { type : documentConstants.BUSINESS_ALL_DOCUMENT_SUCCESS, document } }
    function failure(error) { return { type : documentConstants.BUSINESS_ALL_DOCUMENT_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        20 july 2020
 * @ShortDescription      This function is responsible for document reject
 * @return                JSON Object
 */
function rejectDocument(modal) {
    return dispatch => {
        dispatch(request());
        documentService.rejectDocument(modal)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request() { return { type : documentConstants.REJECT_DOCUMENT_REQUEST } }
    function success(document) { return { type : documentConstants.REJECT_DOCUMENT_SUCCESS, document } }
    function failure(error) { return { type : documentConstants.REJECT_DOCUMENT_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetDocState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : documentConstants.MGMT_DOC_RESET_STATE } }
}
/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : documentConstants.BUSINESS_DOC_RESET_STATE } }
}

/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetAppStatusState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : documentConstants.APP_STATUS_RESET } }
}

/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetRejectDocumentState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : documentConstants.REJECT_DOCUMENT_RESET } }
}

/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetDeleteDocumentState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : documentConstants.MGMT_DOC_DELETE_RESET } }
}
