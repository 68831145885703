import { w4constants } from '../_constants';

/**
 * businessReducer
 *
 * @package                onboard
 * @subpackage             businessReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to Admin business
 */

//Initial State on load state and intial action with their type
const initialState = {
    autofilldetail: {},
    W4DocumentDetail:{},
    stateList : [],
    loader: true,
    successMessage: '',
    isw4Saved: false,
    
    submitted: false,
    success: null,
    message: ''
};
export function WfourForm(state = initialState, action) {
    switch (action.type) {
        case w4constants.AUTOFILL_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case w4constants.AUTOFILL_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                autofilldetail: action.autofilldetail,
               // success: true,
            };
        case w4constants.AUTOFILL_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case w4constants.W4_ADD_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
                submitted: true,
            };
            case w4constants.W4_ADD_SUCCESS:
                return {
                    ...state,
                sendingRequest: true,
                successMessage: action.successMsg.message,
                isw4Saved: true,
                submitted: false,
                success: true,
                message: action.successMsg.message
                };
            case w4constants.W4_ADD_FAILURE:
                return {
                    ...state,
                    sendingRequest: false,
                    isw4Saved: false,
                    submitted: false,
                    errorMsg: action.errorMsg,
                    success: false,
                    message: action.errorMsg
                };
            case w4constants.W4_DOC_ADD_REQUEST:
                return {
                    ...state,
                    sendingRequest: false,
                    loader: true,
                    submitted: true,
                };    
            case w4constants.W4_DOC_ADD_SUCCESS:
                return {
                    ...state,
                sendingRequest  : true,
                successMessage  : action.respDetail.message,
                W4DocumentDetail: action.respDetail.detail,
                message         : action.respDetail.message,
                success         : true,
                submitted       : false,
                };
            case w4constants.W4_DOC_ADD_FAILURE:
                return {
                    ...state,
                    sendingRequest: false,
                    errorMsg: action.errorMsg,
                    W4DocumentDetail: {},
                    message: action.errorMsg,
                    success: false,
                    submitted       : false,

                };

            case w4constants.STATEFORMS_SUCCESS:
                    return {
                        ...state,
                    sendingRequest  : true,
                    stateList   : action.stateList,
                    success         : true,
                    };
            case w4constants.STATEFORMS_FAILURE:
                return {
                    ...state,
                    sendingRequest: false,
                    stateList: [],
                    message: action.errorMsg,
                    success: false
                };
            case w4constants.W4_RESET_STATE:
                return {
                    initialState
                };
    
        default:
            return state
    }
}