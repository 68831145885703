/**
 * businessActions
 *
 * @package                onboard
 * @subpackage             businessActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all business actions
 */ 

import { I9constants,configConstants } from '../_constants';
import { I9FormService } from '../_services';

export const I9formAction = {
    autofilldetails,
    getI9DocList,
    updateI9Form,
    saveI9EmpResident,
    resetState
   
};

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for document list for list a b and c
 * @return                JSON Object
 */
function getI9DocList() {
    return dispatch => {
        I9FormService.getI9DocList()
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(docListDetail) { return { type : I9constants.I9_DOC_LIST_REQUEST, docListDetail } }
    function  success(docListDetail) { return { type : I9constants.I9_DOC_LIST_SUCCESS, docListDetail } }
    function failure(error) { return { type : I9constants.I9_DOC_LIST_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for user list
 * @return                JSON Object
 */
function autofilldetails(model) {
    return dispatch => {
        I9FormService.formDetails(model)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(autofilldetail) { return { type : I9constants.I9_AUTOFILL_REQUEST, autofilldetail } }
    function  success(autofilldetail) { return { type : I9constants.I9_AUTOFILL_SUCCESS, autofilldetail } }
    function failure(error) { return { type : I9constants.I9_AUTOFILL_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}




/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for update i9 form by MGMT
 * @return                JSON Object
 */
function updateI9Form(model) {
    return dispatch => {
        dispatch(request({ model }));
        I9FormService.updateI9(model)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let successMsg = { 'message' : responseData.message, 'detail' : responseData.result };
                        dispatch(success(successMsg));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(model) { return { type : I9constants.I9_UPDATE_REQUEST, model } }
    function  success(successMsg) { return { type : I9constants.I9_UPDATE_SUCCESS, successMsg } }
    function failure(error) { return { type : I9constants.I9_UPDATE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for save emp resident detail
 * @return                JSON Object
 */
function saveI9EmpResident(model) {
    return dispatch => {
        dispatch(request({ model }));
        I9FormService.saveI9EmpResident(model)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let respDetail = { 'message' : responseData.message, 'detail' : responseData.data };
                        dispatch(success(respDetail));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(model) { return { type : I9constants.I9_EMP_RES_REQUEST, model } }
    function  success(respDetail) { return { type : I9constants.I9_EMP_RES_SUCCESS, respDetail } }
    function failure(error) { return { type : I9constants.I9_EMP_RES_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

function resetState()
{
    return dispatch => { dispatch(request()); }
    function request() { return { type : I9constants.I9_RESET_STATE } }
}