/**
 * tpaCompanyActions
 *
 * @package                onboard
 * @subpackage             tpaCompanyActions
 * @category               Actions
 * @DateOfCreation         28 sept 2019
 * @ShortDescription       This is responsible for all TPA Company actions
 */ 

import { tpaCompanyConstants,configConstants } from '../_constants';
import { tpaCompanyService } from '../_services';

export const tpaCompanyActions = {
    tpaCompanyList,
    resetState,
    tpaCompanySave,
    editTPACompany,
    tpaCompanyUpdate,
};



/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for tpaCompany list
 * @return                JSON Object
 */
function tpaCompanyList(page, pageSize, sorted, filtered) {
    return dispatch => {
        tpaCompanyService.tpaCompanyList(page, pageSize, sorted, filtered)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(tpaCompanyList) { return { type : tpaCompanyConstants.TPACOMPANY_REQUEST, tpaCompanyList } }
    function  success(tpaCompanyList) { return { type : tpaCompanyConstants.TPACOMPANY_SUCCESS, tpaCompanyList } }
    function failure(error) { return { type : tpaCompanyConstants.TPACOMPANY_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for save tpaCompany detail
 * @param {object} detail       tpaCompany detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function tpaCompanySave(detail, tpaCompanyList) {
    return dispatch => {
        dispatch(request({ detail }));
        tpaCompanyService.tpaCompanySave(detail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        // const index = businessList.findIndex(
                        //             i => 
                        //                 i.BusinessId == detail.BusinessId
                        //             );
                        // if(businessList[index]) {
                        //     businessList[index] = detail;
                        // }else{
                        //     detail['BusinessId'] = data.result.BusinessId;
                        //     businessList.push(detail);
                        // }
                        let responseDetail = { 'message' : responseData.message, 'detail' : responseData.data, 'tpaCompanyList' : tpaCompanyList };
                        dispatch(success(responseDetail));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };
    function request(detail) { return { type: tpaCompanyConstants.TPACOMPANY_ADD_REQUEST, detail } }
    function success(responseDetail) { return { type : tpaCompanyConstants.TPACOMPANY_ADD_SUCCESS, responseDetail } }
    function failure(error) { return { type : tpaCompanyConstants.TPACOMPANY_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for update tpaCompany detail
 * @param {object} detail       tpaCompany detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function tpaCompanyUpdate(detail) {
    return dispatch => {
        dispatch(request({ detail }));
        tpaCompanyService.tpaCompanyUpdate(detail)
            .then(
                response => { 
                    let data = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let successMsg = { 'message' : data.message, 'detail' : data.result, 'tpaCompanyList' : tpaCompanyList };
                        dispatch(success(successMsg));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(detail) { return { type: tpaCompanyConstants.TPACOMPANY_UPDATE_REQUEST, detail } }
    function success(successMsg) { return { type : tpaCompanyConstants.TPACOMPANY_UPDATE_SUCCESS, successMsg } }
    function failure(error) { return { type : tpaCompanyConstants.TPACOMPANY_UPDATE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}


function editTPACompany(Id) {
    return dispatch => {
        tpaCompanyService.editTPACompany(Id)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(editTPACompanyDetail) { return { type : tpaCompanyConstants.TPACOMPANY_EDIT_REQUEST, editTPACompanyDetail } }
    function  success(editTPACompanyDetail) { return { type : tpaCompanyConstants.TPACOMPANY_EDIT_SUCCESS, editTPACompanyDetail } }
    function failure(error) { return { type : tpaCompanyConstants.TPACOMPANY_EDIT_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}


/**
* @DateOfCreation        8 july 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : tpaCompanyConstants.TPACOMPANY_RESET_STATE } }
}