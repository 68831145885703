import { forgotConstants, configConstants } from '../_constants';
import { forgotPasswordService } from '../_services';
import { utilityHelper } from '../_helpers';

/**
 * forgotPasswordActions
 *
 * @package                onboard
 * @subpackage             forgotPasswordActions
 * @category               Actions
 * @DateOfCreation         16 sept 2019
 * @ShortDescription       This is responsible to handle all action related to Forgot password
 */
export const forgotPasswordActions = {
    forgotSubmit,
    resetState
};

/**
* @DateOfCreation        16 sept 2019
* @ShortDescription      This function is responsible for submit the forgot password form
* @param                 JSON user, This contains full user input data 
* @return                JSON Object
*/
function forgotSubmit(user) {
    return dispatch => {
        dispatch(request({ user }));
        forgotPasswordService.resetPassword(user)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };
    
    // Actions defination that will perform according dispatch call and send data to reducer
    function request(responseData)   { return   { type  : forgotConstants.FORGOT_REQUEST, responseData } }
    function success(responseData) { return   { type  : forgotConstants.FORGOT_SUCCESS, responseData } }
    function failure(error)  { return   { type  : forgotConstants.FORGOT_FAILURE, error } }
}

function resetState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : forgotConstants.FORGOT_RESET_STATE } }
}

