export const a4constants = {
    AUTOFILL_REQUEST        : 'AUTOFILL_REQUEST',
    AUTOFILL_SUCCESS        : 'AUTOFILL_SUCCESS',
    AUTOFILL_FAILURE        : 'AUTOFILL_FAILURE',
    A4_ADD_REQUEST        : 'A4_ADD_REQUEST',
    A4_ADD_SUCCESS        : 'A4_ADD_SUCCESS',
    A4_ADD_FAILURE        : 'A4_ADD_FAILURE',
    A4_UPDATE_REQUEST        : 'A4_UPDATE_REQUEST',
    A4_UPDATE_SUCCESS        : 'A4_UPDATE_SUCCESS',
    A4_UPDATE_FAILURE        : 'A4_UPDATE_FAILURE',
 
    A4_RESET_STATE    : 'A4_RESET_STATE',
};