import React from 'react';
import "./dropdown.css";
import { NavLink } from 'react-router-dom';
import { Nav, NavItem } from 'react-bootstrap';
import { headerActions } from '../../../_actions';
import { connect } from 'react-redux';
import { configConstants } from '../../../_constants';
import { DropdownMenu } from './Dropdown';

class DropdownContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayMenu: false,
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);

  };

  handleLogout() {
    const { dispatch } = this.props;
    dispatch(headerActions.logout());
  }

  showDropdownMenu(event) {
    event.preventDefault();
    event.stopPropagation()
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
      });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  render() {
    
    return (
      <div className="dd">
        <div className="dropdown">
          <div onClick={this.showDropdownMenu}> My Account <i className="fa fa-caret-down"></i></div>
          <DropdownMenu
            handleLogout      =   { this.handleLogout}
            displayMenu       =   { this.state.displayMenu }
            loggedInUserName  =   {this.props.user.firstName+ ' '+this.props.user.lastName  }
            loggedInUserImg   =   {Object.keys(this.props.logoDetail).length > 0 ? this.props.logoDetail : configConstants.DEFAULT_IMAGE_PATH}
          />
        </div>

        
      </div>

    );
  }
}

/**
* @DateOfCreation        22 May 2019
* @ShortDescription      This function is responsible to connect store to props
* @return                View
*/
function mapStateToProps(state) {
  const { isLogoutDone, successMsg, errorMsg } = state.headerReducer;
  const { logoDetail } = state.logo
  return {
    isLogoutDone,
    successMsg,
    errorMsg,
    user: state.session.user,
    authenticated: state.session.authenticated,
    logoDetail
  };
}

// Connection with State
const connectedDropdown = connect(mapStateToProps)(DropdownContainer);
export { connectedDropdown as DropdownContainer };