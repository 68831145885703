import { loginConstants, configConstants } from '../_constants';
import { userLoginService } from '../_services';
import { sessionService } from '../_packages/redux-react-session';

/**
 * userLoginActions
 *
 * @package                onboard
 * @subpackage             userLoginActions
 * @category               Actions
 * @DateOfCreation         10 May 2019
 * @ShortDescription       This is responsible to handle all action related to login
 */
export const userLoginActions = {
    validateAuthCode,
    resendAuthCode,
    loginSubmit,
    updateState,
    resetLogin
};

/**
* @DateOfCreation        10 May 2019
* @ShortDescription      This function is responsible for submit the login form
* @param                 JSON user, This contains full user input data 
* @return                JSON Object
*/
function loginSubmit(user) {
    return dispatch => {
        dispatch(request({ user }));
        userLoginService.login(user)
            .then(
                response => {
                    let responseData = response.data;
                    if (response.status === configConstants.SUCCESS_CODE) {
                        if (responseData.data.token !== undefined && responseData.data.token !== null) {
                            localStorage.removeItem('otpResponse');
                            sessionService.saveSession(responseData.data.token);
                            sessionService.saveUser(responseData.data.userInfo);
                            localStorage.setItem('currentLoginTime', JSON.stringify((new Date).getTime()))
                            sessionStorage.setItem('loginStatus', 'true');
                            dispatch(success(user));
                        }
                        else {
                            localStorage.setItem('otpResponse', JSON.stringify(responseData.data))
                            dispatch(success({ message: responseData.message, data: responseData.data }));
                        }
                    } else if (response.status === configConstants.ERROR_CODE) {
                        let errorMsg = responseData.message;
                        dispatch(failure(errorMsg));
                    } else if (response.status === configConstants.UNAUTHENTICATE_CODE) {
                        let errorMsg = responseData.message;
                        dispatch(failure(errorMsg));
                    } else if (response.status === configConstants.EXCEPTION_CODE) {
                        let errorMsg = response.statusText;
                        dispatch(failure(response.statusText));
                    } else {
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                if (response.data == undefined) {
                    let errorMsg = 'Network Error';
                    dispatch(failure(errorMsg));
                }

            });
    };

    // Actions defination that will perform according dispatch call and send data to reducer
    function request(user) { return { type: loginConstants.GET_OTP_REQUEST, user } }
    function success(result) { return { type: loginConstants.GET_OTP_SUCCESS, result } }
    function failure(error) { return { type: loginConstants.GET_OTP_FAILURE, error } }
}

/**
* @DateOfCreation        30 May 2023
* @ShortDescription      This function is responsible for submit the login form
* @param                 JSON user, This contains full user input data 
* @return                JSON Object
*/
function validateAuthCode(user) {
    return dispatch => {
        dispatch(request({ user }));
        userLoginService.validateAuthCode(user)
            .then(
                response => {
                    let responseData = response.data;
                    if (response.status === configConstants.SUCCESS_CODE) {
                        if (responseData.success) {
                            // Set access token and user in cookies 
                            localStorage.removeItem('otpResponse');
                            sessionService.saveSession(responseData.data.token);
                            sessionService.saveUser(responseData.data.userInfo);
                            localStorage.setItem('currentLoginTime', JSON.stringify((new Date).getTime()))
                            sessionStorage.setItem('loginStatus', 'true');
                            dispatch(success(user));
                        } else {
                            let errorMsg = responseData.message;
                            dispatch(failure(errorMsg));
                        }
                    } else if (response.status === configConstants.ERROR_CODE) {
                        let errorMsg = responseData.message;
                        dispatch(failure(errorMsg));
                    } else if (response.status === configConstants.UNAUTHENTICATE_CODE) {
                        let errorMsg = responseData.message;
                        dispatch(failure(errorMsg));
                    } else if (response.status === configConstants.EXCEPTION_CODE) {
                        let errorMsg = response.statusText;
                        dispatch(failure(response.statusText));
                    } else {
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                if (response.data == undefined) {
                    let errorMsg = 'Network Error';
                    dispatch(failure(errorMsg));
                }

            });
    };

    // Actions defination that will perform according dispatch call and send data to reducer
    function request(user) { return { type: loginConstants.LOGIN_REQUEST, user } }
    function success(result) { return { type: loginConstants.LOGIN_SUCCESS, result } }
    function failure(error) { return { type: loginConstants.LOGIN_FAILURE, error } }
}

/**
* @DateOfCreation        10 May 2019
* @ShortDescription      This function is responsible for submit the login form
* @param                 JSON user, This contains full user input data 
* @return                JSON Object
*/
function resendAuthCode(user) {
    return dispatch => {
        dispatch(request({ user }));
        userLoginService.resendAuthCode(user)
            .then(
                response => {
                    let responseData = response.data;
                    if (response.status === configConstants.SUCCESS_CODE) {
                        if (responseData.success) {
                            localStorage.setItem('otpResponse', JSON.stringify(responseData.data))
                            dispatch(success({ message: responseData.message, data: responseData.data }));
                        } else {
                            let errorMsg = responseData.message;
                            dispatch(failure(errorMsg));
                        }
                    } else if (response.status === configConstants.ERROR_CODE) {
                        let errorMsg = responseData.message;
                        dispatch(failure(errorMsg));
                    } else if (response.status === configConstants.UNAUTHENTICATE_CODE) {
                        let errorMsg = responseData.message;
                        dispatch(failure(errorMsg));
                    } else if (response.status === configConstants.EXCEPTION_CODE) {
                        let errorMsg = response.statusText;
                        dispatch(failure(response.statusText));
                    } else {
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                if (response.data == undefined) {
                    let errorMsg = 'Network Error';
                    dispatch(failure(errorMsg));
                }

            });
    };

    // Actions defination that will perform according dispatch call and send data to reducer
    function request(user) { return { type: loginConstants.GET_OTP_REQUEST, user } }
    function success(result) { return { type: loginConstants.GET_OTP_SUCCESS, result } }
    function failure(error) { return { type: loginConstants.GET_OTP_FAILURE, error } }
}
/**
* @DateOfCreation        28 May 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function updateState() {
    return dispatch => {
        dispatch(request());
    }
    function request() { return { type: loginConstants.LOGIN_UPDATE_STATE } }
}

function resetLogin() {
    return dispatch => {
        dispatch(request());
    }
    function request() { return { type: loginConstants.LOGIN_RESET } }
}

