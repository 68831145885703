/**
 * locationService
 *
 * @package                onboard
 * @subpackage             locationService
 * @category               Service
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for calling all api related to location
 */
import axios from 'axios';
import { configConstants } from '../_constants';
import { utilityHelper, history } from '../_helpers';

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call location api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
export const locationService = {
    locationList,
    locationEdit,
    locationUpdate,
    locationSave,
    locationDelete
};

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call Fetch location api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function locationList(page, pageSize, sorted, filtered, businessId) { 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'Business/'+businessId+'/location',
            data    : {"pageNumber":page, "pageSize":pageSize, "sort":sorted, "filtered":filtered},
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call Fetch business location api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function locationEdit(locationId) { 
    return axios({
            method  : 'get',
            url     : configConstants.API_BASE_PATH+'Location/get/'+locationId,
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to update business
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function locationUpdate(detail) { 
    return axios({
            method  : 'put',
            url     : configConstants.API_BASE_PATH+'Location/Update',
            data    : detail,
            headers : { 'Content-Type' : 'application/json'}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or update location
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function locationSave(detail) { 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'Location/Create',
            data    : detail,
            headers : { 'Content-Type' : 'application/json'}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        27 june 2019
 * @ShortDescription      This function is responsible to delete api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function locationDelete(locationId) { 
    return axios({
            method  : 'get',
            url     : configConstants.API_BASE_PATH+'Location/delete/'+locationId,
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}
