import React from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { configConstants } from "../_constants";
import { utilityHelper } from "../_helpers";

const AdminRoute = ({ authenticated }) => {
    const location = useLocation(); 
    const userInfo = utilityHelper.getUserInfo();
    if (!userInfo) {
        return <Navigate to="/" state={{ from: location }}/>;
    }

    const isAdminOrTPA =
        userInfo.role === configConstants.USER_ROLE_ADMIN ||
        userInfo.role === configConstants.USER_ROLE_TPA;

    if (!authenticated || !isAdminOrTPA) {
        return <Navigate to="/" state={{ from: location }} />;
    }

    return <Outlet />;
};

const { bool } = PropTypes;

AdminRoute.propTypes = {
  authenticated: bool.isRequired,
};


export { AdminRoute };
