/**
 * logoActions
 *
 * @package                onboard
 * @subpackage             logoActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all business actions
 */ 

import { logoConstants,configConstants } from '../_constants';
import { logoService } from '../_services';

export const logoActions = {
    logoSave,
    resetState
};


/**
 * @DateOfCreation              25 june 2019
 * @ShortDescription            This function is responsible for save business logo detail
 * @param {object} detail       business logo detail
 * @param {array} logoSave  save business logo
 * @return                      JSON Object in succsss message, recent saved record
 */
function logoSave(detail) {
    return dispatch => {
        dispatch(request({ detail }));
        logoService.logoSave(detail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(logo) { return { type : logoConstants.LOGO_SAVE_REQUEST, logo } }
    function success(logo) { return { type : logoConstants.LOGO_SAVE_SUCCESS, logo } }
    function failure(error) { return { type : logoConstants.LOGO_SAVE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : logoConstants.LOGO_RESET_STATE } }
}