/**
 * userService
 *
 * @package                onboard
 * @subpackage             userService
 * @category               Service
 * @DateOfCreation         8 July 2019
 * @ShortDescription       This is responsible for calling all api related to business
 */
import axios from 'axios';
import { configConstants } from '../_constants';


export const userService = {
    userList,
    userSave,
    getRoles,
    businessList,
    locationList,
    getJobTitle,
    editUser,
    userDelete,
    userUpdate,
    changeUserRole,
    assignToBusinessSave,
    getAssignedBusinessLocation,
    businessLocationDelete
};

/**
 * @DateOfCreation        8 July 2019
 * @ShortDescription      This function is responsible to call Fetch business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function userList(page, pageSize, sorted, filtered,businessDetail) { 
    let detail = {
        "businessDetail": businessDetail,
        "Paging": {
            "PageNumber":page, 
            "PageSize":pageSize, 
            "Sort":sorted,
            "filtered":filtered
        }
    }
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'user/getEmployee',
            data    : detail,
            responseType: "json"
        }).then(function (response) {
           // console.log(response);
            return response;
        }).catch(response=> {
            //console.log(response.response.data);
            if(response.response.data)
                return response.response.data.message;
            else
               return response;
        });
}

/**
 * @DateOfCreation        8 July 2019
 * @ShortDescription      This function is responsible to call Fetch business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function getAssignedBusinessLocation(page, pageSize, sorted, filtered,userId) { 
    let detail = {
        "PageNumber":page, 
        "PageSize":pageSize, 
        "Sort":sorted,
        "filtered":filtered
    }
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'user/GetUserLocation/'+userId,
            data    : detail,
            responseType: "json"
        }).then(function (response) {
           // console.log(response);
            return response;
        }).catch(response=> {
            //console.log(response.response.data);
            if(response.response.data)
                return response.response.data.message;
            else
               return response;
        });
}

function assignToBusinessSave(detail)
{
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'user/AssignBusinessLocation',
        data    : detail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}

function businessLocationDelete(detail)
{
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'user/Deleteuserlocation',
        data    : detail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}

function userDelete(detail, isPermanentDelete)
{
    let userDetail = {UserId:detail, IsPermanentDelete: isPermanentDelete}
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'user/Delete',
        data    : userDetail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}


function changeUserRole(detail) { 
    return axios({
            method  : 'Post',
            url     : configConstants.API_BASE_PATH+'user/changerole',
            data    : detail,
            responseType: "json"
        }).then(function (response) {
            return response;
        }) .catch(function (error) {
            return error.response
        });
}

function getRoles() { 
    return axios({
            method  : 'Get',
            url     : configConstants.API_BASE_PATH+'user/GetRole',
           // url     :'http://localhost:62892/api/user/GetRole'
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}


function userSave(detail)
{
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'user/create',
      //url     : 'http://localhost:62892/api/user/create',
        data    : detail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}

function userUpdate(detail)
{
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'user/update',
      //url     : 'http://localhost:62892/api/user/create',
        data    : detail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response&&response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}

function businessList() { 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'business/get',
            data    : {},
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

function getJobTitle() { 
    return axios({
            method  : 'get',
            url     : configConstants.API_BASE_PATH+'user/GetJobTitle',
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

function locationList(BusinessId) { 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'business/'+BusinessId+'/Location',
            data    : {},
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}


function editUser(Id) { 
    return axios({
            method  : 'Get',
            url     : configConstants.API_BASE_PATH+'user/Get/'+Id,
            //data    : {},
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}
