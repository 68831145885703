export const w4constants = {
    AUTOFILL_REQUEST        : 'AUTOFILL_REQUEST',
    AUTOFILL_SUCCESS        : 'AUTOFILL_SUCCESS',
    AUTOFILL_FAILURE        : 'AUTOFILL_FAILURE',

    STATEFORMS_REQUEST : 'STATEFORMS_REQUEST',
    STATEFORMS_SUCCESS : 'STATEFORMS_SUCCESS',
    STATEFORMS_FAILURE : 'STATEFORMS_FAILURE',

    W4_ADD_REQUEST        : 'W4_ADD_REQUEST',
    W4_ADD_SUCCESS        : 'W4_ADD_SUCCESS',
    W4_ADD_FAILURE        : 'W4_ADD_FAILURE',

    W4_DOC_ADD_REQUEST : 'W4_DOC_ADD_REQUEST',
    W4_DOC_ADD_SUCCESS : 'W4_DOC_ADD_SUCCESS',
    W4_DOC_ADD_FAILURE : 'W4_DOC_ADD_FAILURE',
    
    W4_RESET_STATE    : 'W4_RESET_STATE',
};