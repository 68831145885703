/**
 * sharedActions
 *
 * @package                onboard
 * @subpackage             sharedActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all master list actions
 */ 

import { sharedConstants,configConstants } from '../_constants';
import { sharedService } from '../_services';

export const sharedActions = {
    getStates,
};


/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible for state list
 * @return                JSON Object
 */
function getStates() {
    return dispatch => {
        sharedService.getStates()
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(statesList) { return { type : sharedConstants.MASTER_STATES_REQUEST, statesList } }
    function success(statesList) { return { type : sharedConstants.MASTER_STATES_SUCCESS, statesList } }
    function failure(error) { return { type : sharedConstants.MASTER_STATES_FAILURE, errorMsg:error } }
}
