import { Cookies } from 'react-cookie';
import { configConstants } from '../_constants';
import { sessionService } from '../_packages/redux-react-session';
import { history } from '../_helpers/history';
/**
 * utilityHelper
 *
 * @package                onboard
 * @subpackage             utilityHelper
 * @category               Helper
 * @DateOfCreation         11 May 2019
 * @ShortDescription       This is responsible for exporting all utility functions
 */

export const utilityHelper = {
    jsonToFormData,
    getFirstErrorMessage,
    isObjectEmpty,
    getLoginAccessToken,
    getUserInfo,
    doLogout,
    setUpdateUserInfo,
    setUpdatedProfileImage,
    setUserAndLoginToken,
    getMonths,
    getYears,
    getStates,
    getCities,
    getSpecialisation,
    getGenders,
    getBusinessRoles,
    getGender,
    calculateAge,
    getTime,
    changeTimingFormat,
    getShiftArray,
    getMultiDimensionArrayIndex,
    getWeekdayName,
    getWeekdays,
    catchServiceErrorHandel,
    getDataConvertToOptionType
};

/**
* @DateOfCreation        11 May 2019
* @ShortDescription      This function is responsible to convert json to formdata 
* @param                 JSON jsonObj
* @param                 FORMDATA formDataObj
* @return                FORMDATA OBJ
*/
function jsonToFormData(jsonObj, formDataObj) {
    for ( var key in jsonObj ) {
        formDataObj.append(key, jsonObj[key]);
    }
    return formDataObj;
}

/**
* @DateOfCreation        11 May 2019
* @ShortDescription      This function is responsible to get first error from response 
* @param                 JSON jsonObj contains error object
* @return                String
*/
function getFirstErrorMessage(jsonObj){
    var key = Object.keys(jsonObj)[0];
    var value = jsonObj[key][0];
    return value;
}

/**
* @DateOfCreation        11 May 2019
* @ShortDescription      This function is responsible to check is object empty 
* @param                 JSON jsonObj
* @return                Boolean
*/
function isObjectEmpty(JSONObj){
    if(Object.keys(JSONObj).length === 0){
        return true;
    }else{
        return false;
    }
}

/**
* @DateOfCreation        24 May 2019
* @ShortDescription      This function is responsible set the login access token
                         and user info to cookies
* @return                String
*/
function setUserAndLoginToken(accessToken, user) {
    const cookies = new Cookies();
    cookies.set(configConstants.LOGIN_TOKEN, accessToken);
    cookies.set(configConstants.USER_INFO, user);
    return true;
}

/**
* @DateOfCreation        24 May 2019
* @ShortDescription      This function is responsible set the updated user info
                         and user info to cookies
* @return                String
*/
function setUpdateUserInfo(key, value) {
    sessionService.loadUser().then(user => {
        user[key] = value;
        sessionService.saveUser(user);
    });
    return true;
}


/**
* @DateOfCreation        24 May 2019
* @ShortDescription      This function is responsible set the updated profile
                         and user info to cookies
* @return                String
*/
function setUpdatedProfileImage(profileImage) {
    sessionService.loadUser().then(user => {
        user.doc_profile_img = profileImage;
        sessionService.saveUser(user);
    });
    return true;
}

/**
* @DateOfCreation        18 May 2019
* @ShortDescription      This function is responsible get the login access token
                         from cookies
* @return                String
*/
function getLoginAccessToken(){
    const cookies = new Cookies();
    return cookies.get(configConstants.LOGIN_TOKEN);
}

/**
* @DateOfCreation        18 May 2019
* @ShortDescription      This function is responsible to get user info from Cookies
* @return                json object
*/
function getUserInfo(){
    if(this.getLoginAccessToken()!== undefined){
        const cookies = new Cookies();
        return cookies.get(configConstants.USER_INFO);
    }
}

/**
* @DateOfCreation        22 May 2019
* @ShortDescription      This function is responsible remove all cookies
* @return                Boolean
*/
function doLogout(){
   sessionService.deleteSession();
   sessionService.deleteUser();
   localStorage.clear();
//    sessionStorage.clear();
   var cookies = document.cookie.split(";");
   for (var i = 0; i < cookies.length; i++) {
       var cookie = cookies[i];
       var eqPos = cookie.indexOf("=");
       var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
       document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
   }
   window.location.reload(true);
   return true;
}

/**
* @DateOfCreation        28 May 2019
* @ShortDescription      This function is responsible make the JSON of years
* @return                json
*/
function getYears(){
    var years = [];
    for (var i = configConstants.START_YEAR; i <= configConstants.END_YEAR; i++) {
        years.push({
            value: String(i),
            label: i
        });
    }
    return years;
}

/**
* @DateOfCreation        28 May 2019
* @ShortDescription      This function is responsible make the JSON of states
* @return                json
*/
function getStates(states){
    var d = getDataConvertToOptionType(states,'state_name','state_id');
    return d;
}

/**
* @DateOfCreation        28 May 2019
* @ShortDescription      This function is responsible make the JSON of cities
* @return                json
*/
function getCities(cities){
     var d = getDataConvertToOptionType(cities,'city_name','city_id');
    return d;
}

/**
* @DateOfCreation        28 May 2019
* @ShortDescription      This function is responsible make the JSON of states
* @return                json
*/
function getDataConvertToOptionType(data,name,id){
    if (!data) return [];
    const arrayData = Array.isArray(data) ? data : data.list;
    return arrayData.map(row => ({
        value: String(row[id]),
        label: row[name]
    }));
}
/**
* @DateOfCreation        28 May 2019
* @ShortDescription      This function is responsible make the JSON of months
* @return                json
*/
function getMonths(){
    var months = [];
    var monthNameList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    for (var i = configConstants.START_MONTH; i <= configConstants.END_MONTH; i++) {
        months.push({
            value: String(i),
            label: monthNameList[i-1]
        });
    }
    return months;
}

/**
* @DateOfCreation        31 May 2019
* @ShortDescription      This function is responsible make the JSON of from master spesialisation data
* @return                json
*/
function getSpecialisation(result){
    var specialisation = [];
    for (var i = 0; i <= result.length - 1 ; i++) {
        specialisation.push({
            value: result[i].spl_id,
            label: result[i].spl_name
        });
    }
    return specialisation;
}

/**
* @DateOfCreation        14 June 2019
* @ShortDescription      This function is responsible make the JSON of Genders
* @return                json
*/
function getGenders(genderId = null){
    var genders = [
                    { value : '1', label : 'Male' },
                    { value : '2', label : 'Female' }, 
                    { value : '3', label : 'Transgender' }
                ];

    if(genderId){
        var id = parseInt(genderId);
        switch(id) {
            case 1:
                return genders[id-1].label;
            case 2:
                return genders[id-1].label;
            case 3:
                return genders[id-1].label;
            default:
        }
    }
    return genders;
}

/**
* @DateOfCreation        14 June 2019
* @ShortDescription      This function is responsible make the JSON of Staff Roles
* @return                json
*/
function getBusinessRoles(id = null){
    let businessRoles = [
                    { value : 1, label : 'No Action', class: 'primary' },
                    { value : 2, label : 'Approved' , class: 'success'},
                    { value : 3, label : 'Rejected' , class: 'danger'},
                ];
    if(id){
        switch(id) {
            case 1:
                return businessRoles[id-1];
            case 2:
                return businessRoles[id-1];
            case 3:
                return businessRoles[id-1];
            default:
        }
    }
    return businessRoles;
}

/**
* @DateOfCreation        18 June 2019
* @ShortDescription      This function is responsible convert gender int to gender string data
* @return                String Gender
*/
function getGender(genderId){
    var gender = "Male";
    switch(genderId) {
        case 1:
            gender = "Male";
            break;
        case 2:
            gender = "Female";
            break;
        case 3:
            gender = "Transgender";
            break;
        default:
            gender = "Male";
    }
    return gender;
}


/**
* @DateOfCreation        18 June 2019
* @ShortDescription      This function is responsible calculate age from DOB
* @return                String Age
*/
function calculateAge(dateString) { 
    var age = false;
    if(dateString !== '' && dateString !== null && dateString !== undefined){
      var today = new Date();
      var birthDate = new Date(dateString.toString());
      age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
    }
  return age;
}

/**
* @DateOfCreation        14 June 2019
* @ShortDescription      This function is responsible to get the time dropdown in the Timing section
* @return                json
*/
function getTime(){
    var timeInterval = 30; 
    var times = [{'value':"Off",'label':"Off"}]; 
    var startTime = 0; 
    var ap = ['AM', 'PM']; // AM-PM
    for (var i=0;startTime<24*60; i++) {
        var hh = Math.floor(startTime/60); 
        var mm = (startTime%60);
        times.push({
            value: ("0" + (hh % 24)).slice(-2) + ("0" + mm).slice(-2),
            label: ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) +' '+ ap[Math.floor(hh/12)]
        });
        startTime = startTime + timeInterval;
    }
    return times;
}

/**
* @DateOfCreation        25 June 2019
* @ShortDescription      This function is responsible to display matrix grid in timing list
* @return                json
*/
function getShiftArray(timing){
    var array = [];
    var offArray = {'timing_id' : '', 'start_time':'', 'end_time': '', 'week_day' : '', 'clinic_name' : '' };
    var shifts = [];
    for(var rows in timing){
          array.push(timing[rows].length);
    }
    var max = Math.max(...array);
    for(var i=0; i<max; i++){
        shifts[i] = [];
        for(var j=1; j<=7; j++){
                if(timing[j][i] !== undefined){
                    shifts[i].push(timing[j][i]);
                }else{
                    offArray.week_day = j;
                    shifts[i].push(offArray);
                }
        }
    }
        return shifts;
}

/**
* @DateOfCreation        26 June 2019
* @ShortDescription      This function is responsible to find the index of a value in multidimensional array
* @param                 data - Array
* @param                 key of the value to be compared
* @param                 value to be searched for index
* @return                json
*/
function getMultiDimensionArrayIndex(data, name, value){
    for (var i in data) {
        var index = data[i].findIndex(i => i[name] === value);
        if (index > -1) {
            return [i, index];
        }
    }
}

/**
* @DateOfCreation        26 June 2019
* @ShortDescription      This function is responsible to display matrix grid in timing list
* @return                json
*/

function getWeekdayName(weekdayNumber){
    var weekdayName = "";
    switch(parseInt(weekdayNumber)) {
        case 1:
            weekdayName = "Monday";
            break;
        case 2:
            weekdayName = "Tuesday";
            break;
        case 3:
            weekdayName = "Wednesday";
            break;
        case 4:
            weekdayName = "Thursday";
            break;
        case 5:
            weekdayName = "Friday";
            break;
        case 6:
            weekdayName = "Saturday";
            break;
        case 7:
            weekdayName = "Sunday";
            break;
        default:
            weekdayName = "";
    }
    return weekdayName;
}

/**
* @DateOfCreation        26 June 2019
* @ShortDescription      This function is responsible make the JSON of Genders
* @return                json
*/
function getWeekdays(){
    var weekdays = [
                    { value: '1', label: 'Monday' },
                    { value: '2', label: 'Tuesday' },
                    { value: '3', label: 'Wednesday' },
                    { value: '4', label: 'Thursday' },
                    { value: '5', label: 'Friday' },
                    { value: '6', label: 'Saturday' },
                    { value: '7', label: 'Sunday' },
                ];

    return weekdays;
}

/**
* @DateOfCreation        27 June 2019
* @ShortDescription      This function is responsible for changing time to proper format from Database
* @return                json
*/
function changeTimingFormat(dbTiming){
    var time;
    if(dbTiming === ''){
        time = "";
    }else{
        var hours =  dbTiming.slice(0,2);
        var minutes =  dbTiming.slice(2);

        var hh =  ("0" + ((hours>12) ? (hours-12) : hours)).slice(-2);
        var mm =  ("0" + minutes).slice(-2);
        var ap = ['AM', 'PM']; // AM-PM
        time = hh + ':' + mm +' '+ ap[Math.floor(hours/12)];
    }
    return time;
}

/**
 * @DateOfCreation        04 July 2019
 * @ShortDescription      This function is responsible to catech error handel message 
 * @return                json
 */
function catchServiceErrorHandel(response){
    let res = {};
    res['data'] = {};
    res['data']['code'] = configConstants.EXCEPTION_CODE;
    res['data']['message'] = response.message;
    return res;
}

