import axios from "axios"; 
import { configConstants } from '../_constants';

/**
 * signatureService
 *
 * @package                onboard
 * @subpackage             signatureService
 * @category               Service
 * @DateOfCreation         15 july 2019
 * @ShortDescription       This is responsible for calling update signature API
 */
export const signatureService = {
    signatureUpdate
};

/**
* @DateOfCreation        15 july 2019
* @ShortDescription      This function is responsible to call update signature api
* @param                 JSON jsonObj
* @return                Response JSON jsonObj
*/
function signatureUpdate(detail) {
    return axios({
            method: 'post',
            url: configConstants.API_BASE_PATH+'user/UpdateConsent',
            data  : detail,
            headers: {'Content-Type': 'application/json'}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

