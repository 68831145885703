/**
 * w4formActions
 *
 * @package                onboard
 * @subpackage             w4formActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all business actions
 */ 

import { w4constants,configConstants } from '../_constants';
import { W4FormService } from '../_services';

export const w4formAction = {
    autofilldetails,
    getStateForms,
    savew4form,
    savew4Document,
    resetState
   
};



/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for user list
 * @return                JSON Object
 */
function autofilldetails(model) {
    return dispatch => {
        W4FormService.formDetails(model)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(autofilldetail) { return { type : w4constants.AUTOFILL_REQUEST, autofilldetail } }
    function  success(autofilldetail) { return { type : w4constants.AUTOFILL_SUCCESS, autofilldetail } }
    function failure(error) { return { type : w4constants.AUTOFILL_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for state form list
 * @return                JSON Object
 */
function getStateForms() {
    return dispatch => {
        W4FormService.getStateForms()
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(stateList) { return { type : w4constants.STATEFORMS_REQUEST, stateList } }
    function  success(stateList) { return { type : w4constants.STATEFORMS_SUCCESS, stateList } }
    function failure(error) { return { type : w4constants.STATEFORMS_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}




/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for user list
 * @return                JSON Object
 */
function savew4form(model) {
    return dispatch => {
        dispatch(request({ model }));
        W4FormService.Addw4(model)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let successMsg = { 'message' : responseData.message, 'detail' : responseData.result };
                        dispatch(success(successMsg));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };
    function request(model){ return { type: w4constants.W4_ADD_REQUEST, model}}
    function  success(successMsg) { return { type : w4constants.W4_ADD_SUCCESS, successMsg } }
    function failure(error) { return { type : w4constants.W4_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for Save w4 document
 * @return                JSON Object
 */
function savew4Document(model) {
    return dispatch => {
        dispatch(request({model}));
        W4FormService.savew4Document(model)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let respDetail = { 'message' : responseData.message, 'detail' : responseData.data };
                        dispatch(success(respDetail));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(model){ return { type: w4constants.W4_DOC_ADD_REQUEST, model}}
    function  success(respDetail) { return { type : w4constants.W4_DOC_ADD_SUCCESS, respDetail } }
    function failure(error) { return { type : w4constants.W4_DOC_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

function resetState()
{
    return dispatch => { dispatch(request()); }
    function request() { return { type : w4constants.W4_RESET_STATE } }
}