export const ddFormConstants = {
AUTOFILL_REQUEST     : 'AUTOFILL_REQUEST',
AUTOFILL_SUCCESS     : 'AUTOFILL_SUCCESS',
AUTOFILL_FAILURE     : 'AUTOFILL_FAILURE',
DD_ADD_REQUEST       : 'DD_ADD_REQUEST',
DD_ADD_SUCCESS     : 'DD_ADD_SUCCESS',
DD_ADD_FAILURE     : 'DD_ADD_FAILURE',
DD_UPDATE_REQUEST  : 'DD_UPDATE_REQUEST',
DD_UPDATE_SUCCESS  : 'DD_UPDATE_SUCCESS',
DD_UPDATE_FAILURE  : 'DD_UPDATE_FAILURE',
DD_RESET_STATE : 'DD_RESET_STATE',
};