import { forgotConstants } from '../_constants';
/**
 * forgotPass
 *
 * @package                onboard
 * @subpackage             forgotPass
 * @category               Reducers
 * @DateOfCreation         15 sept 2019
 * @ShortDescription       This is responsible for all state related to User Forgot Password
 */

//Initial State on load state and intial action with their type
const initialState = {
  successMsg : '',
  submitted  : false,
  errorMsg   : false
};
export function forgotPass(state = initialState, action) {
  switch (action.type) {
    case forgotConstants.FORGOT_REQUEST:
      return  {
        ...state,
        submitted : true 
      };
    case forgotConstants.FORGOT_SUCCESS:
      return {
        ...state,
        successMsg : action.responseData.message,
        submitted  : false 
      };
    case forgotConstants.FORGOT_FAILURE:
      return {
        ...state,
        errorMsg : action.error,
        submitted: false 
      };
    case forgotConstants.FORGOT_RESET_STATE:
      return initialState;
    default:
      return state
  }
}
