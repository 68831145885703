import React from "react";
import { NavLink } from 'react-router-dom';
import { configConstants } from '../../../_constants';
import { DropdownContainer } from "./DropdownContainer";
import '../../responsive.css';
import { Spring } from "react-spring";
import { utilityHelper } from "../../../_helpers";



export const Header = (props) => {
    return (
        <div>
            <Spring
                from={{ opacity: 0, marginTop: -100 }}
                to={{ opacity: 1, marginTop: 0 }}
            >
                {props => (

                    <div className="topHeaderBlue">
                        <div className="">
                            <div className="container">
                                <div className="topHeaderBlue--inner" style={props}>
                                    <div className="float-left">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/facebook.png'} alt="" className="p-r-10" />
                                        <img src={process.env.PUBLIC_URL + '/assets/images/twitter.png'} alt="" />
                                    </div>
                                    <div className="float-right">
                                        <span className="yellow">Toll Free:</span> <span className="white">1-800-231-5099 </span><span className="white"> or </span> <span className="yellow">Locally:</span> <span className="white">503-699-5099</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Spring>
            <div className="whiteHeader">
                <div className="container">
                    <div className="row">
                        <div className="float-left">
                            {(props.role !== configConstants.USER_ROLE_ADMIN) && <img src={utilityHelper.getUserInfo().logoUrl ? utilityHelper.getUserInfo().logoUrl : process.env.PUBLIC_URL + '/assets/images/no-image.png'} height="63" width="97" />}
                            {(props.role === configConstants.USER_ROLE_ADMIN) && <div className="powered-by">
                                <img src={process.env.PUBLIC_URL + '/assets/images/powered.png'} alt="" />
                            </div>}
                        </div>

                        {(props.role === configConstants.USER_ROLE_ADMIN) ?
                            <div className="col-12 col-lg-9 col-md-9 hed-mob">
                                <span className="d-menu d-md-none d-lg-none d-block"><i className="fa fa-bars"></i></span>
                                <ul className="navigation">
                                    <li>
                                        <NavLink to="/dashboard" className="lmenu">Businesses</NavLink>
                                    </li>
                                    {/* <li>
                                    <NavLink to="/business-list" className="lmenu">Businesses</NavLink>
                                </li> */}
                                    <li>
                                        <NavLink to="/location-list" className="lmenu">Locations</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/user-list" className="lmenu">Users</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/tpa-company-list" className="lmenu">TPA Company</NavLink>
                                    </li>
                                    {/* <li> 
                                    <NavLink to="/document" className="lmenu">Manage Documents</NavLink>
                                </li> */}

                                </ul>

                                <div className="myaccount">
                                    <DropdownContainer
                                        handleLogout={props.handleLogout}
                                    />
                                </div>

                            </div>
                            : ''
                        }
                        {props.role === configConstants.USER_ROLE_TPA ?
                            <React.Fragment>

                                <div className="col-12 col-lg-8 col-md-8 hed-mob">
                                    <span className="d-menu d-md-none d-lg-none d-block"><i className="fa fa-bars"></i></span>
                                    <ul className="navigation">
                                        <li>
                                            <NavLink to="/business-list" className="lmenu">Businesses</NavLink>
                                        </li>
                                        {/* <li><NavLink to="/TPA/dashboard" className="lmenu">Businesses</NavLink></li> */}
                                        <li>
                                            <NavLink to="/location-list" className="lmenu">Locations</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/user-list" className="lmenu">Users</NavLink>
                                        </li>
                                        {/* <li>
                                            <NavLink to={`/tpa-user-list/${props.loggedInUser.companyId}`} className="lmenu">TPA Users</NavLink>
                                        </li> */}
                                        {/* <li><NavLink to="/document" className="lmenu">Manage Documents</NavLink></li> */}
                                    </ul>
                                    <div className="myaccount">
                                        <DropdownContainer
                                            handleLogout={props.handleLogout}
                                        />
                                    </div>
                                </div>
                                <div className="powered-by-owner">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/powered.png'} alt="" />
                                </div>
                            </React.Fragment>
                            : ''
                        }
                        {props.role === configConstants.USER_ROLE_OWNER ?
                            <React.Fragment>
                                <div className="col-12 col-lg-8 col-md-8 hed-mob">

                                    <ul className="navigation">
                                        <li>
                                            <NavLink to="/Owner/dashboard" className="lmenu">Home</NavLink>
                                        </li>
                                        {/* <li>
                                            <NavLink to="/Owner/document" className="lmenu">Manage Documents</NavLink>
                                        </li> */}
                                    </ul>

                                    <span className="d-menu d-md-none d-lg-none d-block"><i className="fa fa-bars"></i></span>
                                    <div className="myaccount">
                                        <DropdownContainer
                                            handleLogout={props.handleLogout}
                                        />
                                    </div>
                                </div>
                                <div className="powered-by-owner">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/powered.png'} alt="" />
                                </div>

                            </React.Fragment>

                            : ''
                        }
                        {props.role === configConstants.USER_ROLE_MGMT ?
                            <React.Fragment>
                                <div className="col-12 col-lg-8 col-md-8 hed-mob">

                                    <ul className="navigation">
                                        <li>
                                            <NavLink to="/MGMT/dashboard" className="lmenu">Home</NavLink>
                                        </li>
                                        {/* <li>
                                            <NavLink to="/MGMT/document" className="lmenu">Manage Documents</NavLink>
                                        </li> */}
                                    </ul>

                                    <span className="d-menu d-md-none d-lg-none d-block"><i className="fa fa-bars"></i></span>
                                    <div className="myaccount">
                                        <DropdownContainer
                                            handleLogout={props.handleLogout}
                                        />
                                    </div>
                                </div>
                                <div className="powered-by-owner">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/powered.png'} alt="" />
                                </div>

                            </React.Fragment>

                            : ''
                        }
                        {props.role === configConstants.USER_ROLE_MANAGER ?
                            <React.Fragment>
                                <div className="col-12 col-lg-8 col-md-8 hed-mob">
                                    <span className="d-menu d-md-none d-lg-none d-block"><i className="fa fa-bars"></i></span>
                                    <ul className="navigation">
                                        <li>
                                            <NavLink to="/manager/dashboard" className="lmenu">Home</NavLink>
                                        </li>
                                    </ul>
                                    <div className="myaccount">
                                        <DropdownContainer
                                            handleLogout={props.handleLogout}
                                        />
                                    </div>
                                </div>
                                <div className="powered-by-owner">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/powered.png'} alt="" />
                                </div>
                            </React.Fragment>
                            : ''
                        }
                        {props.role === configConstants.USER_ROLE_EMPLOYEE ?
                            <React.Fragment>
                                <div className="col-12 col-lg-8 col-md-8 hed-mob">
                                    <span className="d-menu d-md-none d-lg-none d-block"><i className="fa fa-bars"></i></span>
                                    <ul className="navigation">
                                        <li>
                                            {/* <NavLink to="/employee/dashboard" className="lmenu">Home</NavLink> */}
                                        </li>
                                    </ul>
                                    <div className="myaccount">
                                        <DropdownContainer
                                            handleLogout={props.handleLogout}
                                        />
                                    </div>
                                </div>
                                <div className="powered-by-owner">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/powered.png'} alt="" />
                                </div>
                            </React.Fragment>
                            : ''
                        }
                        {(utilityHelper.getUserInfo().tpaCompanyLogo!== null) && <img src={utilityHelper.getUserInfo().tpaCompanyLogo} height="63" width="97" />}

                    </div>
                </div>

            </div>


        </div>
    );
}
