/**
 * ddFromAction
 *
 * @package                onboard
 * @subpackage             ddFromAction
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all business actions
 */ 

import { ddFormConstants,configConstants } from '../_constants';
import { ddFormService } from '../_services';

export const ddFormAction = {
    autofilldetail,
    saveDDForm,
    updateDDForm,
    resetState
};



/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for user list
 * @return                JSON Object
 */
function autofilldetail(model) {
    return dispatch => {
        ddFormService.formDetails(model)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(autofilldetail) { return { type : ddFormConstants.AUTOFILL_REQUEST, autofilldetail } }
    function success(autofilldetail) { return { type : ddFormConstants.AUTOFILL_SUCCESS, autofilldetail } }
    function failure(error) { return { type : ddFormConstants.AUTOFILL_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}




/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for user list
 * @return                JSON Object
 */
function saveDDForm(model, ddType) {
    return dispatch => {
        dispatch(request({ model }));
        ddFormService.saveDDForm(model, ddType)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let successMsg = { 'message' : responseData.message, 'detail' : responseData.result };
                        dispatch(success(successMsg));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(model) { return { type : ddFormConstants.DD_ADD_REQUEST, model } }
    function  success(successMsg) { return { type : ddFormConstants.DD_ADD_SUCCESS, successMsg } }
    function failure(error) { return { type : ddFormConstants.DD_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for update DD form
 * @return                JSON Object
 */
function updateDDForm(model, ddType) {
    return dispatch => {
        dispatch(request({ model }));
        ddFormService.updateDDForm(model, ddType)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        let successMsg = { 'message' : responseData.message, 'detail' : responseData.result };
                        dispatch(success(successMsg));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(model) { return { type : ddFormConstants.DD_UPDATE_REQUEST, model } }
    function success(successMsg) { return { type : ddFormConstants.DD_UPDATE_SUCCESS, successMsg } }
    function failure(error) { return { type : ddFormConstants.DD_UPDATE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

function resetState()
{
    return dispatch => { dispatch(request()); }
    function request() { return { type : ddFormConstants.DD_RESET_STATE } }
}