import axios from "axios"; 
import { configConstants } from '../_constants';

/**
 * acknowledgeService
 *
 * @package                onboard
 * @subpackage             acknowledgeService
 * @category               Service
 * @DateOfCreation         15 july 2019
 * @ShortDescription       This is responsible for calling update acknowledge API
 */
export const acknowledgeService = {
    acknowledgeUpdate
};

/**
* @DateOfCreation        15 july 2019
* @ShortDescription      This function is responsible to call update acknowledge api
* @param                 JSON jsonObj
* @return                Response JSON jsonObj
*/
function acknowledgeUpdate(detail) {
    return axios({
            method: 'post',
            url: configConstants.API_BASE_PATH+'user/UpdateAcknowledge',
            data  : detail,
            headers: {'Content-Type': 'application/json'}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

