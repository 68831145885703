export const userConstants = {
    USER_REQUEST        : 'USER_REQUEST',
    USER_SUCCESS        : 'USER_SUCCESS',
    USER_FAILURE        : 'USER_FAILURE',
    USER_EDIT_REQUEST   : 'USER_EDIT_REQUEST',
    USER_EDIT_SUCCESS   : 'USER_EDIT_SUCCESS',
    USER_EDIT_FAILURE   : 'USER_EDIT_FAILURE',
    USER_UPDATE_SUCCESS   : 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAILURE   : 'USER_UPDATE_FAILURE',
    USER_ADD_REQUEST    : 'USER_ADD_REQUEST',
    USER_ADD_SUCCESS    : 'USER_ADD_SUCCESS',
    USER_ADD_FAILURE    : 'USER_ADD_FAILURE',
    USER_REMOVE_REQUEST : 'USER_REMOVE_REQUEST',
    USER_REMOVE_SUCCESS : 'USER_REMOVE_SUCCESS',
    USER_REMOVE_FAILURE : 'USER_REMOVE_FAILURE',
    USER_ROLE_REQUEST   : 'USER_ROLE_REQUEST',
    USER_ROLE_SUCCESS   : 'USER_ROLE_SUCCESS',
    USER_ROLE_FAILURE   : 'USER_ROLE_FAILURE',
    
    USER_BUSINESS_REQUEST   : 'USER_BUSINESS_REQUEST',
    USER_BUSINESS_SUCCESS   : 'USER_BUSINESS_SUCCESS',
    USER_BUSINESS_FAILURE   : 'USER_BUSINESS_FAILURE',

    USER_JOB_TITLE_REQUEST : 'USER_JOB_TITLE_REQUEST',
    USER_JOB_TITLE_SUCCESS : 'USER_JOB_TITLE_SUCCESS',
    USER_JOB_TITLE_FAILURE : 'USER_JOB_TITLE_FAILURE',
    
    USER_LOCATION_REQUEST   : 'USER_LOCATION_REQUEST',
    USER_LOCATION_SUCCESS   : 'USER_LOCATION_SUCCESS',
    USER_LOCATION_FAILURE   : 'USER_LOCATION_FAILURE',

    USER_UPDATE_REQUEST   : 'USER_UPDATE_REQUEST',

    USER_ROLE_CHANGE_SUCCESS : 'USER_ROLE_CHANGE_SUCCESS',
    USER_ROLE_CHANGE_FAILURE : 'USER_ROLE_CHANGE_FAILURE',

    ASSIGNTOBUSINESS_ADD_REQUEST : 'ASSIGNTOBUSINESS_ADD_REQUEST',
    ASSIGNTOBUSINESS_ADD_SUCCESS : 'ASSIGNTOBUSINESS_ADD_SUCCESS',
    ASSIGNTOBUSINESS_ADD_FAILURE : 'ASSIGNTOBUSINESS_ADD_FAILURE',

    ASSIGNTOBUSINESS_DELETE_REQUEST : 'ASSIGNTOBUSINESS_DELETE_REQUEST',
    ASSIGNTOBUSINESS_DELETE_SUCCESS : 'ASSIGNTOBUSINESS_DELETE_SUCCESS',
    ASSIGNTOBUSINESS_DELETE_FAILURE : 'ASSIGNTOBUSINESS_DELETE_FAILURE',

    ASSIGNTOBUSINESS_LIST_SUCCESS : 'ASSIGNTOBUSINESS_LIST_SUCCESS',
    ASSIGNTOBUSINESS_LIST_FAILURE : 'ASSIGNTOBUSINESS_LIST_FAILURE',
    ASSIGNTOBUSINESS_RESET_STATE : 'ASSIGNTOBUSINESS_RESET_STATE',

    APPLICANT_ONBOARD: 'APPLICANT_ONBOARD',

    USER_RESET_STATE    : 'USER_RESET_STATE',
    USER_EDIT_RESET_STATE: 'USER_EDIT_RESET_STATE',
    USER_LIST_RESET_STATE: 'USER_LIST_RESET_STATE',
    USER_RESET_STATE_FOR_ERROR    : 'USER_RESET_STATE_FOR_ERROR'
};
