export const tpaUserConstants = {
    TPAUSER_REQUEST        : 'TPAUSER_REQUEST',
    TPAUSER_SUCCESS        : 'TPAUSER_SUCCESS',
    TPAUSER_FAILURE        : 'TPAUSER_FAILURE',
    TPAUSER_EDIT_REQUEST   : 'TPAUSER_EDIT_REQUEST',
    TPAUSER_EDIT_SUCCESS   : 'TPAUSER_EDIT_SUCCESS',
    TPAUSER_EDIT_FAILURE   : 'TPAUSER_EDIT_FAILURE',
    TPAUSER_UPDATE_SUCCESS   : 'TPAUSER_UPDATE_SUCCESS',
    TPAUSER_UPDATE_FAILURE   : 'TPAUSER_UPDATE_FAILURE',
    TPAUSER_ADD_REQUEST    : 'TPAUSER_ADD_REQUEST',
    TPAUSER_ADD_SUCCESS    : 'TPAUSER_ADD_SUCCESS',
    TPAUSER_ADD_FAILURE    : 'TPAUSER_ADD_FAILURE',
    TPAUSER_REMOVE_REQUEST : 'TPAUSER_REMOVE_REQUEST',
    TPAUSER_REMOVE_SUCCESS : 'TPAUSER_REMOVE_SUCCESS',
    TPAUSER_REMOVE_FAILURE : 'TPAUSER_REMOVE_FAILURE',

    TPAUSER_UPDATE_REQUEST   : 'TPAUSER_UPDATE_REQUEST',
    TPAUSER_UPDATE_SUCCESS   : 'TPAUSER_UPDATE_SUCCESS',
    TPAUSER_UPDATE_FAILURE   : 'TPAUSER_UPDATE_FAILURE',
    TPAUSER_RESET_STATE    : 'TPAUSER_RESET_STATE',
    TPAUSER_EDIT_RESET_STATE: 'TPAUSER_EDIT_RESET_STATE'
};