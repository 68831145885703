/**
 * registerActions
 *
 * @package                onboard
 * @subpackage             registerActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all business actions
 */ 

import { registerConstants,configConstants } from '../_constants';
import { registerService } from '../_services';

export const registerActions = {
    resetState,
    getJobTitle,
    userSave,
    getBusinessLocation
};


/**
 * @DateOfCreation              8 sept 2019
 * @ShortDescription            This function is responsible for save register detail
 * @param {object} detail       register detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function userSave(detail) {
    return dispatch => {
        dispatch(request({ detail }));
        registerService.userSave(detail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let responseDetail = { 'message' : responseData.message, 'detail' : responseData.data };
                        dispatch(success(responseDetail));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(detail) { return { type : registerConstants.REGISTER_ADD_REQUEST, detail } }
    function success(responseDetail) { return { type : registerConstants.REGISTER_ADD_SUCCESS, responseDetail } }
    function failure(error) { return { type : registerConstants.REGISTER_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 sept 2019
 * @ShortDescription            This function is responsible for master job title list
 * @param {object} detail       register detail
 * @return                      JSON Object in succsss message, recent saved record
 */

function getJobTitle() {
    return dispatch => {
        registerService.getJobTitle()
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(jobTitleList) { return { type : registerConstants.REGISTER_JOB_TITLE_REQUEST, jobTitleList } }
    function  success(jobTitleList) { return { type : registerConstants.REGISTER_JOB_TITLE_SUCCESS, jobTitleList } }
    function failure(error) { return { type : registerConstants.REGISTER_JOB_TITLE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 sept 2019
 * @ShortDescription            This function is responsible for get business detail
 * @param {object} detail       register detail
 * @return                      JSON Object in succsss message, recent saved record
 */

function getBusinessLocation(slug) {
    return dispatch => {
        registerService.getBusinessLocation(slug)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(businessLocationList) { return { type : registerConstants.REGISTER_BUSINESS_LOCATION_REQUEST, businessLocationList } }
    function  success(businessLocationList) { return { type : registerConstants.REGISTER_BUSINESS_LOCATION_SUCCESS, businessLocationList } }
    function failure(error) { return { type : registerConstants.REGISTER_BUSINESS_LOCATION_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}


/**
* @DateOfCreation        8 july 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : registerConstants.REGISTER_RESET_STATE } }
}

