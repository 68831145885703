export const registerConstants = {
    REGISTER_ADD_REQUEST    : 'REGISTER_ADD_REQUEST',
    REGISTER_ADD_SUCCESS    : 'REGISTER_ADD_SUCCESS',
    REGISTER_ADD_FAILURE    : 'REGISTER_ADD_FAILURE',
    REGISTER_RESET_STATE    : 'REGISTER_RESET_STATE',
    REGISTER_JOB_TITLE_REQUEST : 'REGISTER_JOB_TITLE_REQUEST',
    REGISTER_JOB_TITLE_SUCCESS : 'REGISTER_JOB_TITLE_SUCCESS',
    REGISTER_JOB_TITLE_FAILURE : 'REGISTER_JOB_TITLE_FAILURE',
    REGISTER_BUSINESS_LOCATION_REQUEST : 'REGISTER_BUSINESS_LOCATION_REQUEST',
    REGISTER_BUSINESS_LOCATION_SUCCESS : 'REGISTER_BUSINESS_LOCATION_SUCCESS',
    REGISTER_BUSINESS_LOCATION_FAILURE : 'REGISTER_BUSINESS_LOCATION_FAILURE',
};