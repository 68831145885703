export const tpaCompanyConstants = {
    TPACOMPANY_REQUEST        : 'TPACOMPANY_REQUEST',
    TPACOMPANY_SUCCESS        : 'TPACOMPANY_SUCCESS',
    TPACOMPANY_FAILURE        : 'TPACOMPANY_FAILURE',
    TPACOMPANY_EDIT_REQUEST   : 'TPACOMPANY_EDIT_REQUEST',
    TPACOMPANY_EDIT_SUCCESS   : 'TPACOMPANY_EDIT_SUCCESS',
    TPACOMPANY_EDIT_FAILURE   : 'TPACOMPANY_EDIT_FAILURE',
    TPACOMPANY_UPDATE_REQUEST : 'TPACOMPANY_UPDATE_REQUEST',
    TPACOMPANY_UPDATE_SUCCESS : 'TPACOMPANY_UPDATE_SUCCESS',
    TPACOMPANY_UPDATE_FAILURE : 'TPACOMPANY_UPDATE_FAILURE',
    TPACOMPANY_ADD_REQUEST    : 'TPACOMPANY_ADD_REQUEST',
    TPACOMPANY_ADD_SUCCESS    : 'TPACOMPANY_ADD_SUCCESS',
    TPACOMPANY_ADD_FAILURE    : 'TPACOMPANY_ADD_FAILURE',
    TPACOMPANY_BUSINESS_REQUEST   : 'TPACOMPANY_BUSINESS_REQUEST',
    TPACOMPANY_BUSINESS_SUCCESS   : 'TPACOMPANY_BUSINESS_SUCCESS',
    TPACOMPANY_BUSINESS_FAILURE   : 'TPACOMPANY_BUSINESS_FAILURE',
    TPACOMPANY_RESET_STATE    : 'TPACOMPANY_RESET_STATE',
    TPACOMPANY_EDIT_RESET_STATE: 'TPACOMPANY_EDIT_RESET_STATE'
};