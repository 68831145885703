/**
 * wotcFormService
 *
 * @package                onboard
 * @subpackage             wotcFormService
 * @category               Service
 * @DateOfCreation         8 July 2019
 * @ShortDescription       This is responsible for calling all api related to wotc form
 */
import axios from 'axios';
import { configConstants } from '../_constants';

export const wotcFormService = {
    formDetails,
    saveWotcForm,
    updateWotcForm
};


/**
 * @DateOfCreation        8 July 2019
 * @ShortDescription      This function is responsible to call Fetch business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function formDetails(model) { 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'WOTCForm/GetAutoFill',
            data    : model,
            responseType: "json"
        }).then(function (response) {
            //console.log(response);
            return response;
        }).catch(response=> {
            //console.log(response.response.data);
            if(response.response.data)
                return response.response.data.message;
            else
               return response;
        });
}

/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or update wotc Document
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function updateWotcForm(detail) { 
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'WOTCForm/update',
        data    : detail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}

function saveWotcForm(model)
{
    let wotcPath = 'WOTCForm/create';
    if(model.Id > 0){
        wotcPath = 'WOTCForm/update';
    }
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+wotcPath,
        data    : model,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}