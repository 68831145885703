import { userConstants } from '../_constants';

/**
 * userReducer
 *
 * @package                onboard
 * @subpackage             userReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to Admin business
 */

//Initial State on load state and intial action with their type
const initialState = {
    userList: {},
    assignedBusinessList: {},
    RoleList: [],
    jobTitleList: [],
    loader: true,
    successMessage: '',
    isUserSaved: false,
    isUserEdit: false,
    isUserUpdate: false,
    isRoleUpdate: null,
    submitted: false,
    editUserDetail: {},
    BusinessList: [],
    LocationList: [],
    success: null,
    message: '',
    isUserEditDone: false,
    isAssignToBusinessSaved: false,
    isAssignToBusinessDelete: false,
    isAssignToBusinessClose: false,
};
export function user(state = initialState, action) {
    switch (action.type) {
        case userConstants.USER_REQUEST:
            return {
                ...state,
                submitted: true,
                sendingRequest: false,
                loader: true,
            };
        case userConstants.USER_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                submitted: false,
                loader: false,
                userList: action.userList,
                pages: action.userList.totalPages,
                success: true,
            };
        case userConstants.USER_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                submitted: false,
                loader: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case userConstants.USER_ADD_REQUEST:
            return {
                ...state,
                submitted: true,
                sendingRequest: false,
                loader: true,
            };
        case userConstants.USER_ADD_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                successMessage: action.successMsg.message,
                isUserSaved: true,
                submitted: false,
                success: true,
                message: action.successMsg.message

            };
        case userConstants.USER_ADD_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isUserSaved: false,
                submitted: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };

        case userConstants.USER_ROLE_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case userConstants.USER_ROLE_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                RoleList: action.RoleList,
                //pages          : action.userList.totalPages
            };
        case userConstants.USER_ROLE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg
            };
        
        case userConstants.USER_JOB_TITLE_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case userConstants.USER_JOB_TITLE_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                jobTitleList: action.jobTitleList,
                //pages          : action.userList.totalPages
            };
        case userConstants.USER_JOB_TITLE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg
            };

        case userConstants.USER_BUSINESS_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case userConstants.USER_BUSINESS_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                BusinessList: action.BusinessList,
                //pages          : action.userList.totalPages
            };
        case userConstants.USER_BUSINESS_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg
            };

        case userConstants.USER_LOCATION_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case userConstants.USER_LOCATION_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                LocationList: action.LocationList,
                //pages          : action.userList.totalPages
            };
        case userConstants.USER_LOCATION_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg,
            };

        case userConstants.USER_EDIT_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case userConstants.USER_EDIT_SUCCESS:
            return {
                ...state,
                isUserEdit: true,
                isUserEditDone:true,
                sendingRequest: true,
                loader: false,
                editUserDetail: action.editUserDetail,
                //pages          : action.userList.totalPages
            };

        case userConstants.USER_EDIT_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg
            };
        case userConstants.APPLICANT_ONBOARD:
            return {
                ...state,
                isUserEdit: true,
                isUserEditDone:true,
                sendingRequest: true,
                loader: false,
                editUserDetail: action.employeeOnboard,
                //pages          : action.userList.totalPages
            };    
        case userConstants.USER_REMOVE_REQUEST:
            return {
                ...state,
                submitted: true,
                sendingRequest: false,
                loader: true,
            };   
        case userConstants.USER_REMOVE_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                success: true,
                message: action.successMsg.message
            };
        case userConstants.USER_REMOVE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case userConstants.USER_UPDATE_REQUEST:
            return {
                ...state,
                submitted: true,
                sendingRequest: false,
                loader: true,
            };

        case userConstants.USER_UPDATE_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                successMessage: action.successMsg.message,
                submitted: true,
                isUserUpdate: true,
                success: true,
                message: action.successMsg.message
            };
        case userConstants.USER_UPDATE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isUserUpdate: false,
                submitted: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case userConstants.USER_ROLE_CHANGE_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                successMessage: action.responseDetail.message,
                isUserEditDone:true,
                submitted: false,
                isRoleUpdate: true,
                success: true,
                message: action.responseDetail.message
            };
        case userConstants.USER_ROLE_CHANGE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isRoleUpdate: false,
                submitted: false,
                errorMsg: action.errorMsg,
                success: false,
                message:  action.errorMsg 
            };
        case userConstants.ASSIGNTOBUSINESS_ADD_REQUEST:
            return {
                ...state,
                submitted: true,
                sendingRequest: false,
                loader: true,
            };    
        case userConstants.ASSIGNTOBUSINESS_ADD_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                isAssignToBusinessSaved: true,
                submitted: true,
                success: true,
                message: action.successMsg.message

            };
        case userConstants.ASSIGNTOBUSINESS_ADD_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isAssignToBusinessSaved: false,
                submitted: false,
                success: false,
                message: action.errorMsg
            };
        case userConstants.ASSIGNTOBUSINESS_DELETE_REQUEST:
            return {
                ...state,
                submitted: true,
                sendingRequest: false,
                loader: true,
            }; 
        case userConstants.ASSIGNTOBUSINESS_DELETE_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                isAssignToBusinessDelete: true,
                submitted: true,
                success: true,
                assignedBusinessList: action.responseDetail.userBusLocList,
                pages: action.responseDetail.userBusLocList.totalPages,
                message: action.responseDetail.message

            };
        case userConstants.ASSIGNTOBUSINESS_DELETE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isAssignToBusinessDelete: false,
                submitted: false,
                success: false,
                message: action.errorMsg
            };

        case userConstants.ASSIGNTOBUSINESS_LIST_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                assignedBusinessList: action.assignedBusinessList,
                pages: action.assignedBusinessList.totalPages,
                success: true,
            };
        case userConstants.ASSIGNTOBUSINESS_LIST_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case userConstants.USER_RESET_STATE:
            return {
                ...state,
                sendingRequest: false,
                errorMsg: false,
                successMessage: false,
                isUserSaved: false,
                isUserEdit: false,
                isUserUpdate: false,
                isRoleUpdate: null,
                submitted: false,
                editUserDetail: {},
                // BusinessList: [],
                // LocationList: [],
                jobTitleList: [],
                success: null,
                message: '',
                
            
            };
            case userConstants.USER_RESET_STATE_FOR_ERROR:
                return {
                    ...state,
                    sendingRequest: false,
                    errorMsg: false,
                    successMessage: false,
                    isUserSaved: false,
                    isUserEdit: false,
                    isUserUpdate: false,
                    isRoleUpdate: null,
                    submitted: false,
                    editUserDetail: {},
                    success: null,
                    message: '',
                };
        case userConstants.USER_LIST_RESET_STATE:
            return { 
                ...state,
                sendingRequest: false,
                errorMsg: false,
                successMessage: false,
                isUserSaved: false,
                isUserEdit: false,
                isUserUpdate: false,
                isRoleUpdate: null,
                submitted: false,
                editUserDetail: {},
                // BusinessList: [],
                 LocationList: [],
                success: null,
                message: ''
            }

            case userConstants.USER_EDIT_RESET_STATE:
                return {
                    ...state,
                    success: null,
                    message: '',
                    isRoleUpdate: null,
                    //isUserEdit: false,
                    isUserEditDone:false,
                    sendingRequest: false,
                    loader: false,
                    editUserDetail: {},
                };
           
            case userConstants.ASSIGNTOBUSINESS_RESET_STATE:
                return {
                    ...state,
                    success: null,
                    message: '',
                    isAssignToBusinessSaved: false,
                    isAssignToBusinessDelete: false,
                    isAssignToBusinessClose: true,
                    submitted: false,
                    errorMsg: false,
                };
        default:
            return state
    }
}