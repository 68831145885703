/**
 * tpaUserService
 *
 * @package                onboard
 * @subpackage             tpaUserService
 * @category               Service
 * @DateOfCreation         28 sept 2019
 * @ShortDescription       This is responsible for calling all api related to business
 */
import axios from 'axios';
import { configConstants } from '../_constants';


export const tpaUserService = {
    tpaUserList,
    tpaUserSave,
    tpaUserEdit,
    tpaUserUpdate,
    tpaUserDelete,
};

/**
 * @DateOfCreation        28 sept 2019
 * @ShortDescription      This function is responsible to call Fetch business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function tpaUserList(page, pageSize, sorted, filtered,CompanyDetail) { 
    let detail = {
        "CompanyDetail": CompanyDetail,
        "Paging": {
            "PageNumber":page, 
            "PageSize":pageSize, 
            "Sort":sorted,
            "filtered":filtered
        }
    }
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'TPAUser/get',
            data    : detail,
            responseType: "json"
        }).then(function (response) {
           // console.log(response);
            return response;
        }).catch(response=> {
            //console.log(response.response.data);
            if(response.response.data)
                return response.response.data.message;
            else
               return response;
        });
}

/**
 * @DateOfCreation        28 sept 2019
 * @ShortDescription      This function is responsible to call Fetch business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */

function tpaUserSave(detail)
{
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'TPAUser/create',
        data    : detail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}

/**
 * @DateOfCreation        28 sept 2019
 * @ShortDescription      This function is responsible to call Update tpa user api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */

function tpaUserUpdate(detail)
{
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'TPAUser/update',
        data    : detail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response&&response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}

/**
 * @DateOfCreation        28 sept 2019
 * @ShortDescription      This function is responsible to call edit tpa user api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */

function tpaUserEdit(Id) { 
    return axios({
            method  : 'Get',
            url     : configConstants.API_BASE_PATH+'TPAUser/Get/'+Id,
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        28 sept 2019
 * @ShortDescription      This function is responsible to call delete tpa user api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function tpaUserDelete(detail)
{
    let userDetail = {UserId:detail}
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'user/delete',
        data    : userDetail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}
