import { changePasswordConstants,configConstants } from '../_constants';
/**
 * changePassword
 *
 * @package                onboard
 * @subpackage             changePassword
 * @category               Reducers
 * @DateOfCreation         15 May 2019
 * @ShortDescription       This is responsible for all state related to User Change Password
 */
//Initial State on load state and intial action with their type
const initialState = {
  successMsg: false,
  errorMsg:false,
  isUserNotValid:false,
  submitted: false,
};
export function changePassword(state = initialState, action) {
  switch (action.type) {
    case changePasswordConstants.PASSWORD_REQUEST:
      return {
        submitted : true
      };
    case changePasswordConstants.PASSWORD_SUCCESS:
      return {
        successMsg : action.success,
        submitted : false
      };
    case changePasswordConstants.PASSWORD_FAILURE:
      return {
        errorMsg : action.error,
        submitted : false
      };
    case configConstants.UNAUTHENTICATE:
      return {
            ...state,
            isUserNotValid : true,
            errorMsg       : false
          };
    case changePasswordConstants.PASSWORD_RESET:
      return {
                ...state,
                successMsg : false,
                errorMsg   : false,
                submitted : false
             };
    default:
      return state
  }
}