/**
 * userService
 *
 * @package                onboard
 * @subpackage             userService
 * @category               Service
 * @DateOfCreation         8 July 2019
 * @ShortDescription       This is responsible for calling all api related to business
 */
import axios from 'axios';
import { configConstants } from '../_constants';

export const I9FormService = {
    formDetails, updateI9, saveI9EmpResident, getI9DocList
};

/**
 * @DateOfCreation        8 July 2019
 * @ShortDescription      This function is responsible to call Fetch document list api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function getI9DocList() {
    return axios({
        method: 'get',
        url: configConstants.API_BASE_PATH + 'i9form/GetI9DocList',
        responseType: "json"
    }).then(function (response) {
        return response;
    }).catch(response => {
        if (response.response.data)
            return response.response.data.message;
        else
            return response;
    });
}

/**
 * @DateOfCreation        8 July 2019
 * @ShortDescription      This function is responsible to call Fetch business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function formDetails(model) {
    return axios({
        method: 'post',
        url: configConstants.API_BASE_PATH + 'i9form/getautofill',
        data: model,
        responseType: "json"
    }).then(function (response) {
        return response;
    }).catch(response => {
        if (response.response.data)
            return response.response.data.message;
        else
            return response;
    });
}

function updateI9(detail) {
    let ResStatus = (detail.ResStatus !== undefined) ? detail.ResStatus : "";
    let PermanentResidentNo = (detail.PermanentResidentNo !== undefined) ? detail.PermanentResidentNo : "";
    let AlienAuthToWorkNo = (detail.AlienAuthToWorkNo !== undefined) ? detail.AlienAuthToWorkNo : "";
    let AlienAuthToWorkExpiry = (detail.AlienAuthToWorkExpiry !== undefined) ? detail.AlienAuthToWorkExpiry : null;

    let bodyFormData = new FormData();
    bodyFormData.set('userId', detail.UserId); //
    bodyFormData.append('userDocumentId', detail.UserDocumentId); //
    bodyFormData.append('docType', detail.DocType); //
    bodyFormData.append('docAId', detail.DocAId == null ? 0 : detail.DocAId); //
    bodyFormData.append('docBId', detail.DocBId == null ? 0 : detail.DocBId); //
    bodyFormData.append('docCId', detail.DocCId == null ? 0 : detail.DocCId); //
    bodyFormData.append('userDocumentId', detail.UserDocumentId); //
    bodyFormData.append('businessId', detail.BusinessId); //
    bodyFormData.append('locationId', detail.LocationId);
    bodyFormData.append('userId', detail.UserId); //
    bodyFormData.append('resStatus', ResStatus);
    bodyFormData.append('permanentResidentNo', PermanentResidentNo);
    bodyFormData.append('alienAuthToWorkNo', AlienAuthToWorkNo);
    bodyFormData.append('alienAuthToWorkExpiry', AlienAuthToWorkExpiry);
    bodyFormData.append('ieA_DocumentTitle', detail.IEA_DocumentTitle); //
    bodyFormData.append('ieA_IssuingAuthority', detail.IEA_IssuingAuthority); //
    bodyFormData.append('ieA_DocumentNo', detail.IEA_DocumentNo); // 
    bodyFormData.append('ieA_ExpirationDate', detail.IEA_ExpirationDate); // 
    bodyFormData.append('identity_DocumentTitle', detail.Identity_DocumentTitle); //
    bodyFormData.append('identity_IssuingAuthority', detail.Identity_IssuingAuthority); // 
    bodyFormData.append('identity_DocumentNo', detail.Identity_DocumentNo); // 
    bodyFormData.append('identity_ExpirationDate', detail.Identity_ExpirationDate); // 
    bodyFormData.append('eA_DocumentTitle', detail.EA_DocumentTitle); // 
    bodyFormData.append('eA_IssuingAuthority', detail.EA_IssuingAuthority); // 
    bodyFormData.append('eA_DocumentNo', detail.EA_DocumentNo); //
    bodyFormData.append('eA_ExpirationDate', detail.EA_ExpirationDate); // 
    bodyFormData.append('docAFile', detail.DocAFile); //
    bodyFormData.append('docBFile', detail.DocBFile); // 
    bodyFormData.append('docCFile', detail.DocCFile); // 
    bodyFormData.append('IsReject', detail.IsReject); //
    bodyFormData.append('reasonType', detail.reasonType); //
    bodyFormData.append('reasonText', detail.reasonText); //
    bodyFormData.append('isDocumentSelectedByEmp', detail.IsDocumentSelectedByEmp); //


    return axios({
        method: 'post',
        url: configConstants.API_BASE_PATH + 'I9form/update',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if (response.response.data)
            return response.response.data.message;
        else
            return response;
    });
}

function saveI9EmpResident(model) {

    let ResStatus = (model.ResStatus !== undefined) ? model.ResStatus : "";
    let PermanentResidentNo = (model.PermanentResidentNo !== undefined) ? model.PermanentResidentNo : "";
    let AlienAuthToWorkNo = (model.AlienAuthToWorkNo !== undefined) ? model.AlienAuthToWorkNo : "";
    let AlienAuthToWorkExpiry = (model.AlienAuthToWorkExpiry !== undefined) ? model.AlienAuthToWorkExpiry : null;

    let bodyFormData = new FormData();
    bodyFormData.set('userId', model.UserId); //
    bodyFormData.append('userDocumentId', model.UserDocumentId); //
    bodyFormData.append('docType', model.DocType); //
    bodyFormData.append('docAId', model.DocAId == null ? 0 : model.DocAId); //
    bodyFormData.append('docBId', model.DocBId == null ? 0 : model.DocBId); //
    bodyFormData.append('docCId', model.DocCId == null ? 0 : model.DocCId); //
    bodyFormData.append('businessId', model.BusinessId); //
    bodyFormData.append('locationId', model.LocationId);
    bodyFormData.append('userId', model.UserId); //
    bodyFormData.append('resStatus', ResStatus);
    bodyFormData.append('permanentResidentNo', PermanentResidentNo);
    bodyFormData.append('alienAuthToWorkNo', AlienAuthToWorkNo);
    bodyFormData.append('alienAuthToWorkExpiry', AlienAuthToWorkExpiry);
    bodyFormData.append('ieA_DocumentTitle', model.IEA_DocumentTitle); //
    bodyFormData.append('ieA_IssuingAuthority', model.IEA_IssuingAuthority); //
    bodyFormData.append('ieA_DocumentNo', model.IEA_DocumentNo); // 
    bodyFormData.append('ieA_ExpirationDate', model.IEA_ExpirationDate); // 
    bodyFormData.append('identity_DocumentTitle', model.Identity_DocumentTitle); //
    bodyFormData.append('identity_IssuingAuthority', model.Identity_IssuingAuthority); // 
    bodyFormData.append('identity_DocumentNo', model.Identity_DocumentNo); // 
    bodyFormData.append('identity_ExpirationDate', model.Identity_ExpirationDate); // 
    bodyFormData.append('eA_DocumentTitle', model.EA_DocumentTitle); // 
    bodyFormData.append('eA_IssuingAuthority', model.EA_IssuingAuthority); // 
    bodyFormData.append('eA_DocumentNo', model.EA_DocumentNo); //
    bodyFormData.append('eA_ExpirationDate', model.EA_ExpirationDate); // 
    bodyFormData.append('docAFile', model.DocAFile !== null ? model.DocAFile : ''); //
    bodyFormData.append('docBFile', model.DocType === 1 ? "" : model.DocBFile); // 
    bodyFormData.append('docCFile', model.DocCFile !== null ? model.DocCFile : ''); // 
    bodyFormData.append('supportedDocumentType', model.SupportedDocumentType); //
    bodyFormData.append('isDocumentSelectedByEmp', model.IsDocumentSelectedByEmp); //


    let I9Path = 'I9form/Create';
    if (model.Id > 0) {
        I9Path = 'I9form/EmployeeUpdate';
    }
    return axios({
        method: 'post',
        url: configConstants.API_BASE_PATH + I9Path,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/json' }
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if (response.response.data)
            return response.response.data.message;
        else
            return response;
    });
}