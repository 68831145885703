/**
 * forgotConstants
 *
 * @package                onboard
 * @subpackage             forgotConstants
 * @category               Constants
 * @DateOfCreation         15 May 2019
 * @ShortDescription       This is responsible for Forgot Password action names
 */
export const forgotConstants = {
    FORGOT_REQUEST    	: 'FORGOT_REQUEST',
    FORGOT_SUCCESS      : 'FORGOT_SUCCESS',
    FORGOT_FAILURE      : 'FORGOT_FAILURE',
    FORGOT_RESET_STATE  : 'FORGOT_RESET_STATE'
};
