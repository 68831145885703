import { loginConstants } from '../_constants';
/**
 * userLogin
 *
 * @package                onboard
 * @subpackage             userLogin
 * @category               Reducers
 * @DateOfCreation         10 May 2019
 * @ShortDescription       This is responsible for all state related to User login
 */
export function userLogin(state = {submitted: false, otpReqSubmitted: false}, action) {
  switch (action.type) {
    case loginConstants.GET_OTP_REQUEST:
      return { 
        otpReqSubmitted: true,
        isOTPGetDone : false,
        otpResponse: undefined,
        otpSuccessMsg: '',
        otpErrorMsg: ''
      };
    case loginConstants.GET_OTP_SUCCESS:
      return { 
        otpReqSubmitted: false, 
        isOTPGetDone : true,
        otpResponse: action.result.data,
        otpSuccessMsg: action.result.message
      };
    case loginConstants.GET_OTP_FAILURE:
      return { 
        otpReqSubmitted: false, 
        otpErrorMsg : action.error
      };
    case loginConstants.LOGIN_REQUEST:
      return { 
        submitted: true 
      };
    case loginConstants.LOGIN_SUCCESS:
      return { 
        submitted: false, 
        isLoginDone : true
      };
    case loginConstants.LOGIN_FAILURE:
      return { 
        submitted: false, 
        errorMsg : action.error
      };
    case loginConstants.LOGOUT_SUCCESS:
      return { 
        submitted: false, 
        isLogoutDone : true, 
        successMsg : action.success 
      };
    case loginConstants.LOGOUT_FAILURE:
      return { 
        submitted: false, 
        isLogoutDone : false, 
        errorMsg : action.error 
      };
    case loginConstants.LOGIN_UPDATE_STATE:
      return {
        ...state,
        errorMsg      : false,
        submitted     : false,
      }    
    case loginConstants.LOGIN_RESET:
      return {
        ...state,
        otpErrorMsg      : '',
        submitted     : false,
      }    
    default:
      return state
  }

}
