import { acknowledgeConstants, configConstants } from '../_constants';
import { acknowledgeService } from '../_services';
import { utilityHelper } from '../_helpers';
/**
 * acknowledgeActions
 *
 * @package                onboard
 * @subpackage             acknowledgeActions
 * @category               Actions
 * @DateOfCreation         15 May 2019
 * @ShortDescription       This is responsible to handle all action related to change password
 */
export const acknowledgeActions = {
    acknowledgeUpdate,
    resetState
};

/**
* @DateOfCreation        15 May 2019
* @ShortDescription      This function is responsible for submit the change password form
* @param                 JSON user, This contains full user input data 
* @return                JSON Object
*/
function acknowledgeUpdate(acknowledgeDetail) {
    return dispatch => {
        acknowledgeService.acknowledgeUpdate(acknowledgeDetail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status === configConstants.SUCCESS_CODE){
                        utilityHelper.setUpdateUserInfo('resetPassword', false)
                        let successMsg = responseData.message;
                        dispatch(success(successMsg));
                    }else if(response.status === configConstants.ERROR_CODE){
                        let errorMsg = responseData.message;
                        dispatch(failure(errorMsg));
                    }else if(response.status === configConstants.EXCEPTION_CODE){
                        let errorMsg = responseData.message;
                        dispatch(failure(errorMsg));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE){
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };
    
    // Actions defination that will perform according dispatch call and send data to reducer
    function success(success) { return { type  : acknowledgeConstants.ACKNOWLEDGE_SUCCESS, success } }
    function failure(error)  { return { type  : acknowledgeConstants.ACKNOWLEDGE_FAILURE, error } }
    function unauthorize(error) { return { type: configConstants.UNAUTHENTICATE, error } }
}

/**
* @DateOfCreation        28 May 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetState(){
    return dispatch => { dispatch(request());}
    function request() { return { type: acknowledgeConstants.ACKNOWLEDGE_RESET } }
}

