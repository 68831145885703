export const employeeConstants = {
    EMPLOYEE_NOTES_REQUEST        : 'EMPLOYEE_NOTES_REQUEST',
    EMPLOYEE_NOTES_SUCCESS        : 'EMPLOYEE_NOTES_SUCCESS',
    EMPLOYEE_NOTES_FAILURE        : 'EMPLOYEE_NOTES_FAILURE',
    EMPLOYEE_DOCS_ADD_SUCCESS     : 'EMPLOYEE_DOCS_ADD_SUCCESS',
    EMPLOYEE_DOCS_ADD_FAILURE     : 'EMPLOYEE_DOCS_ADD_FAILURE',
    EMPLOYEE_NOTES_EDIT_REQUEST   : 'EMPLOYEE_NOTES_EDIT_REQUEST',
    EMPLOYEE_NOTES_EDIT_SUCCESS   : 'EMPLOYEE_NOTES_EDIT_SUCCESS',
    EMPLOYEE_NOTES_EDIT_FAILURE   : 'EMPLOYEE_NOTES_EDIT_FAILURE',
    EMPLOYEE_NOTES_UPDATE_SUCCESS   : 'EMPLOYEE_NOTES_UPDATE_SUCCESS',
    EMPLOYEE_NOTES_UPDATE_FAILURE   : 'EMPLOYEE_NOTES_UPDATE_FAILURE',
    EMPLOYEE_NOTES_ADD_SUCCESS    : 'EMPLOYEE_NOTES_ADD_SUCCESS',
    EMPLOYEE_NOTES_ADD_FAILURE    : 'EMPLOYEE_NOTES_ADD_FAILURE',
    EMPLOYEE_NOTES_REMOVE_SUCCESS : 'EMPLOYEE_NOTES_REMOVE_SUCCESS',
    EMPLOYEE_NOTES_REMOVE_FAILURE : 'EMPLOYEE_NOTES_REMOVE_FAILURE',
    EMPLOYEE_NOTES_RESET_STATE    : 'EMPLOYEE_NOTES_RESET_STATE',
    EMPLOYEE_UPDATE_PROFILE_FAILURE : 'EMPLOYEE_UPDATE_PROFILE_FAILURE',
    EMPLOYEE_UPDATE_PROFILE_SUCCESS : 'EMPLOYEE_UPDATE_PROFILE_SUCCESS',
    EMPLOYEE_NOTES_DELETE_REQUEST : 'EMPLOYEE_NOTES_DELETE_REQUEST',
    EMPLOYEE_NOTES_DELETE_SUCCESS : 'EMPLOYEE_NOTES_DELETE_SUCCESS',
    EMPLOYEE_NOTES_DELETE_FAILURE : 'EMPLOYEE_NOTES_DELETE_FAILURE',
};
