export const businessConstants = {
    BUSINESS_REQUEST        : 'BUSINESS_REQUEST',
    BUSINESS_SUCCESS        : 'BUSINESS_SUCCESS',
    BUSINESS_FAILURE        : 'BUSINESS_FAILURE',
    BUSINESS_EDIT_REQUEST   : 'BUSINESS_EDIT_REQUEST',
    BUSINESS_EDIT_SUCCESS   : 'BUSINESS_EDIT_SUCCESS',
    BUSINESS_EDIT_FAILURE   : 'BUSINESS_EDIT_FAILURE',
    BUSINESS_UPDATE_REQUEST   : 'BUSINESS_UPDATE_REQUEST',
    BUSINESS_UPDATE_SUCCESS   : 'BUSINESS_UPDATE_SUCCESS',
    BUSINESS_UPDATE_FAILURE   : 'BUSINESS_UPDATE_FAILURE',
    BUSINESS_ADD_REQUEST    : 'BUSINESS_ADD_REQUEST',
    BUSINESS_ADD_SUCCESS    : 'BUSINESS_ADD_SUCCESS',
    BUSINESS_ADD_FAILURE    : 'BUSINESS_ADD_FAILURE',
    BUSINESS_REMOVE_REMOVE : 'BUSINESS_REMOVE_REMOVE',
    BUSINESS_REMOVE_SUCCESS : 'BUSINESS_REMOVE_SUCCESS',
    BUSINESS_REMOVE_FAILURE : 'BUSINESS_REMOVE_FAILURE',
    BUSINESS_DOCUMENT_REQUEST   : 'BUSINESS_DOCUMENT_REQUEST',
    BUSINESS_DOCUMENT_SUCCESS   : 'BUSINESS_DOCUMENT_SUCCESS',
    BUSINESS_DOCUMENT_FAILURE   : 'BUSINESS_DOCUMENT_FAILURE',
    BUSINESS_USER_REQUEST : 'BUSINESS_USER_REQUEST',
    BUSINESS_USER_SUCCESS : 'BUSINESS_USER_SUCCESS',
    BUSINESS_ROLE_REQUEST : 'BUSINESS_ROLE_REQUEST',
    BUSINESS_ROLE_SUCCESS : 'BUSINESS_ROLE_SUCCESS',
    BUSINESS_ROLE_FAILURE : 'BUSINESS_ROLE_FAILURE',
    BUSINESS_USER_FAILURE :  'BUSINESS_USER_FAILURE',
    BUSINESS_RESET_STATE    : 'BUSINESS_RESET_STATE',
    BUSINESS_RESET_RESPONSE    : 'BUSINESS_RESET_RESPONSE',
};


