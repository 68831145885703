import { employeeConstants } from '../_constants';

/**
 * businessReducer
 *
 * @package                onboard
 * @subpackage             businessReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to Admin business
 */

//Initial State on load state and intial action with their type
const initialState = {
    employeeNoteList    : [],
    loader          : true,
    successMessage  : '',
    isUserSaved    : false,
    isNoteSaved : false,
    isDocumentSaved: false,
    isUserEdit     : false,
    isUserUpdate    : false,
    submitted       : false,
    editEmployeeNotesDetail : {},
    isEmployeeNotesDelete : false,
    success:null,
    message:''
};
export function employee(state = initialState, action) {
    switch (action.type) {
        case employeeConstants.EMPLOYEE_NOTES_REQUEST :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : true,
            };
        case employeeConstants.EMPLOYEE_NOTES_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                loader         : false,
                employeeNoteList: action.employeeNoteList,
                pages          : action.employeeNoteList.totalPages,
                success        : true,
            };
        case employeeConstants.EMPLOYEE_NOTES_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                errorMsg       : action.errorMsg,
                success        : false,
                message        : action.errorMsg
            };     
        case employeeConstants.EMPLOYEE_NOTES_ADD_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true, 
                isNoteSaved    : true, 
                submitted      : true,
                success        : true,
                message        : action.responseDetail.message,
                employeeNoteList: [...state.employeeNoteList.list, action.responseDetail.detail]
                
            };
        case employeeConstants.EMPLOYEE_NOTES_ADD_FAILURE :
            return  { 
                ...state,
                sendingRequest : false, 
                isNoteSaved: false, 
                submitted      : false,
                errorMsg       : action.errorMsg,
                success        : false,
                message        : action.errorMsg
            };

        case employeeConstants.EMPLOYEE_DOCS_ADD_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true, 
                isDocumentSaved: action.responseDetail.detail !== null ? true : false,
                submitted      : true,
                success        : true,
                message        : action.responseDetail.message,
                employeeNoteList: [...state.employeeNoteList.list, action.responseDetail.detail]
                
            };
        case employeeConstants.EMPLOYEE_DOCS_ADD_FAILURE :
            return  { 
                ...state,
                sendingRequest : false, 
                isDocumentSaved: false,
                submitted      : false,
                errorMsg       : action.errorMsg,
                success        : false,
                message        : action.errorMsg
            };

        case employeeConstants.EMPLOYEE_NOTES_EDIT_REQUEST :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : true,
            };
        case employeeConstants.EMPLOYEE_NOTES_EDIT_SUCCESS :
            return  { 
                ...state,
                isUserEdit     : true,
                sendingRequest : true,
                loader         : false,
                editEmployeeNotesDetail   : action.editEmployeeNotesDetail,
                //pages          : action.employeeNoteList.totalPages
            };
        case employeeConstants.EMPLOYEE_NOTES_EDIT_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                errorMsg       : action.errorMsg
            };

            
            case employeeConstants.EMPLOYEE_NOTES_UPDATE_SUCCESS :
                return  { 
                    ...state,
                    sendingRequest : true,
                    successMessage : action.successMsg.message,
                    submitted:true,
                    isUserUpdate:true,
                    success        : true,
                    message        : action.successMsg.message
                };
            case employeeConstants.EMPLOYEE_NOTES_UPDATE_FAILURE :
                return  { 
                    ...state,
                    sendingRequest : false, 
                    isUserUpdate: false, 
                    submitted      : false,
                    errorMsg       : action.errorMsg,
                    success        : false,
                    message        : action.errorMsg
                };
            case employeeConstants.EMPLOYEE_NOTES_DELETE_REQUEST:
                return {
                    ...state,
                    submitted: true,
                    sendingRequest: false,
                    loader: true,
                }; 
            case employeeConstants.EMPLOYEE_NOTES_DELETE_SUCCESS:
                return {
                    ...state,
                    sendingRequest: true,
                    isEmployeeNotesDelete: true,
                    submitted: true,
                    success: true,
                    employeeNoteList: action.responseDetail.employeeNoteList,
                    pages: action.responseDetail.employeeNoteList.totalPages,
                    message: action.responseDetail.message
    
                };
            case employeeConstants.EMPLOYEE_NOTES_DELETE_FAILURE:
                return {
                    ...state,
                    sendingRequest: false,
                    isEmployeeNotesDelete: false,
                    submitted: false,
                    success: false,
                    message: action.errorMsg
                };
            case employeeConstants.EMPLOYEE_NOTES_RESET_STATE:
                return {
                    ...state,
                    sendingRequest  : false, 
                    errorMsg        : false,
                    successMessage  : false,
                    isUserSaved    : false,
                    isNoteSaved : false,
                    isDocumentSaved : false,
                    isUserEdit     : false,
                    isUserUpdate    : false,
                    submitted       : false,
                    editEmployeeNotesDetail : {},
                    success        : null,
                    message        : '',
                    isEmployeeNotesDelete: false,
                    
                    };
        default:
            return state
    }
}