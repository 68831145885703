export const locationConstants = {
    LOCATION_REQUEST        : 'LOCATION_REQUEST',
    LOCATION_SUCCESS        : 'LOCATION_SUCCESS',
    LOCATION_FAILURE        : 'LOCATION_FAILURE',
    LOCATION_EDIT_REQUEST   : 'LOCATION_EDIT_REQUEST',
    LOCATION_EDIT_SUCCESS   : 'LOCATION_EDIT_SUCCESS',
    LOCATION_EDIT_FAILURE   : 'LOCATION_EDIT_FAILURE',
    LOCATION_UPDATE_REQUEST   : 'LOCATION_UPDATE_REQUEST',
    LOCATION_UPDATE_SUCCESS   : 'LOCATION_UPDATE_SUCCESS',
    LOCATION_UPDATE_FAILURE   : 'LOCATION_UPDATE_FAILURE',
    LOCATION_ADD_REQUEST    : 'LOCATION_ADD_REQUEST',
    LOCATION_ADD_SUCCESS    : 'LOCATION_ADD_SUCCESS',
    LOCATION_ADD_FAILURE    : 'LOCATION_ADD_FAILURE',
    LOCATION_REMOVE_SUCCESS : 'LOCATION_REMOVE_SUCCESS',
    LOCATION_REMOVE_FAILURE : 'LOCATION_REMOVE_FAILURE',
    LOCATION_RESET_STATE    : 'LOCATION_RESET_STATE',
};


