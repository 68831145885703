import { I9constants } from '../_constants';

/**
 * businessReducer
 *
 * @package                onboard
 * @subpackage             businessReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to Admin business
 */

//Initial State on load state and intial action with their type
const initialState = {
    autofilldetail: {},
    docListDetail:{},
    residenDetail:{},
    loader: true,
    isI9Saved: false,
    submitted: false,
    success: null,
    message: ''
};
export function I9Form(state = initialState, action) {
    switch (action.type) {
        case I9constants.I9_AUTOFILL_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case I9constants.I9_AUTOFILL_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                autofilldetail: action.autofilldetail,
               // success: true,
            };
        case I9constants.AUTOFILL_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };

            case I9constants.I9_DOC_LIST_REQUEST:
                return {
                    ...state,
                    sendingRequest: false,
                    loader: true,
                };
            case I9constants.I9_DOC_LIST_SUCCESS:
                return {
                    ...state,
                    sendingRequest: true,
                    loader: false,
                    docListDetail: action.docListDetail,
                   // success: true,
                };
            case I9constants.I9_DOC_LIST_FAILURE:
                return {
                    ...state,
                    sendingRequest: false,
                    loader: false,
                    errorMsg: action.errorMsg,
                    success: false,
                    message: action.errorMsg
                };
            case I9constants.I9_UPDATE_REQUEST:
                return {
                    ...state,
                    sendingRequest: false,
                    loader: true,
                    submitted: true,
                };

            case I9constants.I9_UPDATE_SUCCESS:
                return {
                    ...state,
                    sendingRequest: true,
                    isI9Saved: true,
                    submitted: false,
                    success: true,
                    message: action.successMsg.message
                };
            case I9constants.I9_UPDATE_FAILURE:
                return {
                    ...state,
                    sendingRequest: false,
                    isI9Saved: false,
                    submitted: false,
                    errorMsg: action.errorMsg,
                    success: false,
                    message: action.errorMsg
                };
            case I9constants.I9_EMP_RES_REQUEST:
                return {
                    ...state,
                    sendingRequest: false,
                    loader: true,
                    submitted: true,
                };
            case I9constants.I9_EMP_RES_SUCCESS:
                return {
                    ...state,
                    sendingRequest: true,
                    submitted: false,
                    success: true,
                    residenDetail: action.respDetail.detail,
                    message: action.respDetail.message
                };
            case I9constants.I9_EMP_RES_FAILURE:
                return {
                    ...state,
                    sendingRequest: false,
                    submitted: false,
                    errorMsg: action.errorMsg,
                    success: false,
                    message: action.errorMsg
                };
                
            
            case I9constants.I9_RESET_STATE:
                return {
                    initialState
                };
        
            default:
                return state
    }
}