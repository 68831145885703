/**
 * sharedService
 *
 * @package                onboard
 * @subpackage             sharedService
 * @category               Service
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for calling all api related to business
 */
import axios from 'axios';
import { configConstants } from '../_constants';

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
export const sharedService = {
    getStates,
};


/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to call Fetch states api
* @param                 JSON jsonObj
* @return                Response JSON jsonObj
*/
function getStates() { 
   return axios({
           method  : 'get',
           url     : configConstants.API_BASE_PATH+'Masters/State',
       }).then(function (response) {
           return response;
       }).catch(function (response) {
           return response;
       });
}