
/**
 * resetConstants
 *
 * @package                onboard
 * @subpackage             resetConstants
 * @category               Constants
 * @DateOfCreation         15 May 2019
 * @ShortDescription       This is responsible for Reset Password action names
 */
export const resetConstants = {
    RESET_REQUEST      : 'RESET_REQUEST',
    RESET_SUCCESS      : 'RESET_SUCCESS',
    RESET_FAILURE      : 'RESET_FAILURE'
};

