/**
 * employeeActions
 *
 * @package                onboard
 * @subpackage             employeeActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all employee actions
 */ 

import { employeeConstants,configConstants } from '../_constants';
import { employeeService } from '../_services';

export const employeeActions = {
    employeeNotesList,
    resetState,
    employeeNoteSave,
    employeeDocumentSave,
    editEmployee,
    employeeUpdate,
    notesDelete
};



/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for employee notes list
 * @return                JSON Object
 */
function employeeNotesList(page, pageSize, sorted, filtered,userDetail) {
    return dispatch => {
        employeeService.employeeNotesList(page, pageSize, sorted, filtered, userDetail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(employeeNoteList) { return { type : employeeConstants.EMPLOYEE_NOTES_REQUEST, employeeNoteList } }
    function  success(employeeNoteList) { return { type : employeeConstants.EMPLOYEE_NOTES_SUCCESS, employeeNoteList } }
    function failure(error) { return { type : employeeConstants.EMPLOYEE_NOTES_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for save employee note detail
 * @param {object} detail       employee detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function employeeNoteSave(detail,employeeNoteList) {
    return dispatch => {
        employeeService.employeeNoteSave(detail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let responseDetail = { 'message' : responseData.message, 'detail' : responseData.data, 'employeeNoteList':employeeNoteList};
                        dispatch(success(responseDetail));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function success(responseDetail) { return { type : employeeConstants.EMPLOYEE_NOTES_ADD_SUCCESS, responseDetail } }
    function failure(error) { return { type : employeeConstants.EMPLOYEE_NOTES_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for save employee document detail
 * @param {object} detail       employee detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function employeeDocumentSave(detail,employeeNoteList) {
    return dispatch => {
        employeeService.employeeNoteSave(detail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let responseDetail = { 'message' : responseData.message, 'detail' : responseData.data, 'employeeNoteList':employeeNoteList};
                        dispatch(success(responseDetail));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function success(responseDetail) { return { type : employeeConstants.EMPLOYEE_DOCS_ADD_SUCCESS, responseDetail } }
    function failure(error) { return { type : employeeConstants.EMPLOYEE_DOCS_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for update employee detail
 * @param {object} detail       employee detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function employeeUpdate(detail) {
    return dispatch => {
        employeeService.employeeUpdate(detail)
            .then(
                response => { 
                    let data = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let successMsg = { 'message' : data.message, 'detail' : data.result };
                        dispatch(success(successMsg));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function success(successMsg) { return { type : employeeConstants.EMPLOYEE_NOTES_UPDATE_SUCCESS, successMsg } }
    function failure(error) { return { type : employeeConstants.EMPLOYEE_NOTES_UPDATE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}


function editEmployee(Id) {
    return dispatch => {
        employeeService.editEmployee(Id)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(editEmployeeDetail) { return { type : employeeConstants.EMPLOYEE_NOTES_EDIT_REQUEST, editEmployeeDetail } }
    function  success(editEmployeeDetail) { return { type : employeeConstants.EMPLOYEE_NOTES_EDIT_SUCCESS, editEmployeeDetail } }
    function failure(error) { return { type : employeeConstants.EMPLOYEE_NOTES_EDIT_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for delete business location detail
 * @param {object} detail       user business location detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function notesDelete(notesId,employeeNoteList) {
    return dispatch => {
        dispatch(request({ notesId }));
        employeeService.notesDelete(notesId)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        employeeNoteList.list.filter((item)=>{
                           return item.id !== notesId
                        })
                        let responseDetail = { 'message' : responseData.message, 'employeeNoteList':employeeNoteList};
                        dispatch(success(responseDetail));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(detail) { return { type: employeeConstants.EMPLOYEE_NOTES_DELETE_REQUEST, detail } }
    function success(responseDetail) { return { type : employeeConstants.EMPLOYEE_NOTES_DELETE_SUCCESS, responseDetail } }
    function failure(error) { return { type : employeeConstants.EMPLOYEE_NOTES_DELETE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}


/**
* @DateOfCreation        8 july 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : employeeConstants.EMPLOYEE_NOTES_RESET_STATE } }
}
