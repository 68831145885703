/**
 * documentService
 *
 * @package                onboard
 * @subpackage             documentService
 * @category               Service
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for calling all api related to business
 */
import axios from 'axios';
import { configConstants } from '../_constants';

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
export const documentService = {
    documentSave,
    signDocument,
    signLevelDocument,
    getDocumentList,
    getBusinessDocumentWithLevel5,
    rejectDocument,
    getAllBusinessDocument,
    getApplicationsList,
    updateApplicationStatus,
    businessDocumentSave,
    generateDocument,
    documentDelete
};


/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or update business document
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function documentSave(detail) { 
    let bodyFormData = new FormData();
    bodyFormData.set('BusinessId', detail.BusinessId);
    bodyFormData.append('BusinessFileName', detail.BusinessFileName); 
    bodyFormData.append('BusinessFile', detail.BusinessFile); 
    bodyFormData.append('FormLevel', detail.FormLevel);
    bodyFormData.append('isAcknowladge', detail.isAcknowladge);
    bodyFormData.append('isExpired', detail.isExpirationDateCheck);
    bodyFormData.append('expiryDate', detail.expirationDate);


    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'documents/Upload',
            data    : bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        28 Sept 2021
 * @ShortDescription      This function is responsible to call api to delete document
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
 function documentDelete(detail) { 
    
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'documents/RejectDocuments',
            data    : detail,
            responseType: "json"
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        20 july 2020
 * @ShortDescription      This function is responsible to call api to add or update reject document
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function rejectDocument(detail) { 
    
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'documents/RejectDocuments',
            data    : detail,
            responseType: "json"
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        8 July 2019
 * @ShortDescription      This function is responsible to call Fetch management document list
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function getDocumentList(page, pageSize, sorted, filtered, businessDetail) { 
    let detail = {
        "DocumentDetails": businessDetail,
        "Paging": {
            "PageNumber":page, 
            "PageSize":pageSize, 
            "Sort":sorted,
            "filtered":filtered
        }
    }
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'Documents/GetdocumentList',
            data    : detail,
            responseType: "json",
        }).then(function (response) {
            return response;
        }).catch(response=> {
            if(response.response.data)
                return response.response.data.message;
            else
               return response;
        });
}

/**
 * @DateOfCreation        8 July 2019
 * @ShortDescription      This function is responsible to call Fetch management document list
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function getApplicationsList(page, pageSize, sorted, filtered, applicationDetail) { 
    let detail = {
        "AppDetails": applicationDetail,
        "Paging": {
            "PageNumber":page, 
            "PageSize":pageSize, 
            "Sort":sorted,
        }
    }
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'documents/GetRegistration',
            data    : detail,
            responseType: "json"
        }).then(function (response) {
            return response;
        }).catch(response=> {
            if(response.response.data)
                return response.response.data.message;
            else
               return response;
        });
}

/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or update business document
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function updateApplicationStatus(detail) { 

    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'documents/UpdateRegistration',
            data    : detail,
            responseType: "json"
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or update business document
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function signDocument(detail) { 
    let formName='';
    if(detail.documentId == configConstants.W4_FORM_ID){
        formName = 'w4form';
    }else if(detail.documentId == configConstants.I9_FORM_ID){
        formName = 'i9form';
    }else if(detail.documentId == configConstants.WOTC_FORM_ID){
        formName = 'WOTCForm';
    }else if(detail.documentId == configConstants.DD_FORM_ID){
        formName = 'DirectDeposit';
    }else if(detail.documentId == configConstants.A4_FORM_ID){
        formName = 'a4form';
    }
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+formName+'/SignAndGeneratePdf',
            data    : { 'UserDocumentId': detail.userDocumentId},
            headers : { 'Content-Type' : 'application/json'}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or update business document
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function signLevelDocument(detail) { 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'Documents/ApproveTaskForms',
            data    : { 'UserDocumentId': detail.userDocumentId},
            headers : { 'Content-Type' : 'application/json'}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or generate business document
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function generateDocument(detail) { 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'documents/GenerateForms',
            headers : { 'Content-Type' : 'application/json'},
            data: detail,
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}


/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or update business required document
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function businessDocumentSave(detail) { 
    let bodyFormData = new FormData();
    bodyFormData.set('DocumentId', detail.DocumentId);
    bodyFormData.append('UserId', detail.UserId); 
    bodyFormData.append('DocumentFile', detail.DocumentFile); 
    bodyFormData.append('BusinessId', detail.BusinessId); 
    bodyFormData.append('LocationId', detail.LocationId); 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'documents/UploadTaskDocument',
            data    : bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call Fetch business document api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function getAllBusinessDocument(businessId) { 
    let businessDocId = businessId;
    return axios({
        method  : 'get',
        url     : configConstants.API_BASE_PATH+'business/GetBusinessDocuments/' + businessDocId,
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        return response;
    });
}

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call Fetch business document api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
 function getBusinessDocumentWithLevel5(bussinessId) { 
    //  console.log(bussinessId);
    let businessDocId = 0;
    if(bussinessId){
        businessDocId = bussinessId;
    }
    // console.log(businessDocId)
    return axios({
        method  : 'get',
        url     : configConstants.API_BASE_PATH+'business/getDocumentsWithLevel5/' + businessDocId, // getdocuments
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        return response;
    });
}