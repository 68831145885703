/**
 * logoService
 *
 * @package                onboard
 * @subpackage             logoService
 * @category               Service
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for calling all api related to business
 */
import axios from 'axios';
import { configConstants } from '../_constants';

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
export const logoService = {
    logoSave,
};


/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or update logo
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function logoSave(detail) { 
    let bodyFormData = new FormData();
    bodyFormData.set('BusinessId', detail.BusinessId);
    bodyFormData.append('LogoFile', detail.LogoFile); 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'Documents/UploadLogo',
            data    : bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}