import axios from "axios";
import { configConstants } from '../_constants';

/**
 * changePasswordService
 *
 * @package                onboard
 * @subpackage             changePasswordService
 * @category               Service
 * @DateOfCreation         15 june 2019
 * @ShortDescription       This is responsible for calling update password API
 */
export const changePasswordService = {
    passwordUpdate
};

/**
* @DateOfCreation        15 june 2019
* @ShortDescription      This function is responsible to call update password api
* @param                 JSON jsonObj
* @return                Response JSON jsonObj
*/
function passwordUpdate(detail) {
    // Encode Password, and ConfirmPassword to Base64
    const credentials = btoa(`${detail.UserId}:${detail.Password}:${detail.ConfirmPassword}`);
    const newData = { userData: credentials }
    return axios({
        method: 'post',
        url: configConstants.API_BASE_PATH + 'user/resetpassword',
        data: newData,
        headers: { 'Content-Type': 'application/json' }
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        return response;
    });
}

