/**
 * store
 *
 * @package                onboard
 * @subpackage             store
 * @category               Helper
 * @DateOfCreation         11 july 2019
 * @ShortDescription       This is responsible for creating and exporting store
 */

import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
//import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';

//const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunk
    )
);