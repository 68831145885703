import { tpaCompanyConstants } from '../_constants';

/**
 * tpaCompanyReducer
 *
 * @package                onboard
 * @subpackage             tpaCompanyReducer
 * @category               Reducers
 * @DateOfCreation         28 sept 2019
 * @ShortDescription       This is responsible for all state related to Admin TPA company
 */

//Initial State on load state and intial action with their type
const initialState = {
    tpaCompanyList: [],
    tpaCompanyBusiness:[],
    loader: true,
    isTPACompanySaved: false,
    isTPACompanyEdit: false,
    isTPACompanyUpdate: false,
    submitted: false,
    editTPACompanyDetail: {},
    success: null,
    message: '',
};
export function tpaCompany(state = initialState, action) {
    switch (action.type) {
        case tpaCompanyConstants.TPACOMPANY_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case tpaCompanyConstants.TPACOMPANY_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                tpaCompanyList: action.tpaCompanyList,
                pages: action.tpaCompanyList.totalPages,
                tpaCompanyBusiness: action.tpaCompanyList,
                success: true,
            };
        case tpaCompanyConstants.TPACOMPANY_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                success: false,
                message: action.errorMsg
            };
        case tpaCompanyConstants.TPACOMPANY_ADD_REQUEST:
            return {
                ...state,
                submitted: true,
            };
        case tpaCompanyConstants.TPACOMPANY_ADD_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                message: action.responseDetail.message,
                isTPACompanySaved: true,
                submitted: false,
                success: true,
            };
        case tpaCompanyConstants.TPACOMPANY_ADD_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isTPACompanySaved: false,
                submitted: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case tpaCompanyConstants.TPACOMPANY_EDIT_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case tpaCompanyConstants.TPACOMPANY_EDIT_SUCCESS:
            return {
                ...state,
                isTPACompanyEdit: true,
                sendingRequest: true,
                loader: false,
                editTPACompanyDetail: action.editTPACompanyDetail,
                //pages          : action.tpaCompanyList.totalPages
            };
        case tpaCompanyConstants.TPACOMPANY_EDIT_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                success: false,
                message: action.errorMsg
            };
        case tpaCompanyConstants.TPACOMPANY_UPDATE_REQUEST:
            return {
                ...state,
                submitted: true,
            };
        case tpaCompanyConstants.TPACOMPANY_UPDATE_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                submitted: false,
                isTPACompanyUpdate: true,
                success: true,
                message: action.successMsg.message
            };
        case tpaCompanyConstants.TPACOMPANY_UPDATE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isTPACompanyUpdate: false,
                submitted: false,
                success: false,
                message: action.errorMsg
            };
        case tpaCompanyConstants.TPACOMPANY_RESET_STATE:
            return {
                ...state,
                sendingRequest: false,
                errorMsg: false,
                isTPACompanySaved: false,
                isTPACompanyUpdate: false,
                isTPACompanyEdit: false,
                submitted: false,
                editTPACompanyDetail: {},
                success: null,
                message: '',
            };
        default:
            return state
    }
}