import { combineReducers } from 'redux';
import { sessionReducer } from '../_packages/redux-react-session';
import { userLogin } from './userLoginReducer';
import { forgotPass } from './forgotPassReducer';
import { resetPass } from './resetPassReducer';
import { headerReducer } from './headerReducer';
import { changePassword } from './changePasswordReducer';
import { business } from './businessReducer'
import { location } from './locationReducer'
import { document } from './documentReducer'
import { task } from './taskReducer'
import { logo } from './logoReducer'
import { user } from './userReducer'
import { tpaUser } from './tpaUserReducer'
import { register } from './registerReducer'
import { tpaCompany } from './tpaCompanyReducer'
import { employee } from './employeeReducer'
import { signature } from './signatureReducer'
import { acknowledge } from './acknowledgeReducer'
import { WfourForm }from './w4formReducer'
import { AfourForm }from './a4formReducer'
import { I9Form }from './I9formReducer'
import { WotcForm }from './wotcFormReducer'
import { DDForm }from './ddFormReducer'
import { shared }from './sharedReducer'
const rootReducer = combineReducers({
    session: sessionReducer,
    userLogin,
    forgotPass,
    resetPass,
    headerReducer,
    changePassword,
    business,
    location,
    document,
    task,
    logo,
    user,
    tpaUser,
    tpaCompany,
    register,
    employee,
    WfourForm,
    AfourForm,
    signature,
    acknowledge,
    I9Form,
    WotcForm,
    DDForm,
    shared
});

export default rootReducer;
