export const taskConstants = {
    MGMT_TASK_REQUEST         : 'MGMT_TASK_REQUEST',
    MGMT_TASK_SUCCESS         : 'MGMT_TASK_SUCCESS',
    MGMT_TASK_FAILURE         : 'MGMT_TASK_FAILURE',
    MGMT_TASK_RESET_STATE     : 'MGMT_TASK_RESET_STATE',

    MGMT_DOC_SIGN_REQUEST     : 'MGMT_DOC_SIGN_REQUEST',
    MGMT_DOC_SIGN_SUCCESS     : 'MGMT_DOC_SIGN_SUCCESS',
    MGMT_DOC_SIGN_FAILURE     : 'MGMT_DOC_SIGN_FAILURE',
    MGMT_DOC_SIGN_RESET_STATE : 'MGMT_DOC_SIGN_RESET_STATE',

    LEVEL_DOC_SIGN_REQUEST : 'LEVEL_DOC_SIGN_REQUEST',
    LEVEL_DOC_SIGN_SUCCESS : 'LEVEL_DOC_SIGN_SUCCESS',
    LEVEL_DOC_SIGN_FAILURE : 'LEVEL_DOC_SIGN_FAILURE',
    LEVEL_DOC_SIGN_RESET_STATE : 'LEVEL_DOC_SIGN_RESET_STATE',

    GENERATE_DOC_REQUEST      : 'GENERATE_DOC_REQUEST',
    GENERATE_DOC_SUCCESS      : 'GENERATE_DOC_SUCCESS',
    GENERATE_DOC_FAILURE      : 'GENERATE_DOC_FAILURE',
    GENERATE_DOC_RESET_STATE  : 'GENERATE_DOC_RESET_STATE',

    MGMT_EMP_TASK_REQUEST     : 'MGMT_EMP_TASK_REQUEST',
    MGMT_EMP_TASK_SUCCESS     : 'MGMT_EMP_TASK_SUCCESS',
    MGMT_EMP_TASK_FAILURE     : 'MGMT_EMP_TASK_FAILURE',
    MGMT_EMP_TASK_RESET_STATE : 'MGMT_EMP_TASK_RESET_STATE',
};