import { sharedConstants } from '../_constants';

/**
 * sharedReducer
 *
 * @package                onboard
 * @subpackage             sharedReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to master list
 */

//Initial State on load state and intial action with their type
const initialState = {
    sendingRequest: false,
    loader        : true,
    statesList    : [],
    submitted     : false,
    errorMsg      : false,
};
export function shared(state = initialState, action) {
    switch (action.type) {
        case sharedConstants.MASTER_STATES_REQUEST :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : true,
            };
        case sharedConstants.MASTER_STATES_SUCCESS :
            return  { 
                ...state,
                sendingRequest : true,
                loader         : false,
                statesList     : action.statesList,
                submitted      : true
            };
        case sharedConstants.MASTER_STATES_FAILURE :
            return  { 
                ...state,
                sendingRequest : false,
                loader         : false,
                errorMsg       : action.errorMsg,
                submitted      : false
            };
        default:
            return state
    }
}