import React, { Component } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { HeaderContainer } from "./Header";
import { Footer } from "./Footer/Footer";
// import { configConstants } from "../../_constants";
import { utilityHelper } from "./../../_helpers/utilityHelper";

class Layout extends Component {
  state = {
    header: null,
    footer: null,
  };

  constructor(props) {
    super(props);
    const loggedInfo = utilityHelper.getUserInfo();

    if (loggedInfo) {
      this.state.header = <HeaderContainer role={loggedInfo.role} />;
      this.state.footer = <Footer />;
    }
  }

  render() {
    const { header, footer } = this.state;
    const loggedInfo = utilityHelper.getUserInfo();

    if (!loggedInfo) {
      return (
        <Navigate to="/" />
      );
    }
    return (
      <div>
        {header}
        <div className="main-content">
          <Outlet />
          {this.props.children}
        </div>
        {footer}
      </div>
    );
  }
}

export { Layout };
