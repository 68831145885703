import React from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";
import { configConstants } from "../_constants";
import { utilityHelper } from "../_helpers";

const EmployeeRoute = ({ authenticated }) => {
    const userInfo = utilityHelper.getUserInfo();

    if (!userInfo) {
        return <Navigate to="/" replace={true} />;
    }

    const isEmployee =
        userInfo.role === configConstants.USER_ROLE_EMPLOYEE ||
        userInfo.role === configConstants.USER_ROLE_ADMIN ||
        userInfo.role === configConstants.USER_ROLE_TPA;

    return authenticated && isEmployee ? <Outlet /> : <Navigate to="/" replace={true} />;
};

EmployeeRoute.propTypes = {
    authenticated: PropTypes.bool.isRequired,
};

export { EmployeeRoute };
