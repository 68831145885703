/**
 * businessActions
 *
 * @package                onboard
 * @subpackage             businessActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all business actions
 */ 

import { businessConstants,configConstants, documentConstants } from '../_constants';
import { businessService } from '../_services';

export const businessActions = {
    businessEdit,
    businessList,
    businessSave,
    businessUpdate,
    businessDelete,
    documentDelete,
    resetDocumentState,
    getBusinessDocument,
    getUsers,
    getBusinessRoles,
    resetState,
    resetBusinessResponse
};

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible for business detail
 * @return                JSON Object
 */
function businessEdit(businessId) {
    return dispatch => {
        dispatch(request({ businessId }));
        businessService.businessEdit(businessId)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(businessId) { return { type : businessConstants.BUSINESS_EDIT_REQUEST, businessId } }
    function success(business) { return { type : businessConstants.BUSINESS_EDIT_SUCCESS, business } }
    function failure(error) { return { type : businessConstants.BUSINESS_EDIT_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible for business list
 * @return                JSON Object
 */
function businessList(page, pageSize, sorted, filtered) {
    return dispatch => {
        dispatch(request());
        businessService.businessList(page, pageSize, sorted, filtered)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
                ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request() { return { type : businessConstants.BUSINESS_REQUEST  } }
    function success(businessList) { return { type : businessConstants.BUSINESS_SUCCESS, businessList } }
    function failure(error) { return { type : businessConstants.BUSINESS_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              25 june 2019
 * @ShortDescription            This function is responsible for save business detail
 * @param {object} detail       businessList detail
 * @param {array} businessList  list of all active business
 * @return                      JSON Object in succsss message, recent saved record
 */
function businessSave(detail) {
    return dispatch => {
        dispatch(request({ detail }));
        businessService.businessSave(detail)
            .then(
                response => { 
                    let data = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        // const index = businessList.findIndex(
                        //             i => 
                        //                 i.BusinessId == detail.BusinessId
                        //             );
                        // if(businessList[index]) {
                        //     businessList[index] = detail;
                        // }else{
                        //     detail['BusinessId'] = data.result.BusinessId;
                        //     businessList.push(detail);
                        // }
                        let successMsg = { 'message' : data.message, 'detail' : data.result, 'businessList' : businessList };
                        dispatch(success(successMsg));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(detail) { return { type: businessConstants.BUSINESS_ADD_REQUEST, detail } }
    function success(successMsg) { return { type : businessConstants.BUSINESS_ADD_SUCCESS, successMsg } }
    function failure(error) { return { type : businessConstants.BUSINESS_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              25 june 2019
 * @ShortDescription            This function is responsible for update business detail
 * @param {object} detail       businessList detail
 * @param {array} businessList  list of all active business
 * @return                      JSON Object in succsss message, recent saved record
 */
function businessUpdate(detail) {
    return dispatch => {
        dispatch(request({ detail }));
        businessService.businessUpdate(detail)
            .then(
                response => { 
                    let data = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let result = { 'message' : data.message, 'detail' : data.data };
                        dispatch(success(result));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };
    function request(detail) { return { type: businessConstants.BUSINESS_UPDATE_REQUEST, detail } }
    function success(result) { return { type : businessConstants.BUSINESS_UPDATE_SUCCESS, result } }
    function failure(error) { return { type : businessConstants.BUSINESS_UPDATE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              27 june 2019
 * @ShortDescription            This function is responsible for update business detail
 * @param {object}              businessId Business id
 * @param {array} businessList    list of all active businessList
 * @return                      JSON Object in succsss message
 */
function businessDelete(businessId, businessList) {
    return dispatch => {
        dispatch(request({ businessId }));
        businessService.businessDelete(businessId)
            .then(
                response => { 
                    var data = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {

                        businessList = businessList.filter(function(item) {
                            return item.BusinessId !== businessId
                        });
                    
                        var successMsg = { 'message' : data.message, 'businessId' : businessId, 'businessList' : businessList };
                        dispatch(success(successMsg));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(businessId) { return { type: businessConstants.BUSINESS_REMOVE_REQUEST, businessId } }
    function success(successMsg) { return { type : businessConstants.BUSINESS_REMOVE_SUCCESS, successMsg } }
    function failure(error) { return { type : businessConstants.BUSINESS_REMOVE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              27 june 2019
 * @ShortDescription            This function is responsible for update document detail
 * @param {object}              documentId document id
 * @param {array} documentList    list of all active documentList
 * @return                      JSON Object in succsss message
 */
function documentDelete(documentId, isPermanentDelete, IsAcknowladge) {
    return dispatch => {
        dispatch(request({ documentId }));
        businessService.documentDelete(documentId,isPermanentDelete, IsAcknowladge)
            .then(
                response => { 
                    var data = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        var successMsg = { 'message' : data.message};
                        dispatch(success(successMsg));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(documentId) { return { type: documentConstants.DOCUMENT_REMOVE_REQUEST, documentId } }
    function success(successMsg) { return { type : documentConstants.DOCUMENT_REMOVE_SUCCESS, successMsg } }
    function failure(error) { return { type : documentConstants.DOCUMENT_REMOVE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}
/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible for document list
 * @return                JSON Object
 */
function getBusinessDocument(businessId='') {
    return dispatch => {
        businessService.getBusinessDocument(businessId)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(documentList) { return { type : businessConstants.BUSINESS_DOCUMENT_REQUEST, documentList } }
    function success(documentList) { return { type : businessConstants.BUSINESS_DOCUMENT_SUCCESS, documentList } }
    function failure(error) { return { type : businessConstants.BUSINESS_DOCUMENT_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}



/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible for document list
 * @return                JSON Object
 */
function getUsers() {
    return dispatch => {
        businessService.getUsers()
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(userList) { return { type : businessConstants.BUSINESS_USER_REQUEST, userList } }
    function success(userList) { return { type : businessConstants.BUSINESS_USER_SUCCESS, userList } }
    function failure(error) { return { type : businessConstants.BUSINESS_USER_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        30 oct 2019
 * @ShortDescription      This function is responsible for recuriter role for online application form
 * @return                JSON Object
 */
function getBusinessRoles() {
    return dispatch => {
        businessService.getBusinessRoles()
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(businessRoleList) { return { type : businessConstants.BUSINESS_ROLE_REQUEST, businessRoleList } }
    function  success(businessRoleList) { return { type : businessConstants.BUSINESS_ROLE_SUCCESS, businessRoleList } }
    function failure(error) { return { type : businessConstants.BUSINESS_ROLE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}


/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : businessConstants.BUSINESS_RESET_STATE } }
}

function resetBusinessResponse(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : businessConstants.BUSINESS_RESET_RESPONSE } }
}

/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetDocumentState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : documentConstants.DOCUMENT_RESET_STATE } }
}

