/**
 * signatureConstants
 *
 * @package                onboard
 * @subpackage             signatureConstants
 * @category               Constants
 * @DateOfCreation         15 JULY 2019
 * @ShortDescription       This is responsible for E-Signature action names
 */
export const signatureConstants = {
    SIGNATURE_REQUEST  : 'SIGNATURE_REQUEST',
    SIGNATURE_SUCCESS  : 'SIGNATURE_SUCCESS',
    SIGNATURE_FAILURE  : 'SIGNATURE_FAILURE',
    SIGNATURE_RESET    : 'SIGNATURE_RESET'
};