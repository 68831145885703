/**
 * App
 *
 * @package                onboard
 * @subpackage             App
 * @category               Component
 * @DateOfCreation         11 May 2019
 * @ShortDescription       This is the parent most component for full app
 */
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Loadable from "react-loadable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { history } from '../_helpers/history';
import { utilityHelper } from "../_helpers/utilityHelper";
import { userLoginService } from '../_services';
import { sessionService } from "../_packages/redux-react-session";
import "../assets/css/bootstrap.css";
import "../assets/css/mine.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Layout } from './../components/Layout';
import { AdminRoute, EmployeeRoute, MGMTRoute, ManagerRoute, TPARoute } from '../_routes';
import packageJson from '../../package.json';
import CacheBuster from "./CacheBuster";
import SweetAlert from "react-bootstrap-sweetalert";

global.appVersion = packageJson.version;

const Loading = () => (
  <div className="showbox">
    <div className="loader">
      <svg className="circular" viewBox="25 25 50 50">
        <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
      </svg>
    </div>
  </div>
);

const LoginContainer = Loadable({
  loader: () => import('../components/Auth/Login').then(object => object.LoginContainer),
  loading: Loading
});

const RegistrationContainer = Loadable({
  loader: () => import('../components/Auth/Registration').then(object => object.RegistrationContainer),
  loading: Loading
});

const ForgotPasswordContainer = Loadable({
  loader: () => import('../components/Auth/ForgotPassword').then(object => object.ForgotPasswordContainer),
  loading: Loading
});

const ResetPasswordContainer = Loadable({
  loader: () => import('../components/Auth/ResetPassword').then(object => object.ResetPasswordContainer),
  loading: Loading
});

const Dashboard = Loadable({
  loader: () => import('../components/Admin/Dashboard').then(object => object.Dashboard),
  loading: Loading
});

const BusinessListContainer = Loadable({
  loader: () => import('../components/Admin/Business').then(object => object.BusinessListContainer),
  loading: Loading
});

const BusinessContainer = Loadable({
  loader: () => import('../components/Admin/Business').then(object => object.BusinessContainer),
  loading: Loading
});

const LocationListContainer = Loadable({
  loader: () => import('../components/Admin/Location').then(object => object.LocationListContainer),
  loading: Loading
});

const LocationContainer = Loadable({
  loader: () => import('../components/Admin/Location').then(object => object.LocationContainer),
  loading: Loading
});

const DocumentContainer = Loadable({
  loader: () => import('../components/Admin/Document').then(object => object.DocumentContainer),
  loading: Loading
});

const LogoContainer = Loadable({
  loader: () => import('../components/Admin/Logo').then(object => object.LogoContainer),
  loading: Loading
});


const UserListContainer = Loadable({
  loader: () => import('../components/Admin/User').then(object => object.UserListContainer),
  loading: Loading
});

const UserContainer = Loadable({
  loader: () => import('../components/Admin/User').then(object => object.UserContainer),
  loading: Loading
});

const AssignToBussinessContainer = Loadable({
  loader: () => import('../components/Admin/User').then(object => object.AssignToBusinessContainer),
  loading: Loading
});

const TPACompanyListContainer = Loadable({
  loader: () => import('../components/Admin/TPACompany').then(object => object.TPACompanyListContainer),
  loading: Loading
});

const TPACompanyContainer = Loadable({
  loader: () => import('../components/Admin/TPACompany').then(object => object.TPACompanyContainer),
  loading: Loading
});

const TPAUserListContainer = Loadable({
  loader: () => import('../components/Shared/TPAUser').then(object => object.TPAUserListContainer),
  loading: Loading
});

const TPAUserContainer = Loadable({
  loader: () => import('../components/Shared/TPAUser').then(object => object.TPAUserContainer),
  loading: Loading
});

const MGMTMainContainer = Loadable({
  loader: () => import('../components/MGMT/Main').then(object => object.MGMTMainContainer),
  loading: Loading
});

const EmpDashboardContainer = Loadable({
  loader: () => import('../components/Employee/Dashboard').then(object => object.EmpDashboardContainer),
  loading: Loading
});

const ChangePassword = Loadable({
  loader: () => import('../components/Shared/ChangePassword').then(object => object.ChangePassword),
  loading: Loading
});

const Signature = Loadable({
  loader: () => import('../components/Shared/Signature').then(object => object.Signature),
  loading: Loading
}); 


const UpdateProfileContainer = Loadable({
  loader: () => import('../components/Shared/Profile/UpdateProfileContainer').then(object => object.UpdateProfileContainer),
  loading: Loading
});

const TPAProfileContainer = Loadable({
  loader: () => import('../components/Shared/Profile/TPAProfileContainer').then(object => object.TPAProfileContainer),
  loading: Loading
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = { logginStatus: true, showConfirmAlert: false };
    this.events = ["mousemove", "mousedown", "click", "scroll"];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (let i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    if (utilityHelper.getUserInfo()) {
      this.warnTimeout = setTimeout(() => { this.warn() }, 300 * 1000); // 5 minute inactivity 
      this.logoutTimeout = setTimeout(() => { this.logout() }, 720 * 1000); // 12 minute idle time out
    }
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  warn() {
    this.setState({ showConfirmAlert: !this.state.showConfirmAlert });
  }

  refreshToken() {
    if (utilityHelper.getUserInfo()) {
      const refreshToken = {
        refreshToken: utilityHelper.getUserInfo().refreshToken,
        Token: utilityHelper.getLoginAccessToken(),
        UserName: utilityHelper.getUserInfo().username,
      };
      userLoginService.refreshToken(refreshToken)
        .then(response => {
          let responseData = response.data;
          if (responseData !== null && responseData.data !== null && responseData.data.token !== null) {
            sessionService.saveSession(responseData.data.token);
            sessionService.saveUser(responseData.data.userInfo);
            localStorage.setItem('currentLoginTime', JSON.stringify((new Date).getTime()));
          } else {
            this.logout();
          }
          this.setState({ showConfirmAlert: false });
        }, error => {
          this.logout();
          this.setState({ showConfirmAlert: false });
        });
      setTimeout(() => { this.resetTimeout(); }, 2000);
    }
  }

  logout() {
    utilityHelper.doLogout();
    this.setState({ logginStatus: false });
    this.destroy(); // Cleanup
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    history.push('/login');
  }

  destroy() {
    this.clearTimeout();
    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout());
    }
  }

  continueSession = () => {
    window.document.location.reload(1);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showConfirmAlert && (
          <SweetAlert
            customClass="containerBox"
            warning
            title="System Idle"
          >
            You have been idle for a few minutes. Are you still working? Please click Yes to continue or No to logout.
            <div className="react-confirm-alert-button-group">
              <a href="#" className="btn btn-lg btn-primary confirm-alert-button" onClick={(e) => { e.preventDefault(); this.continueSession(); }}>Yes</a>
              <a href="#" className="btn btn-lg btn-primary confirm-alert-button" onClick={(e) => { e.preventDefault(); this.logout(); }}>No</a>
            </div>
          </SweetAlert>
        )}
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }
            return (
              <BrowserRouter basename="/">
                {this.props.checked && (
                  <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                    <Route path="/online-application-form/:slug" element={<RegistrationContainer />} />
                    <Route path="/login" element={<LoginContainer />} />
                    <Route path="/forgotpassword" element={<ForgotPasswordContainer />} />
                    <Route path="/resetPassword" element={<ResetPasswordContainer />} />
                    <Route path="/" element={<Layout />}>
                      
                      {/* AdminRoute */}
                      <Route path="/profile" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<UpdateProfileContainer />} />
                      </Route>
                      <Route path="/dashboard" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<Dashboard />} />
                      </Route>
                      <Route path="/business-list" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<BusinessListContainer />} />
                      </Route>
                      <Route path="/business/:id" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<BusinessContainer />} />
                      </Route>
                      <Route path="/business-add" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<BusinessContainer />} />
                      </Route>
                      <Route path="/location-list" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<LocationListContainer />} />
                      </Route>
                      <Route path="/location/:id" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<LocationContainer />} />
                      </Route>
                      <Route path="/location-add/:businessId?" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<LocationContainer />} />
                      </Route>
                      <Route path="/document" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<DocumentContainer />} />
                      </Route>
                      <Route path="/logo" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<LogoContainer />} />
                      </Route>
                      <Route path="/user-list" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<UserListContainer />} />
                      </Route>
                      <Route path="/user-add" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<UserContainer />} />
                      </Route>
                      <Route path="/user/:id" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<UserContainer />} />
                      </Route>
                      <Route path="/user/assign-to-business/:id" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<AssignToBussinessContainer />} />
                      </Route>
                      <Route path="/tpa-company-list" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<TPACompanyListContainer />} />
                      </Route>
                      <Route path="/tpa-company" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<TPACompanyContainer />} />
                      </Route>
                      <Route path="/tpa-company/:id" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<TPACompanyContainer />} />
                      </Route>
                      <Route path="/tpa-user-list/:cmpId" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<TPAUserListContainer />} />
                      </Route>
                      <Route path="/tpa-user/:cmpId" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<TPAUserContainer />} />
                      </Route>
                      <Route path="/tpa-user/:cmpId/:id" element={<AdminRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<TPAUserContainer />} />
                      </Route>

                      {/* TPARoute Route */}
                      <Route path="/TPA/profile" element={<TPARoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<TPAProfileContainer />} />
                      </Route>
                      <Route path="/TPA/dashboard/:id" element={<TPARoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<MGMTMainContainer />} />
                      </Route>

                      {/* MGMTRoute Route */}
                      <Route path="/Owner/dashboard" element={<MGMTRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<MGMTMainContainer />} />
                      </Route>
                      <Route path="/Owner/document" element={<MGMTRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<DocumentContainer />} />
                      </Route>
                      <Route path="/MGMT/dashboard" element={<MGMTRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<MGMTMainContainer />} />
                      </Route>
                      <Route path="/MGMT/document" element={<MGMTRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<DocumentContainer />} />
                      </Route>
                      <Route path="/updateProfile" element={<MGMTRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<UpdateProfileContainer />} />
                      </Route>
                      <Route path="/change-password" element={<MGMTRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<ChangePassword />} />
                      </Route>
                      <Route path="/ESConsent" element={<MGMTRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<Signature />} />
                      </Route>

                      {/* ManagerRoute Route */}
                      <Route path="/manager/updateProfile" element={<ManagerRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<UpdateProfileContainer />} />
                      </Route>
                      <Route path="/manager/dashboard" element={<ManagerRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<MGMTMainContainer />} />
                      </Route>
                      <Route path="/manager/change-password" element={<ManagerRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<ChangePassword />} />
                      </Route>
                      <Route path="/manager/ESConsent" element={<ManagerRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<Signature />} />
                      </Route>

                      {/* EmployeeRoute Route */}
                      <Route path="/employee/dashboard" element={<EmployeeRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<EmpDashboardContainer />} />
                      </Route>
                      <Route path="/employee/updateProfile" element={<EmployeeRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<UpdateProfileContainer />} />
                      </Route>
                      <Route path="/employee/change-password" element={<EmployeeRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<ChangePassword />} />
                      </Route>
                      <Route path="/employee/ESConsent" element={<EmployeeRoute authenticated={this.props.authenticated} />}>
                        <Route path="" element={<Signature />} />
                      </Route>
                    </Route>
                  </Routes>
                )}
              </BrowserRouter>
            );
          }}
        </CacheBuster>
      </React.Fragment>
    );
  }
}

const { bool } = PropTypes;

App.propTypes = {
  authenticated: bool.isRequired,
  checked: bool.isRequired
};

const mapState = ({ session }) => ({
  checked: session.checked,
  authenticated: session.authenticated
});

const connectedApp = connect(mapState)(App);
export { connectedApp as App };
