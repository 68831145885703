/**
 * A4FormService
 *
 * @package                onboard
 * @subpackage             A4FormService
 * @category               Service
 * @DateOfCreation         8 July 2019
 * @ShortDescription       This is responsible for calling all api related to business
 */
import axios from 'axios';
import { configConstants } from '../_constants';


export const a4FormService = {
    formDetails,
    A4FormAdd,
    A4FormUpdate,
};


/**
 * @DateOfCreation        8 July 2019
 * @ShortDescription      This function is responsible to call Fetch business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function formDetails(model) { 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'a4form/getautofill',
            data    : model,
            responseType: "json"
        }).then(function (response) {
           // console.log(response);
            return response;
        }).catch(response=> {
            //console.log(response.response.data);
            if(response.response.data)
                return response.response.data.message;
            else
               return response;
        });
}



function A4FormAdd(model)
{
    let A4Path = 'a4form/create';
    if(model.Id > 0){
        A4Path = 'a4form/update';
    }
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+A4Path,
        data    : model,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}


/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or update wotc Document
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function A4FormUpdate(detail) { 
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'a4form/update',
        data    : detail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}