import { signatureConstants,configConstants } from '../_constants';
/**
 * signature
 *
 * @package                onboard
 * @subpackage             signature
 * @category               Reducers
 * @DateOfCreation         15 july 2019
 * @ShortDescription       This is responsible for all state related to User Signature
 */
//Initial State on load state and intial action with their type
const initialState = {
  successMsg: false,
  errorMsg:false,
  isSignatureValid:false,
};
export function signature(state = initialState, action) {
  switch (action.type) {
    case signatureConstants.SIGNATURE_SUCCESS:
      return {
        successMsg : action.success
      };
    case signatureConstants.SIGNATURE_FAILURE:
      return {
        errorMsg : action.error
      };
    case configConstants.UNAUTHENTICATE:
      return {
            ...state,
            isSignatureValid : true,
            errorMsg       : false
          };
    case signatureConstants.SIGNATURE_RESET:
      return {
                ...state,
                successMsg : false,
                errorMsg   : false,
             };
    default:
      return state
  }
}