import { acknowledgeConstants,configConstants } from '../_constants';
/**
 * acknowledge
 *
 * @package                onboard
 * @subpackage             acknowledge
 * @category               Reducers
 * @DateOfCreation         15 july 2019
 * @ShortDescription       This is responsible for all state related to User acknowledge
 */
//Initial State on load state and intial action with their type
const initialState = {
  successMsg: false,
  errorMsg:false,
  isAcknowledgeValid:false,
};
export function acknowledge(state = initialState, action) {
  switch (action.type) {
    case acknowledgeConstants.ACKNOWLEDGE_SUCCESS:
      return {
        successMsg : action.success
      };
    case acknowledgeConstants.ACKNOWLEDGE_FAILURE:
      return {
        errorMsg : action.error
      };
    case configConstants.UNAUTHENTICATE:
      return {
            ...state,
            isAcknowledgeValid : true,
            errorMsg       : false
          };
    case acknowledgeConstants.ACKNOWLEDGE_RESET:
      return {
                ...state,
                successMsg : false,
                errorMsg   : false,
             };
    default:
      return state
  }
}