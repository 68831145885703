import { ddFormConstants } from '../_constants';

/**
 * ddFormReducer
 *
 * @package                onboard
 * @subpackage             ddFormReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to direct Deposit
 */

//Initial State on load state and intial action with their type
const initialState = {
    autofilldetail: {},
    loader: true,
    successMessage: '',
    isDDFormSaved: false,
    isDDFormUpdate: false,
    submitted: false,
    success: null,
    message: ''
};
export function DDForm(state = initialState, action) {
    switch (action.type) {
        case ddFormConstants.AUTOFILL_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case ddFormConstants.AUTOFILL_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                autofilldetail: action.autofilldetail,
                //success: true,
            };
        case ddFormConstants.AUTOFILL_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case ddFormConstants.DD_ADD_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
                submitted: true,
            };    
        case ddFormConstants.DD_ADD_SUCCESS:
            return {
                ...state,
            sendingRequest: true,
            successMessage: action.successMsg.message,
            isDDFormSaved: true,
            submitted: false,
            success: true,
            message: action.successMsg.message
            };
        case ddFormConstants.DD_ADD_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isDDFormSaved: false,
                submitted: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case ddFormConstants.DD_UPDATE_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
                submitted: true,
            };  
        case ddFormConstants.DD_UPDATE_SUCCESS:
            return {
                ...state,
            sendingRequest: true,
            successMessage: action.successMsg.message,
            isDDFormUpdate: true,
            submitted: false,
            success: true,
            message: action.successMsg.message
            };
        case ddFormConstants.DD_UPDATE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isDDFormUpdate: false,
                submitted: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        

        case ddFormConstants.DD_RESET_STATE:
            return {
                initialState
            };
    
        default:
            return state
    }
}