/**
 * businessService
 *
 * @package                onboard
 * @subpackage             businessService
 * @category               Service
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for calling all api related to business
 */
import axios from 'axios';
import { configConstants } from '../_constants';
import { utilityHelper, history } from '../_helpers';

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
export const businessService = {
    businessEdit,
    businessList,
    businessSave,
    businessUpdate,
    businessDelete,
    documentDelete,
    getBusinessDocument,
    getUsers,
    getBusinessRoles
};

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call Fetch business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function businessEdit(businessId) { 
    return axios({
            method  : 'get',
            url     : configConstants.API_BASE_PATH+'Business/get/'+businessId,
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call Fetch business api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function businessList(page, pageSize, sorted, filtered) { 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'Business/get',
            data    : {"PageNumber":page, "PageSize":pageSize, "sort":sorted, "filtered":filtered},
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call Fetch business document api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function getBusinessDocument(bussinessId) { 
    let businessDocId = 0;
    if(bussinessId){
        businessDocId = bussinessId;
    }
    return axios({
        method  : 'get',
        url     : configConstants.API_BASE_PATH+'business/getdocuments/' + businessDocId, // getdocuments
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        return response;
    });
}

/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or update business
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function businessSave(detail) { 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'business/create',
            data    : detail,
            headers : { 'Content-Type' : 'application/json'}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to update business
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function businessUpdate(detail) { 
    return axios({
            method  : 'put',
            url     : configConstants.API_BASE_PATH+'Business/Update',
            data    : detail,
            headers : { 'Content-Type' : 'application/json'}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}


/**
 * @DateOfCreation        27 june 2019
 * @ShortDescription      This function is responsible to delete api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function businessDelete(businessId) { 
    return axios({
            method  : 'get',
            url     :  configConstants.API_BASE_PATH+'Business/Delete/'+businessId,
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        27 june 2019
 * @ShortDescription      This function is responsible to delete api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function documentDelete(detail, isPermanentDelete, IsAcknowladge)
{
    let documentDetail = {DocId:detail, IsPermanentDelete: isPermanentDelete, IsAcknowladge: IsAcknowladge}
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+'Documents/DeleteBusinessDocuments',
        data    : documentDetail,
        headers : { 'Content-Type' : 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible to call Fetch users api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function getUsers() { 
    return axios({
            method  : 'get',
            url     : configConstants.API_BASE_PATH+'user/GetTPAList',
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        30 oct 2019
 * @ShortDescription      This function is responsible to call Fetch recruiter role
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function getBusinessRoles() { 
     return axios({
            method  : 'Get',
            url     : configConstants.API_BASE_PATH+'business/GetRoles',
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}
