/**
 * registerService
 *
 * @package                onboard
 * @subpackage             registerService
 * @category               Service
 * @DateOfCreation         28 sept 2019
 * @ShortDescription       This is responsible for calling all api related to online registration
 */
import axios from 'axios';
import { configConstants } from '../_constants';


export const registerService = {
    userSave,
    getJobTitle,
    getBusinessLocation
};

/**
 * @DateOfCreation        28 sept 2019
 * @ShortDescription      This function is responsible to call Fetch online registration api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */

function userSave(detail)
{
    //console.log(detail); return;
    let bodyFormData = new FormData();
    bodyFormData.append('businessId',detail.businessId);
    bodyFormData.append('locationId',detail.locationId);
    bodyFormData.append('firstName',detail.firstName);
    bodyFormData.append('lastName',detail.lastName);
    bodyFormData.append('emailId',detail.emailId);
    bodyFormData.append('dob',detail.dob);
    bodyFormData.append('jobTitleId',detail.jobTitleId);
    bodyFormData.append('phoneNumber',detail.phoneNumber);
    bodyFormData.append('availDay',detail.availDay);
    bodyFormData.append('availTime',detail.availTime);
    bodyFormData.append('streetAddress',detail.streetAddress);
    bodyFormData.append('city',detail.city);
    bodyFormData.append('state',detail.state);
    bodyFormData.append('zip',detail.zip);
    bodyFormData.append('workHistory',detail.workHistory);
    bodyFormData.append('docFile',detail.docFile);


    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'OnlineApplication/Create',
            data    : bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data',Pragma: 'no-cache' }}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        28 sept 2019
 * @ShortDescription      This function is responsible to call Fetch master job title api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function getJobTitle() { 
    return axios({
            method  : 'get',
            url     : configConstants.API_BASE_PATH+'account/GetJobTitle',
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

/**
 * @DateOfCreation        28 sept 2019
 * @ShortDescription      This function is responsible to call Fetch business detail
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */

function getBusinessLocation(slug) { 
    return axios({
            method  : 'get',
            url     : configConstants.API_BASE_PATH+'OnlineApplication/Get/'+slug,
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}