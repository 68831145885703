import { tpaUserConstants } from '../_constants';

/**
 * tpaUserReducer
 *
 * @package                onboard
 * @subpackage             tpaUserReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to Admin business
 */

//Initial State on load state and intial action with their type
const initialState = {
    tpaUserList: [],
    loader: true,
    isTPAUserSaved: false,
    isTPAUserEdit: false,
    isTPAUserUpdate: false,
    submitted: false,
    editTPAUserDetail: {},
    success: null,
    message: '',
    isTPAUserEditDone: false,
};
export function tpaUser(state = initialState, action) {
    switch (action.type) {
        case tpaUserConstants.TPAUSER_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case tpaUserConstants.TPAUSER_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                tpaUserList: action.tpaUserList.list,
                pages: action.tpaUserList.totalPages,
                success: true,
            };
        case tpaUserConstants.TPAUSER_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case tpaUserConstants.TPAUSER_ADD_REQUEST:
            return {
                ...state,
                submitted: true,
            };
        case tpaUserConstants.TPAUSER_ADD_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                isTPAUserSaved: true,
                submitted: false,
                success: true,
                message: action.responseDetail.message

            };
        case tpaUserConstants.TPAUSER_ADD_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isTPAUserSaved: false,
                submitted: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };

        case tpaUserConstants.TPAUSER_EDIT_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case tpaUserConstants.TPAUSER_EDIT_SUCCESS:
            return {
                ...state,
                isTPAUserEdit: true,
                isTPAUserEditDone:true,
                sendingRequest: true,
                loader: false,
                editTPAUserDetail: action.editTPAUserDetail,
                //pages          : action.tpaUserList.totalPages
            };
        case tpaUserConstants.TPAUSER_EDIT_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg
            };
        case tpaUserConstants.TPAUSER_UPDATE_REQUEST:
            return {
                ...state,
                submitted: true,
            };
        case tpaUserConstants.TPAUSER_UPDATE_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                submitted: false,
                isTPAUserUpdate: true,
                success: true,
                message: action.responseDetail.message
            };
        case tpaUserConstants.TPAUSER_UPDATE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isTPAUserUpdate: false,
                submitted: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case tpaUserConstants.TPAUSER_REMOVE_REQUEST:
            return {
                ...state,
                submitted: true,
            };
        case tpaUserConstants.TPAUSER_REMOVE_SUCCESS:
                return {
                    ...state,
                    sendingRequest: true,
                    loader: false,
                    success: true,
                    submitted: false,
                    message: action.successMsg.message
                };
        case tpaUserConstants.TPAUSER_REMOVE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg,
                success: false,
                submitted: false,
                message: action.errorMsg
            };
        case tpaUserConstants.TPAUSER_RESET_STATE:
            return {
                ...state,
                sendingRequest: false,
                errorMsg: false,
                isTPAUserSaved: false,
                isTPAUserEdit: false,
                isTPAUserUpdate: false,
                isRoleUpdate: false,
                submitted: false,
                editTPAUserDetail: {},
                success: null,
                message: ''
            };
            case tpaUserConstants.TPAUSER_EDIT_RESET_STATE:
                return {
                    ...state,
                    success: null,
                    message: '',
                    isTPAUserEditDone: false,
                };
        default:
            return state
    }
}