/**
 * locationActions
 *
 * @package                onboard
 * @subpackage             locationActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all location actions
 */ 

import { locationConstants,configConstants } from '../_constants';
import { locationService } from '../_services';

export const locationActions = {
    locationList,
    locationEdit,
    locationUpdate,
    locationSave,
    locationDelete,
    resetState
};

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible for business list
 * @return                JSON Object
 */
function locationList(page, pageSize, sorted, filtered, businessId) {
    return dispatch => {
        locationService.locationList(page, pageSize, sorted, filtered, businessId)
            .then(
                response => { 
                   let data = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(data.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(locationList) { return { type : locationConstants.LOCATION_REQUEST, locationList } }
    function success(locationList) { return { type : locationConstants.LOCATION_SUCCESS, locationList } }
    function failure(error) { return { type : locationConstants.LOCATION_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation        28 june 2019
 * @ShortDescription      This function is responsible for business location detail
 * @return                JSON Object
 */
function locationEdit(businessId) {
    return dispatch => {
        locationService.locationEdit(businessId)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(location) { return { type : locationConstants.LOCATION_EDIT_REQUEST, location } }
    function success(location) { return { type : locationConstants.LOCATION_EDIT_SUCCESS, location } }
    function failure(error) { return { type : locationConstants.LOCATION_EDIT_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              25 june 2019
 * @ShortDescription            This function is responsible for update business location detail
 * @param {object} detail       businessList detail
 * @param {array} businessList  list of all active business
 * @return                      JSON Object in succsss message, recent saved record
 */
function locationUpdate(detail) {
    return dispatch => {
        dispatch(request({detail}));
        locationService.locationUpdate(detail)
            .then(
                response => { 
                    let data = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let result = { 'message' : data.message, 'detail' : data.data };
                        dispatch(success(result));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(detail) { return { type: locationConstants.LOCATION_UPDATE_REQUEST, detail } }
    function success(result) { return { type : locationConstants.LOCATION_UPDATE_SUCCESS, result } }
    function failure(error) { return { type : locationConstants.LOCATION_UPDATE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              25 june 2019
 * @ShortDescription            This function is responsible for save location detail
 * @param {object} detail       locationList detail
 * @param {array} locationList  list of all active location
 * @return                      JSON Object in succsss message, recent saved record
 */
function locationSave(detail, locationList) {
    return dispatch => {
        dispatch(request({detail}));
        locationService.locationSave(detail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        // const index = locationList.findIndex(
                        //             i => 
                        //                 i.BusinessId == detail.BusinessId
                        //             );
                        // if(locationList[index]) {
                        //     locationList[index] = detail;
                        // }else{
                        //     detail['BusinessId'] = data.result.BusinessId;
                        //     locationList.push(detail);
                        // }
                        let responseDetail = { 'message' : responseData.message, 'detail' : responseData.data, 'locationList' : locationList };
                        dispatch(success(responseDetail));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(detail) { return { type: locationConstants.LOCATION_ADD_REQUEST, detail } }
    function success(responseDetail) { return { type : locationConstants.LOCATION_ADD_SUCCESS, responseDetail } }
    function failure(error) { return { type : locationConstants.LOCATION_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              27 june 2019
 * @ShortDescription            This function is responsible for update business detail
 * @param {object}              businessId Business id
 * @param {array} locationList    list of all active locationList
 * @return                      JSON Object in succsss message
 */
function locationDelete(businessId, locationList) {
    return dispatch => {
        locationService.locationDelete(businessId)
            .then(
                response => { 
                    var data = response.data;
                    if(data.code == configConstants.SUCCESS_CODE) {

                        locationList = locationList.filter(function(item) {
                            return item.BusinessId !== businessId
                        });
                    
                        var successMsg = { 'message' : data.message, 'businessId' : businessId, 'locationList' : locationList };
                        dispatch(success(successMsg));
                    }else if(data.code == configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(data.code == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(data.code == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function success(successMsg) { return { type : locationConstants.LOCATION_REMOVE_SUCCESS, successMsg } }
    function failure(error) { return { type : locationConstants.LOCATION_REMOVE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
* @DateOfCreation        28 june 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : locationConstants.LOCATION_RESET_STATE } }
}