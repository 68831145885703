import axios from "axios"; 
import { configConstants } from '../_constants';
import { utilityHelper } from '../_helpers';

/**
 * resetPasswordService
 *
 * @package                onboard
 * @subpackage             resetPasswordService
 * @category               Service
 * @DateOfCreation         15 May 2019
 * @ShortDescription       This is responsible for calling API
*/
export const resetPasswordService = {
    resetPassword
};

/**
* @DateOfCreation        15 May 2019
* @ShortDescription      This function is responsible to call Reset Api with new password
* @param                 JSON jsonObj
* @return                Response JSON jsonObj
*/
function resetPassword(user) {
    var bodyFormData = new FormData();
    bodyFormData = utilityHelper.jsonToFormData(user, bodyFormData);
    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH + 'password/reset',
        data    : bodyFormData,
        headers: {
            'Content-Type' : 'multipart/form-data',
        }
    })
    .then(response => {
        return response;
    })
    .catch(function (response) {
            return response;
    });
}

