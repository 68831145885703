/**
 * W4 form Service
 *
 * @package                onboard
 * @subpackage             W4 form Service
 * @category               Service
 * @DateOfCreation         8 July 2019
 * @ShortDescription       This is responsible for calling all api related to W4 form
 */
import axios from 'axios';
import { configConstants } from '../_constants';

export const W4FormService = {
    formDetails,
    getStateForms,
    Addw4,
    savew4Document
};


/**
 * @DateOfCreation        8 July 2019
 * @ShortDescription      This function is responsible to call Fetch W4 form api
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function formDetails(model) { 
    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'w4form/getautofill',
            data    : model,
            'Content-Type': "application/json"
        }).then(function (response) {
            return response;
        }).catch(response=> {
            if(response.response.data)
                return response.response.data.message;
            else
               return response;
        });
}


/**
 * @DateOfCreation        8 July 2019
 * @ShortDescription      This function is responsible to call Fetch get state form
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function getStateForms() { 
    return axios({
            method  : 'get',
            url     : configConstants.API_BASE_PATH+'Masters/State',
            'Content-Type': "application/json"
        }).then(function (response) {
            return response;
        }).catch(response=> {
            if(response.response.data)
                return response.response.data.message;
            else
               return response;
        });
}

/**
 * @DateOfCreation        25 june 2019
 * @ShortDescription      This function is responsible to call api to add or update w4 Document
 * @param                 JSON jsonObj
 * @return                Response JSON jsonObj
 */
function savew4Document(detail) { 
    let bodyFormData = new FormData();
    bodyFormData.set('UserId', detail.UserId);
    bodyFormData.append('UserDocumentId', detail.userDocumentId); 
    bodyFormData.append('State', detail.State !== undefined ? detail.State : null); 
    bodyFormData.append('W4FormState', detail.W4FormState); 
    bodyFormData.append('SupportiveDocName', detail.SupportiveDocName); 
    bodyFormData.append('SupportiveDocPath', detail.SupportiveDocPath); 
    bodyFormData.append('DocFile', detail.DocFile); 
    bodyFormData.append('IsReject', detail.IsReject);

    return axios({
            method  : 'post',
            url     : configConstants.API_BASE_PATH+'user/uploadDocument',
            data    : bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(function (response) {
            return response;
        }).catch(function (response) {
            return response;
        });
}

function Addw4(model)
{
    // Created new FormData object for DocFile
    const emplyeeFormData = new FormData();
    
    // Append the DocFile directly to the FormData object
    emplyeeFormData.append('DocFile', model.DocFile);
    emplyeeFormData.append('maritalStatus', model.maritalStatus);
    emplyeeFormData.append('DependentsUnderAge17', model.DependentsUnderAge17 == null ? 0 : model.DependentsUnderAge17);
    emplyeeFormData.append('DependentsOtherAge', model.DependentsOtherAge == null ? 0 : model.DependentsOtherAge);
    emplyeeFormData.append('OtherIncome', model.OtherIncome==null?0:model.OtherIncome);
    emplyeeFormData.append('Deductions', model.Deductions==null?0:model.Deductions);
    emplyeeFormData.append('ExtraWithholding', model.ExtraWithholding==null?0:model.ExtraWithholding);
    emplyeeFormData.append('BusinessId', model.BusinessId);
    emplyeeFormData.append('LocationId', model.LocationId);
    emplyeeFormData.append('UserId', model.UserId);
    emplyeeFormData.append('userDocumentId', model.userDocumentId);
    emplyeeFormData.append('SupportiveDocName', 'SSC');
    emplyeeFormData.append('SupportiveDocPath', model.SupportiveDocPath);
    emplyeeFormData.append('W4FormState', model.W4FormState !== null ? model.W4FormState : '');
    emplyeeFormData.append('IsStateSelectedByEmp', model.IsStateSelectedByEmp);

    let w4Path = 'w4form/create';

    if(model.Id > 0){
        w4Path = 'w4form/update';
    }

    return axios({
        method  : 'post',
        url     : configConstants.API_BASE_PATH+w4Path,
        data    : emplyeeFormData,
        headers : {'Content-Type': 'application/json'}
    }).then(function (response) {
        return response;
    }).catch(function (response) {
        if(response.response.data)
        return response.response.data.message;
    else
       return response;
    });
}