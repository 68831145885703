import React from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";
import { configConstants } from "../_constants";
import { utilityHelper } from "../_helpers";

const MGMTRoute = ({ authenticated }) => {
    const userInfo = utilityHelper.getUserInfo();

    if (!userInfo) {
        return <Navigate to="/" replace={true} />;
    }

    const isMgmtOrOwnerOrTPA =
        userInfo.role === configConstants.USER_ROLE_MGMT ||
        userInfo.role === configConstants.USER_ROLE_ADMIN ||
        userInfo.role === configConstants.USER_ROLE_OWNER ||
        userInfo.role === configConstants.USER_ROLE_TPA;

    return authenticated && isMgmtOrOwnerOrTPA ? (
        <Outlet />
    ) : (
        <Navigate to="/" replace={true} />
    );
};

MGMTRoute.propTypes = {
    authenticated: PropTypes.bool.isRequired,
};

export { MGMTRoute };
