import { wotcFormConstants } from '../_constants';

/**
 * wotcFormReducer
 *
 * @package                onboard
 * @subpackage             wotcFormReducer
 * @category               Reducers
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all state related to Admin business
 */

//Initial State on load state and intial action with their type
const initialState = {
    autofilldetail: {},
    WotcDocumentDetail:{},
    loader: true,
    successMessage: '',
    isWotcSaved: false,
    isWotcUpdate: false,
    submitted: false,
    success: null,
    message: ''
};
export function WotcForm(state = initialState, action) {
    switch (action.type) {
        case wotcFormConstants.AUTOFILL_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
            };
        case wotcFormConstants.AUTOFILL_SUCCESS:
            return {
                ...state,
                sendingRequest: true,
                loader: false,
                autofilldetail: action.autofilldetail,
                //success: true,
            };
        case wotcFormConstants.AUTOFILL_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                loader: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case wotcFormConstants.WOTC_ADD_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
                submitted: true,
            };
        case wotcFormConstants.WOTC_ADD_SUCCESS:
            return {
                ...state,
            sendingRequest: true,
            successMessage: action.successMsg.message,
            isWotcSaved: true,
            submitted: false,
            success: true,
            message: action.successMsg.message
            };
        case wotcFormConstants.WOTC_ADD_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isWotcSaved: false,
                submitted: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        case wotcFormConstants.WOTC_UPDATE_REQUEST:
            return {
                ...state,
                sendingRequest: false,
                loader: true,
                submitted: true,
            };
        case wotcFormConstants.WOTC_UPDATE_SUCCESS:
            return {
                ...state,
            sendingRequest: true,
            successMessage: action.successMsg.message,
            isWotcUpdate: true,
            submitted: false,
            success: true,
            message: action.successMsg.message
            };
        case wotcFormConstants.WOTC_UPDATE_FAILURE:
            return {
                ...state,
                sendingRequest: false,
                isWotcUpdate: false,
                submitted: false,
                errorMsg: action.errorMsg,
                success: false,
                message: action.errorMsg
            };
        

        case wotcFormConstants.WOTC_RESET_STATE:
            return {
                initialState
            };
    
        default:
            return state
    }
}