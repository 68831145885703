/**
 * tpaUserActions
 *
 * @package                onboard
 * @subpackage             tpaUserActions
 * @category               Actions
 * @DateOfCreation         28 june 2019
 * @ShortDescription       This is responsible for all business actions
 */ 

import { tpaUserConstants,configConstants } from '../_constants';
import { tpaUserService } from '../_services';

export const tpaUserActions = {
    tpaUserList,
    resetState,
    tpaUserSave,
    tpaUserEdit,
    tpaUserUpdate,
    tpaUserDelete,
    resetTPAUserEditState,
};



/**
 * @DateOfCreation        8 july 2019
 * @ShortDescription      This function is responsible for tpaUser list
 * @return                JSON Object
 */
function tpaUserList(page, pageSize, sorted, filtered,companyDetail={}) {
    return dispatch => {
        tpaUserService.tpaUserList(page, pageSize, sorted, filtered,companyDetail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(tpaUserList) { return { type : tpaUserConstants.TPAUSER_REQUEST, tpaUserList } }
    function  success(tpaUserList) { return { type : tpaUserConstants.TPAUSER_SUCCESS, tpaUserList } }
    function failure(error) { return { type : tpaUserConstants.TPAUSER_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for save tpaUser detail
 * @param {object} detail       tpaUser detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function tpaUserSave(detail) {
    return dispatch => {
        dispatch(request({ detail }));
        tpaUserService.tpaUserSave(detail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let responseDetail = { 'message' : responseData.message, 'detail' : responseData.data };
                        dispatch(success(responseDetail));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(detail) { return { type : tpaUserConstants.TPAUSER_ADD_REQUEST, detail } }
    function success(responseDetail) { return { type : tpaUserConstants.TPAUSER_ADD_SUCCESS, responseDetail } }
    function failure(error) { return { type : tpaUserConstants.TPAUSER_ADD_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for update tpaUser detail
 * @param {object} detail       tpaUser detail
 * @return                      JSON Object in succsss message, recent saved record
 */
function tpaUserUpdate(detail) {
    return dispatch => {
        dispatch(request({ detail }));
        tpaUserService.tpaUserUpdate(detail)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let responseDetail = { 'message' : responseData.message, 'detail' : responseData.data};
                        dispatch(success(responseDetail));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(detail) { return { type : tpaUserConstants.TPAUSER_UPDATE_REQUEST, detail } }
    function success(responseDetail) { return { type : tpaUserConstants.TPAUSER_UPDATE_SUCCESS, responseDetail } }
    function failure(error) { return { type : tpaUserConstants.TPAUSER_UPDATE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

function tpaUserEdit(Id) {
    return dispatch => {
        tpaUserService.tpaUserEdit(Id)
            .then(
                response => { 
                    let responseData = response.data;
                    if(response.status == configConstants.SUCCESS_CODE) {
                        dispatch(success(responseData.data));
                    }else if(response.status == configConstants.ERROR_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.EXCEPTION_CODE) {
                        dispatch(failure(responseData.message));
                    }else if(response.status == configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(responseData.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };

    function request(editTPAUserDetail) { return { type : tpaUserConstants.TPAUSER_EDIT_REQUEST, editTPAUserDetail } }
    function  success(editTPAUserDetail) { return { type : tpaUserConstants.TPAUSER_EDIT_SUCCESS, editTPAUserDetail } }
    function failure(error) { return { type : tpaUserConstants.TPAUSER_EDIT_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
 * @DateOfCreation              8 july 2019
 * @ShortDescription            This function is responsible for delete tpaUser detail
 * @param {object} detail       tpaUser detail
 * @return                      JSON Object in succsss message, recent delete record
 */
function tpaUserDelete(tpaUserId) {
    return dispatch => {
        dispatch(request({tpaUserId}))
        tpaUserService.tpaUserDelete(tpaUserId)
            .then(
                response => { 
                    let data = response.data;
                    if(response.status === configConstants.SUCCESS_CODE) {
                        let successMsg = { 'message' : data.message };
                        dispatch(success(successMsg));
                    }else if(response.status === configConstants.ERROR_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.EXCEPTION_CODE) {
                        dispatch(failure(data.message));
                    }else if(response.status === configConstants.UNAUTHENTICATE_CODE) {
                        dispatch(unauthorize(data.message));
                    }else{
                        dispatch(failure(response));
                    }
                }
            ).catch(function (response) {
                dispatch(failure(response));
            });
    };
    function request(tpaUserId) { return { type : tpaUserConstants.TPAUSER_REMOVE_REQUEST, tpaUserId } }
    function success(successMsg) { return { type : tpaUserConstants.TPAUSER_REMOVE_SUCCESS, successMsg } }
    function failure(error) { return { type : tpaUserConstants.TPAUSER_REMOVE_FAILURE, errorMsg:error } }
    function unauthorize(error) { return { type : configConstants.UNAUTHENTICATE, error } }
}

/**
* @DateOfCreation        8 july 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : tpaUserConstants.TPAUSER_RESET_STATE } }
}


/**
* @DateOfCreation        8 july 2019
* @ShortDescription      This function is responsible to update the states and props
* @return                JSON Object
*/
function resetTPAUserEditState(){
    return dispatch => { dispatch(request()); }
    function request() { return { type : tpaUserConstants.TPAUSER_EDIT_RESET_STATE } }
}
