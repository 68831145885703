import React, {Component} from 'react'
import * as $ from "jquery"

export class Footer extends Component {

    
    constructor(props, context){
        super(props);
        var fth = $(window).height();
        var calculate = fth - 315;
        $('.wrapper, .wrapper2').css("min-height", calculate);   
    }


    render() {
        const today = new Date();
        return (
            <div>  
                         
                  <footer>
                    <div className="container">
                        <div className="footer">
                            <div className="float-left">
                                <ul>
                                    <li>
                                        <a href="/">About Us</a>
                                    </li>
                                    <li>
                                        <a href="/">Our Services</a>
                                    </li>
                                    <li>
                                        <a href="/">Our Team</a>
                                    </li>
                                    <li>
                                        <a href="/">Partners</a>
                                    </li>
                                    <li>
                                        <a href="/">Contact Us</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="float-right">
                             © {today.getFullYear()}  <img src={process.env.PUBLIC_URL + '/assets/images/metis-copyright.png'} alt="Location"/> Management  |  Privacy Policy
                            </div>
                        </div>
                    </div>
                  </footer>
              
            </div>
        )
    }
    
}

export default Footer;